.b-history {
	display: none;

	&__date {
		@include font-size(18px);
		margin-bottom: 27px;
	}

	ul.b-history__list {
		@include rem(margin-bottom, 35px);
	}

	li.b-history__item {
		padding-left: 0;
		@include rem(margin-top, 5px);
	}

	&__link {
		display: table-cell;

		&:hover {
			text-decoration: none;
		}
	}

	&__time {
		display: table-cell;
		@include rem(padding-right, 40px);
	}

	&__btn {
		@include rem(margin-bottom, 45px);
	}
}

.b-history-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
		align-items: center;
	}

	&__icon {
		@include rem(min-width, 70px);
		@include rem(margin-right, 10px);
		margin-left: 1px;

		& > .webfont-icon {
			@include rem(font-size, 70px);
		}
	}

	&__link {
		&:visited {
			color: $link-default-color;
		}
	}

	&__content {}
	&__message {}
}

// .b-history-empty {
// 	&:not(.preloader) {
// 		@extend %clearfix;
// 	}
//
// 	&__message {
// 		margin: 0;
// 	}
//
// 	&__image {
// 		float: left;
// 		width: 61px;
// 		height: 85px;
// 	}
//
// 	&__content {
// 		float: left;
// 		@include rem(margin-top, 20px);
// 		@include rem(margin-left, 25px);
// 	}
//
// 	&__link {
// 		display: inline-block;
// 		@extend %disable-visited;
// 		@include rem(margin-top, 25px);
// 	}
// }
