.highcharts-menu {
    hr {
        display: none !important;
    }
}

.highcharts-credits {
    display: none;
}

.bar-class {
    .highcharts-point {
        &:first-child {
            fill: #00aeef;
        }
    }
}

.chart {
    position: relative;

    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-top: -2vw;//-40px;

        @include breakpoint(xs) {
            margin-top: -6vw;
        }
    }

    &__number {
        @include font(500);
        color: #00aeef;
        font-size: calc(1vw + 5px);
        @include breakpoint(sm) {
            font-size: 19px;
        }

        @include breakpoint(xs) {
            font-size: 13px;
        }
    }

    &__text {
        color: $color-grey;
        font-size: calc(1vw + 1px);
        @include breakpoint(sm) {
            font-size: 16px;
        }

        @include breakpoint(xs) {
            font-size: 10px;
        }
    }

    &--2 {
        .highcharts-point.highcharts-color-0 {
            &:last-child {
                transform: translateX(3px);

                #cboxLoadedContent & {
                    transform: translateX(7px);
                }
            }
        }

        .highcharts-axis.highcharts-xaxis {
            transform: translateY(-1px);
        }

        .highcharts-axis-labels.highcharts-xaxis-labels {
            text {
                &:nth-child(2n) {
                    display: none;
                }
            }
        }
    }

    &--3 {
        .highcharts-tracker {
            &:first-child {
                top: 0 !important;
                // left: 0 !important;
            }
        }

        .highcharts-legend-item.highcharts-series-0 {
            rect {
                fill: #71c063;
            }
        }

        .highcharts-legend-item.highcharts-series-1 {
            rect {
                fill: $color-supernova;
            }
        }

        .highcharts-legend-item.highcharts-series-2 {
            rect {
                fill: #00aeef;
            }
        }
    }
}