$popup_base--bg-color: white;
$popup_base--width: 700px;
$popup_base--close-color: $color-ironside-gray;
$popup_base--close-color-hover: $color-black;
$popup_base--close-color-active: $color-carrot-orange;

.b-popup {
	visibility: hidden;
	position: fixed;
	width: 100%;
	height: auto;
	top: 0;
	bottom: 0;
	z-index: 9999;
	max-height: 100%;

	&__inner {
		width: 100%;
		height: 100%;
		overflow: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__content {
		position: relative;
		max-width: $popup_base--width;
		width: 100%;
		padding: 20px;
		background: $popup_base--bg-color;
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		transform-origin: center;
		text-align: center;
		line-height: 0;
		background-color: transparent;
		padding: 0;
		color: $popup_base--close-color;
		@include webfont-icon($webfont-icon--close_2);
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:hover {
			color: $popup_base--close-color-hover;
		}

		&:active {
			color: $popup_base--close-color-active;
		}
	}

	
}

[data-popup-id="map"], [data-popup-id="map-en"] {

	.b-popup__content {
		border: 2px solid $color-supernova;
		max-width: 1250px;
    	width: 70%;
		padding: 70px 33px 31px;

		img {
			width: 100%;
		}

		.b-popup__close {
			top: 28px;
    		right: 30px;
			@include webfont-icon($webfont-icon--i-map-close);
			

			&:before {
				font-size: 24px;
				color: $color-pacific-blue;
				font-weight: bold;
			}
		}
	}
}

.b-popup--js-animation {
	display: none;
	visibility: visible !important;

	.b-popup__inner {
		margin-left: 0;
	}
}

.blur {
	filter: blur(3px);
}

.md-show {
	visibility: visible !important;
}

body.is-lock {
	position: relative;
	height: auto;
	min-height: 100%;
}

#popup-locker {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 9998;
	background: #000;
	opacity: 0;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-popup {
			&__inner {
				margin: auto;
				width: 95%;
			}
		}
	}

	@media (max-width: $breakpoint-sm) and (max-height: 650px) {
		.b-popup {
			&__inner {
				width: 100%;
				background-color: $popup_base--bg-color;
			}

			&__content {
				max-width: 100%;
				width: 100%;
				height: 100%;
			}
		}
	}
}

/* Effect 1: Fade in and scale up */
.b-popup--fade-in-and-scale .b-popup__inner {
	transform: scale(0.7);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--fade-in-and-scale .b-popup__inner {
	transform: scale(1);
	opacity: 1;
}

/* Effect 2: Slide from the right */
.b-popup--slide-in-right .b-popup__inner {
	transform: translateX(20%);
	opacity: 0;
	transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.md-show.b-popup--slide-in-right .b-popup__inner {
	transform: translateX(0);
	opacity: 1;
}

/* Effect 3: Slide from the bottom */
.b-popup--slide-in-bottom .b-popup__inner {
	transform: translateY(20%);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--slide-in-bottom .b-popup__inner {
	transform: translateY(0);
	opacity: 1;
}

/* Effect 4: Newspaper */
.b-popup--newspaper .b-popup__inner {
	transform: scale(0) rotate(720deg);
	opacity: 0;
}

.md-show.b-popup--newspaper,
.b-popup--newspaper .b-popup__inner {
	transition: all 0.5s;
}

.md-show.b-popup--newspaper .b-popup__inner {
	transform: scale(1) rotate(0deg);
	opacity: 1;
}

/* Effect 5: fall */
.b-popup--fall.b-popup {
	perspective: 1300px;
}

.b-popup--fall .b-popup__inner {
	transform-style: preserve-3d;
	transform: translateZ(600px) rotateX(20deg);
	opacity: 0;
}

.md-show.b-popup--fall .b-popup__inner {
	transition: all 0.3s ease-in;
	transform: translateZ(0px) rotateX(0deg);
	opacity: 1;
}

/* Effect 6: side fall */
.b-popup--side-fall.b-popup {
	perspective: 1300px;
}

.b-popup--side-fall .b-popup__inner {
	transform-style: preserve-3d;
	transform: translate(30%) translateZ(600px) rotate(10deg);
	opacity: 0;
}

.md-show.b-popup--side-fall .b-popup__inner {
	transition: all 0.3s ease-in;
	transform: translate(0%) translateZ(0) rotate(0deg);
	opacity: 1;
}

/* Effect 7:  slide and stick to top */
.b-popup--sticky-up {
	top: 0 !important;
	transform: translate(-50%, 0%) !important;
}

.b-popup--sticky-up .b-popup__inner {
	transform: translateY(-200%);
	transition: all .3s;
	opacity: 0;
}

.md-show.b-popup--sticky-up .b-popup__inner {
	transform: translateY(0%);
	border-radius: 0 0 3px 3px;
	opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.b-popup--flip-horizontal.b-popup {
	perspective: 1300px;
}

.b-popup--flip-horizontal .b-popup__inner {
	transform-style: preserve-3d;
	transform: rotateY(-70deg);
	transition: all 0.3s;
	opacity: 0;
}

.md-show.b-popup--flip-horizontal .b-popup__inner {
	transform: rotateY(0deg);
	opacity: 1;
}

/* Effect 9: 3D flip vertical */
.b-popup--flip-vertical.b-popup {
	perspective: 1300px;
}

.b-popup--flip-vertical .b-popup__inner {
	transform-style: preserve-3d;
	transform: rotateX(-70deg);
	transition: all 0.3s;
	opacity: 0;
}

.md-show.b-popup--flip-vertical .b-popup__inner {
	transform: rotateX(0deg);
	opacity: 1;
}

/* Effect 10: 3D sign */
.b-popup--3d-sign.b-popup__inner {
	perspective: 1300px;
}

.b-popup--3d-sign .b-popup__inner {
	transform-style: preserve-3d;
	transform: rotateX(-60deg);
	transform-origin: 50% 0;
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--3d-sign .b-popup__inner {
	transform: rotateX(0deg);
	opacity: 1;
}

/* Effect 11: Super scaled */
.b-popup--super-scaled .b-popup__inner {
	transform: scale(2);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--super-scaled .b-popup__inner {
	transform: scale(1);
	opacity: 1;
}

/* Effect 12:  Just me */
.b-popup--just-modal .b-popup__inner {
	transform: scale(0.8);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--just-modal {
	background: #eeeeee;
	height: 100% !important;
}

.b-popup--just-modal .b-popup__inner {
	background: transparent;
	top: 15%;
}

.md-show.b-popup--just-modal .b-popup__inner {
	transform: scale(1);
	opacity: 1;
}

/* Effect 13: 3D slit */
.b-popup--3d-split.b-popup {
	perspective: 1300px;
}

.b-popup--3d-split .b-popup__inner {
	transform-style: preserve-3d;
	transform: translateZ(-3000px) rotateY(90deg);
	opacity: 0;
}

.md-show.b-popup--3d-split .b-popup__inner {
	animation: slit .7s forwards ease-out;
}

@keyframes slit {
	50% { transform: translateZ(-250px) rotateY(89deg); opacity: 1; animation-timing-function: ease-in;}
	100% { transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

/* Effect 14:  3D Rotate from bottom */
.b-popup--rotate-bottom.b-popup {
	perspective: 1300px;
}

.b-popup--rotate-bottom .b-popup__inner {
	transform-style: preserve-3d;
	transform: translateY(100%) rotateX(90deg);
	transform-origin: 0 100%;
	opacity: 0;
	transition: all 0.3s ease-out;
}

.md-show.b-popup--rotate-bottom .b-popup__inner {
	transform: translateY(0%) rotateX(0deg);
	opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.b-popup--rotate-left.b-popup {
	perspective: 1300px;
}

.b-popup--rotate-left .b-popup__inner {
	transform-style: preserve-3d;
	transform: translateZ(100px) translateX(-30%) rotateY(90deg);
	transform-origin: 0 100%;
	opacity: 0;
	transition: all 0.3s;
}

.md-show.b-popup--rotate-left .b-popup__inner {
	transform: translateZ(0px) translateX(0%) rotateY(0deg);
	opacity: 1;
}

