.b-useful-links--compact {
	border-radius: 30px 0;
	border: 4px solid $color-supernova;
	.b-useful-links {
		&__button {
			width: 100%;
			font-size: 14px;
			line-height: 16px;
			@include font(700);
			text-transform: uppercase;
			color: $color-regal-blue;
			@include webfont-icon($webfont-icon--i-arrow-bottom, after);
			text-align: left;
			position: relative;
			padding: 19px 0 19px 1px;
			background-color: transparent;

			&::after {
				font-size: 8px;
				position: absolute;
				top: 22px;
				right: 2px;
				display: inline-block;
			}

			&:hover,
			&:active,
			&.is-active {
				color: $color-supernova;
			}

			&.is-active {
				&::after {
					transform: rotate(180deg);
				}
			}
		}

		&__list {
			display: none;
			padding: 2px 0 20px;
		}

		&__item {
			margin-bottom: 11px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
