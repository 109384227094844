.safety-highlights {

	&__block {
		border: 2px solid $color-pacific-blue;
		border-radius: 40px 0 40px 0;
		padding: 50px;
		background: #fff;

		@include breakpoint(sm) {
			padding: 0;
		}

		& ~ & {
			margin-top: 50px;

			@include breakpoint(sm) {
				margin-top: 20px;
			}
		}

		&--2 {
			border-color: #003566;

			.safety-highlights__block-title {
				color: #F9B234;
			}

			.safety-highlights__block-subtitle {
				color: #58595B;
			}
		}

		&--3 {
			border-color: #78BA54;

			.safety-highlights__block-title {
				color: #78BA54;
			}
		}

		&--4 {
			border-color: #EAF2FC;
			@media (min-width: ($breakpoint-mobile + 1)) {
				border-width: 0;
				padding: 0;
			}

			.safety-highlights__block-title {
				color: #F9B234;
			}

			.safety-highlights__block-content {
				.col-6 ~ .col-6 .graphic {
					@include breakpoint(md) {
						margin-top: -25px;
					}
				}
			}
		}

		&-head {

		}

		&-title {
			display: block;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 500;
			font-family: $custom-font-family;
			font-size: 22px;
			line-height: (25/22);
			color: $color-pacific-blue;
			pointer-events: none;

			@include breakpoint(sm) {
				padding: 20px 70px 25px 30px;
				@include webfont-icon($webfont-icon--i-arrow, after);
				position: relative;
				pointer-events: initial;
			}

			span {
				display: block;
				margin-top: .5em;
				text-transform: lowercase;
				font-weight: 700;
				font-size: 20px;
				line-height: 1.3;
			}

			@include breakpoint(sm) {
				&.is-expand {
					background-color: transparent;
					border-color: $color-pacific-blue;
					border-radius: 30px 0 0;
					&:after {
						margin-top: -12px;
						transform: rotate(90deg);
					}
				}

				&:after {
					margin-top: -10px;
					font-size: 20px;
					color: $color-pacific-blue;
					position: absolute;
					top: 50%;
					right: 30px;
					transition: transform .3s ease;
				}
			}
		}

		&-content {
			padding-top: 20px;

			@include breakpoint(sm) {
				display: none;
				padding: 8px 8px 30px;
			}

			.row {
				margin-top: -40px;

				@include breakpoint(sm) {
					margin-top: -25px;
				}
			}

			.graphic {
				margin: 40px 0 0;

				@include breakpoint(sm) {
					margin: 25px 0 0;
				}
			}
		}
	}

	&__aside {
		display: none;
		margin-top: 50px;

		@include breakpoint(sm) {
			display: block;
		}

		.inset {
			margin-top: 20px;
		}
	}

	&__dev-global .aside--right {
		margin-top: 50px;
	}
}

.highlights-btn {
	cursor: help;
}

.js-highlights {
	opacity: 0;
}
