$title--text-color: rgba(19, 58, 96, .3);
$title--link-color: rgba(19, 58, 96, .3);
$title--link-color--hover: rgba(19, 58, 96, .3);
$title--link-color--active: rgba(19, 58, 96, .3);
$title--link-color--visited: rgba(19, 58, 96, .3);

@if ($contrast) {
	$title--text-color: $color-white;
}

.b-report-title {
	font-size: 24px;
	line-height: 28px;
	@include font(700);
	color: rgba(19, 58, 96, .3) !important;

	.body--main-page & {
		color: $color-white !important;
	}

	&__link {
		color: rgba(19, 58, 96, .3) !important;
		&:visited {
			color: $title--link-color--visited !important;
		}

		&:hover {
			text-decoration: none;
			color: $title--link-color--hover;
		}

		&:active {
			color: $title--link-color--active;
		}
	}

	@include breakpoint(md) {
		font-size: 18px;
		line-height: 21px;
	}

	@include breakpoint(sm) {
		max-width: 77px;//73px;
		width: 100%;
		font-size: 14px;
		line-height: 16px;
		@include font(500);
		color: $color-white !important;
		opacity: 0.6;

		&__link {
			color: $color-white !important;
			opacity: 0.6;
			&:visited {
				color: $color-white !important;
				opacity: 0.6;
			}
		}
	}
}
