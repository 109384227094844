.b-nav--top.b-nav--horizontal {
	height: 100%;
	.b-nav__burger {
		background-color: $color-pacific-blue;
		box-shadow: 0px 2px 2px rgba(0, 159, 227, 0.24), 0px 0px 2px rgba(0, 159, 227, 0.12);
		border-top-right-radius: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		transition: background-color .3s ease;
		z-index: 10;
		position: relative;

		&:hover,
		&:active {
			background-color: #33B2E9;
			text-decoration: none;

			.b-nav__burger-line {
				height: 30px;
			}
		}

		&.is-expand {
			@include webfont-icon($webfont-icon--close_2);
			background-color: #33B2E9;
			&::before {
				font-size: 24px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform:  translate(-50%, -50%);
				color: $color-white;
			}

			.b-nav__burger-line {
				opacity: 0;
			}
		}
	}

	.b-nav__burger-line {
		width: 28px;
		height: 22px;
		position: relative;
		transition: all .3s ease;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&::before,
		&:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: $color-white;
			left: 0;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}
	}

	.b-nav__line {
		height: 2px;
		background-color: $color-white;
		width: 100%;
		display: block;
	}

	.b-nav__wrapper {
		display: none;
		position: fixed;
		top: 10px;
		left: 10px;
		background: linear-gradient(135.99deg, #FFFFFF 0%, #FEFEFE 98.47%);
		border-radius: 0 40px 40px 0;
		box-shadow: 0px 4px 40px rgba(19, 58, 96, 0.22), 0px 12px 80px rgba(19, 58, 96, 0.16);
		height: calc(100vh - 20px);
		max-width: 1171px;
		width: 100%;
		padding: 68px 68px 53px 136px;
		z-index: 9;

		&.is-expand {
			display: block;
		}

		@media (min-width: 1940px) {
			width: 100vw;
			top: 0;
			.browser-ie &,
			.browser-edge & {
				right: 50vw;
				left: auto;
				transform: translateX(31%) !important;
				width: 100vw;
			}
		}
	}

	.b-nav__title {
		font-size: 16px;
		line-height: 18px;
		color: $color-supernova;
		opacity: 0.8;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		@include font(700);
		margin-bottom: 39px;
	}

	.b-nav__number {
		text-transform: uppercase;
		font-size: 70px;
		line-height: 84px;
		@include font(700);
		color: $color-white;
		text-shadow: -1px 0 $color-pacific-blue, 0 1px $color-pacific-blue, 1px 0 $color-pacific-blue, 0 -1px $color-pacific-blue;
		display: none;

		.b-nav__link--lvl1 & {
			display: block;
		}
	}

	.b-nav__list {
	
	}

	.b-nav__list--lvl1 {
		display: flex;
		flex-wrap: wrap;
	}

	.b-nav__list--lvl2 {
		width: 100% !important;
	}

	.b-nav__list--lvl3 {
		display: none;
		padding: 18px 0 0 21px;
		max-width: 71%;
		flex: 1 0 100%;
		transition: transform .3s ease;
	}

	.b-nav__item {
		position: relative;

		&--lvl2 {
			margin-bottom: 12px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		&--lvl3 {
			margin-bottom: 9px;
		}
	}

	.b-nav__item--lvl1 {
		flex: 0 1 auto;
		width: calc(100%/3);
		margin-bottom: 37px;
		position: relative;
	}

	.b-nav__link {
		position: relative;
		display: block;
		width: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		text-decoration: none;
		transition: color 0.2s ease, background-color 0.2s ease;

		span {
			// display: inline-block;
			width: 100%;
			vertical-align: middle;
		}

		&:hover {
			color: $menu--link-color-expand-hover;
		}

		&:active {
			color: $menu--link-color-active;
		}

		&.is-expand {
			&::after {
				transform: rotate(180deg);
			}
		}

		&.current,
		&.is-expand {
			color: $menu--link-color-current;
		}
	}

	.b-nav__link--lvl1 {
		display: flex;
		align-items: center;
		font-size: 19px;
		line-height: 23px;
		text-transform: uppercase;
		@include font(700);
		color: $color-pacific-blue;
		display: flex;
		align-items: center;
		max-width: 90%;
		margin-bottom: 7px;
		min-height: 84px;
		.b-nav__number {
			display: inline-block;
			width: auto;
			margin-right: 20px;
		}
	}

	.b-nav__link--lvl2 {
		font-size: 15px;
		line-height: 18px;
		@include font(500);
		width: auto;
		max-width: 80%;
		padding-right: 31px;
		display: flex;
		align-items: center;
	}

	.b-nav__link--lvl3 {
		font-size: 15px;
		line-height: 17px;
		color: $color-eclipse;
		@include font(300);
	}

	.b-nav__item--lvl1 .has-submenu {
		> .b-nav__arrow {
			@include webfont-icon($webfont-icon--i-arrow-bottom, 'after');
			// position: absolute;
			// top: 0;
			// right: 0;
			width: 10px;
			height: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&:after {
				// position: absolute;
				// top: 50%;
				// transform: translateY(-50%);
				// @include rem(right, 18px);
				margin: auto;
				@include font-size(8px);
				height: 10px;
				color: $color-pacific-blue;
				transition: all 0.3s ease;
			}
		}
		.is-expand + .b-nav__arrow {
			&:after {
				transform:rotate(180deg);
			}
		}
	}

	.b-nav__arrow {
		cursor: pointer;

		&:after {
			transition: transform .3s ease;
		}
	}

	.b-nav__arrow.is-expand:after {
		transform: rotateX(180deg);
	}

	@include breakpoint(md) {
		.b-nav__wrapper {
			max-width: calc(100vw - 20px);
			padding: 22px 66px 53px 121px;
		}

		.b-nav__title {
			margin: 0 0 7px;
		}

		.b-nav__number {
			font-size: 60px;
			line-height: 72px;
			margin-right: 25px;
		}

		.b-nav__item--lvl1 {
			margin-bottom: 2px;
		}

		.b-nav__link--lvl1 {
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 3px;
			max-width: 83%;
		}

		.b-nav__link--lvl2,
		.b-nav__link--lvl3 {
			font-size: 15px;
			line-height: 18px;
		}
	}
}
