.b-lang--single {
	.b-lang__item {
		display: block;
		color: $lang-selector--color;
		text-decoration: none;
		font-size: 14px;
		line-height: 16px;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
		border-radius: 20px 0px;
		border: 2px solid $lang-selector--color-border;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		padding: 10px 19px;
		transition: background-color .3s ease;
		@include font(700);

		&:before {
			display: block;
			@include font-size(22px);
		}

		&:hover {
			color: $lang-selector--color-hover;
			background-color: $lang-selector--color-bg;
		}

		&:active {
			color: $lang-selector--color-active;
		}

		.body--main-page & {
			border-color: $color-white;
			color: $color-white;

			&:hover {
			color: #009FE3;
			}

			&:active,
			&.is-expand {
				color: #009FE3;
			}

			@include breakpoint(sm) {
				border-color: #009FE3;
				color: #009FE3;
			}
		}
	}
}
