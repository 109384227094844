$extendable-input-width: 45vw;
$extendable-input-border-color: #cccccc;

.b-search {
	&.b-search--extendable {
		position: relative;
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			width: 54vw;
			height: 100%;
			z-index: 10;
			position: absolute;
			right: 0;

			@media (min-width: 1940px) {
				width: 48vw;
			}
		}

		.b-search__input-container {
			&:hover {
				.b-search__input {
					border-color: $color-regal-blue;
				}

				.b-search__button--input,
				.b-search__button-close {
					color: $color-regal-blue;
				}
			}

			&:active,
			&.is-expand {
				.b-search__input {
					border-color: $color-pacific-blue;
				}

				.b-search__button--input,
				.b-search__button-close {
					color: $color-pacific-blue;
				}
			}
		}

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 20px);
			@include rem(left, 60px);
			cursor: text;
			font-size: 14px;
			line-height: 16px;
			@include font(700);
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: rgba(0, 159, 227, .6);
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			padding:0 55px 0 60px;// 0 55px;
			margin-top: 0;
			border-radius: 20px 0px;
			border: 2px solid $color-pacific-blue;
			transition: background-color .3s ease;
			font-size: 14px;
			line-height: 16px;
			@include font(700);
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: $color-pacific-blue;
			-webkit-appearance: none;
		}

		.b-search__button {
			display: block;
			padding: 0;
			background-color: transparent;
			color: $search--btn-icon;
			@include webfont-icon($webfont-icon--i-search);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
			border-radius: 20px 0px;
			border: 2px solid $color-pacific-blue;
			padding: 10px 19px;
			transition: background-color .3s ease;

			&:before {
				display: block;
				@include font-size(16px);
			}

			&:hover {
				color: $search--btn-icon-hover;
				background-color: $color-white;
			}

			&:active,
			&.is-expand {
				color: $search--btn-icon-active;
			}

			&--input {
				position: absolute;
				top: 0;
				left: 0;
				border: 0;

				&::before {
					font-size: 18px;
				}

				&:hover {
					color: $color-regal-blue;
					background-color: transparent;
				}
	
				&:active,
				&.is-expand {
					color: $search--btn-icon-active;
				}
			}

			.body--main-page & {
				border-color: $color-white;

				&::before {
					color: $color-white;
				}

				&:hover {
					&::before {
					color: #009FE3;
					}
				}
	
				&:active,
				&.is-expand {
					&::before {
					color: #009FE3;
					}
				}

				@include breakpoint(sm) {
					border: 2px solid $color-pacific-blue;
					&::before {
						color: #009FE3;
					}


					&:hover {
						color: $search--btn-icon-hover;
						background-color: $color-white;
					}

					&:active,
					&.is-expand {
						color: $search--btn-icon-active;
					}
				}
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}

		.search__additional-buttons {
			right: 15px;
		}

		.b-search__button-close {
			&::before {
				font-size: 16px;
			}

			color: $color-pacific-blue;
		}

		@include breakpoint(sm) {
			.b-search__wrapper-input {
				left: 0;
				width: 90vw;
			}
		}
	}
}
