$tabs--button-bg: #EAF2FC;
$tabs--button-bg-active: transparent;
$tabs--button-bg-hover: $color-taupe-gray;
$tabs--button-text-color: $base-text-color;
$tabs--button-text-color-hover: $color-pacific-blue;
$tabs--body-bg: $color-white;

.b-tabs {

	&__nav {
		margin: 0 -12px;
	}

	&__nav-item {
		// &:first-child {
		// 	.b-tabs__button {
		// 		margin-left: 0;
		// 	}
		// }
	}
	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		padding: 20px 13px;
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font-size(18px);
		border-radius: 30px 0;
		text-transform: uppercase;
		border: 2px solid transparent;
		transition: all .2s ease-in-out;
		@include font(400);
		margin: 0 12px 0 12px;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			text-decoration: none;

			&:visited {
				color: $tabs--button-text-color-hover;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			border-color: $color-pacific-blue;
			background-color: transparent;
			color: $color-eclipse;

			&:visited {
				color: $color-eclipse;
				border-color: $color-pacific-blue;

			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		padding: 22px 15px 9px 15px;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}
