/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $link-default-color;
	background-color: transparent;
	// transition: color 0.2s ease;
	text-decoration: none;
	@include font(500, 'Ubuntu');

	&:visited {
		color: $link-visited-color;
	}

	&:hover,
	&:visited:hover {
		color: $link-hover-color;
		text-decoration: underline;
	}

	&:active,
	&:visited:active {
		color: $link-active-color;
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&.btn {
		&:visited {
			color: $btn--color;
		}

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $btn--color-hover;
		}

		&:active,
		&:visited:active {
			color: $btn--color-active;
		}
	}
}

[data-anchor-link] {
	cursor: pointer;
}

.link--external {
	@include webfont-icon($webfont-icon--i-external-link);

	&:before {
		// transition: color 0.2s ease;
		display: inline;
		@include font-size(17px);
		margin-right: 0.2em;
		vertical-align: middle;
		margin-top: -3px;
		text-decoration: underline
	}

	&:before {
		text-decoration: none;
	}

	&:hover,
	&:visited:hover {
		&:before {
			color: $link-hover-color;
			text-decoration: none;
		}

	}

	&:active,
	&:visited:active {
		&:before {
			color: $link-active-color;
			text-decoration: none;
		}
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}
