.b-indicator {
	display: block;
	margin-bottom: 68px;
	// max-width: 300px;
	max-width: 350px;

	&--line {
		max-width: none;
	}

	&--border {
		border: 2px solid #626262;
		border-radius: 40px 0;
		padding: 20px 40px;
	}

	&__value {
		display: block;
		font-size: 80px;
		line-height: 92px;
		font-weight: 700;
		line-height: 1;
		color: $color-white;
		text-shadow: -1px 1px $color-dim-gray, 1px 1px $color-dim-gray, 1px -1px $color-dim-gray, -1px -1px $color-dim-gray;//-1px 0 $color-dim-gray, 0 1px $color-dim-gray, 1px 0 $color-dim-gray, 0 -1px $color-dim-gray;
		margin-bottom: 10px;
		white-space: nowrap;

		&--min {
			font-size: 48px;
			line-height: 55px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}

		&--min2 {
			@media (max-width: 1450px) {
				font-size: 50px;
			}

			@media (max-width: 798px) {
				font-size: 80px;
			}

		}

		&--dark-blue {
			text-shadow: -1px 1px #0F62A1, 1px 1px #0F62A1, 1px -1px #0F62A1, -1px -1px #0F62A1;//
		}

		&--blue {
			text-shadow: -1px 1px #009FE3, 1px 1px #009FE3, 1px -1px #009FE3, -1px -1px #009FE3;//
		}

		&--orange {
			text-shadow: -1px 1px #F9B234, 1px 1px #F9B234, 1px -1px #F9B234, -1px -1px #F9B234;//
		}
	}

	&__value--big {
		@include font-size(45px);
	}

	&__value--small {
		@include font-size(39px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		display: block;
		font-size: 26px;
		line-height: 31px;
		font-weight: 700;
		color: $color-dim-gray;

		&--min {
			font-size: 20px;
			line-height: 21px;
		}

		&--dark-blue {
			color: #0F62A1;
			margin-bottom: 0;
		}

		&--blue {
			color: #009FE3;
		}

		&--orange {
			color: #F9B234;
		}
	}

	&__unit--big {
		@include font-size(30px);
	}

	&__unit--small {
		@include font-size(24px);
		font-weight: 400;
	}

	&__heading {
		font-size: 20px;
		line-height: 20px;
		color: #9D9D9D;
		font-weight: 300;

		&--big {
			font-size: 22px;
			line-height: 23px;
		}
	}

	&__text {
		display: block;
		font-size: 18px;
		line-height: 22px;
		font-weight: 400;
		color: $color-dim-gray;
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__name {
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		color: #626262;
	}

	&__desck {
		font-size: 16px;
		line-height: 20px;
		color: #626262;
		font-weight: 300;
	}

	&__list {
		margin-top: 10px;

		&--line {
			display: flex;
		}
	}

	&__item {
		margin-bottom: 10px;
		margin-right: 5px;
	}
}
