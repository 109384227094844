.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: block;
			@include font-size($buttons-panel--trigger-icon-size);
		}

		&:hover {
			// color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		// @include webfont-icon($webfont-icon--i-tools);
		color: $color-white;
		height: 100%;
		width: 100%;
		border-bottom-right-radius: 40px;
		background: $color-pacific-blue;
		&::before {
			font-size: 28px;
		}

		&:hover {
			background: #33B2E9;
		}
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: 0;
		margin-top: 7px;
		z-index: 20;

		// .os-content {
		// 	display: flex;
		// 	flex-wrap: wrap;
		// }
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
		background-color: $buttons-panel--list-bg-color;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--tools {
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;

	.b-buttons-panel__trigger {
		position: relative;
		z-index: 10;
		transition: all .3s ease;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			&::before {
				color: $color-white;
			}
			.b-buttons-panel__icons {
			// width: 32px;
				height: 32px;
				margin: 0 4px;
				transition: all .3s ease;
			}
		}

		&.is-expand {
			background: #33B2E9;
			@include webfont-icon($webfont-icon--close_2);
			&::before {
				font-size: 24px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform:  translate(-50%, -50%);
				color: $color-white;
			}

			.b-buttons-panel__icons {
				opacity: 0;
			}

			@include breakpoint(sm) {
				&::before {
					position: static;
					transform: none;
				}
			}
		}

		@include breakpoint(sm) {
			@include webfont-icon($webfont-icon--i-tools);
		}
	}

	.b-buttons-panel__icons {
		position: relative;
		width: 12px;
		display: block;
		height: 28px;
		margin: 0 2px;
		transition: all .3s ease;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			width: 12px;
			height: 12px;
			border: 2px solid $color-white;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}

		@include breakpoint(sm) {
			display: none;
		}
	}

	.b-buttons-panel__list {
		top: 3px;
		left: 11px;
		position: fixed;
		background: linear-gradient(135.99deg, #FFFFFF 0%, #FEFEFE 98.47%);
		border-radius: 0 40px 40px 0;
		box-shadow: 0px 4px 40px rgba(19, 58, 96, 0.22), 0px 12px 80px rgba(19, 58, 96, 0.16);
		height: calc(100vh - 20px);
		width: 485px;
		z-index: 5;
		padding: 51px 56px 53px 136px;
		z-index: 9;
	}

	.b-buttons-panel__list-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -6px 53px;
	}

	.b-buttons-panel__item {
		padding: 3px;
		width: calc(50% - 2px);
		display: inline-block;
		vertical-align: top;
		position: relative;
	}

	.b-buttons-panel__title-tools {
		font-size: 16px;
		line-height: 18px;
		@include font(700);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #F9B234;
		opacity: 0.8;
		margin-bottom: 44px;
	}

	.b-buttons-panel__link {
		border-radius: 30px;
		background-color: transparent;
		border: 1px solid rgba(0, 159, 227, .2);
		font-size: 14px;
		line-height: 15px;
		display: flex;
		align-items: flex-start;
		text-align: center;
		color: $color-pacific-blue;
		width: 144px;
		height: 144px;
		transition: all .3s ease;
		justify-content: center;
		flex-wrap: wrap;
		@include font(400);
		padding: 33px 18px 21px;

		&:before {
			position: static;
			transform: none;
			width: 100%;
			margin-top: 6px;
		}

		&:hover {
			text-decoration: none;
			border: 1px solid $color-pacific-blue;
		}

		&:active {
			color: $color-supernova;
			border: 1px solid $color-supernova;
		}
	}

	@media (min-width: 1940px) {
		.b-buttons-panel__list {
			.browser-ie &,
			.browser-edge & {
				right: 50vw;
				left: auto;
				transform: translateX(-68%) !important;
				width: 100vw;
				max-width: 485px;
				top: -7px;
			}
		}
	}

	@include breakpoint(sm) {
		.b-buttons-panel__list {
			top: 69px;
			left: 0;
			width: 100%;
			max-width: none;
			box-shadow: none;
			border-radius: 0;
			padding: 11px 16px 20px;
			height: calc(100% - 69px);
			margin: 0;
			z-index: 10;
		}

		.b-buttons-panel__trigger {
			&.is-expand,
			&:hover {
				background: transparent !important;
			}

			&:hover {
				.b-buttons-panel__icons {
					height: 28px;
					margin: 0 2px;
				}
			}
		}

		.b-buttons-panel__title-tools {
			margin-bottom: 18px;
		}
		
		.os-content {
			// text-align: center;
			// display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
		}

		.b-buttons-panel__item {
			padding: 1px 3px;
			max-width: 144px;
		}

		.b-buttons-panel__title-tools {
			max-width: 320px;
			margin: 0 auto 18px;
		}

		.b-buttons-panel__list-wrapper {
			max-width: 320px;
			margin: 0 auto 25px;
		}

		.js-buttons-panel-list .os-scrollbar-vertical {
			right: 6px;
		}
	}

	@include breakpoint(xs) {
		.os-content {
			text-align: center;
		}
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		font-size: 14px;
		line-height: 16px;
		@include font(700);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $color-white;
		background-color: $color-pacific-blue;
		border-radius: 20px 0;
		border: 2px solid transparent;
		transition: all .3s ease;
		padding: 10px 24px;

		[lang="en"] & {
			padding: 10px 16px;
		}

		&:hover {
			background-color: $color-white;
			color: $color-pacific-blue;
			border-color: $color-pacific-blue;;
		}

		.body--main-page & {
			background-color: $color-white;
			color: $color-pacific-blue;
		}
	}

	.b-buttons-panel__list {
		background-color: transparent;
		margin-top: 10px;
		width: 100%;
	}

	.b-buttons-panel__title-tools {
		display: none;
	}

	.b-buttons-panel__item {
		margin-bottom: 10px;
	}

	.b-buttons-panel__link {
		white-space: normal;
		font-size: 14px;
		line-height: 16px;
		@include font(400);
		color: $color-pacific-blue;
		@include rem(padding-left, 50px);
		border: 2px solid $color-pacific-blue;
		border-radius: 20px 0px;
		transition: border-color .3s ease, color .3s ease;
		background-color: $color-white;
		padding-top: 10px;
		padding-bottom: 10px;

		&:before {
			left: 25px;
			font-size: 18px;
		}

		&:hover {
			text-decoration: none;
			color: $color-regal-blue;
			border-color: $color-regal-blue;
			background-color: $color-white;
		}

		&:active {
			color: $color-pacific-blue;
			background-color: $color-white;
			border-color: $color-pacific-blue;
		}
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--i-pdf-double-page);
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--i-pdf-page);
	}

	@media (max-width: 1000px) {
		.b-buttons-panel__list {
			min-width: 140px;
			right: 0vw
		}
		.b-buttons-panel__link {
			padding-left: 42px;
			
		}
	}

	@include breakpoint(sm) {
		.b-buttons-panel__trigger {
			padding: 10px 23px;
		}

		.b-buttons-panel__link {
			&::before {
				font-size: 18px;
			}
		}
	}
	
}

@include breakpoint(xs) {
	.b-buttons-panel--in-column {
		// .b-buttons-panel__sublist--share {
		// 	width: auto;
		// 	height: auto;
		// }

		.b-buttons-panel__trigger--tools {
			border-radius: 0;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}
	}
}
