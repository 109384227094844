.b-form {
	display: block;
	width: 100%;

	input::-ms-clear {
		display: none;
	}

	&__title {
		margin-bottom: 1em;
		margin-top: 0;
		padding: 0;
	}

	&__fields {}

	&__field {}

	&__field:last-child {
		margin-bottom: 0;
	}

	&__field-title {
		margin-bottom: 0.4em;
	}

	&__field-placeholder--smart,
	&__field-placeholder--smart-top {
		cursor: text;
		position: absolute;
		padding: $input-padding;
		transition: all 0.2s ease;
		background-color: transparent;
		white-space: nowrap;
		text-overflow: ellipsis;
		left: 11px;

		// span {
		// 	color: $color-error;
		// }
	}

	&__field-placeholder--smart {
		top: 11px;
	}

	&__field-placeholder--smart-top {
		top: 11px;
	}

	&__field-input {
		position: relative;
		display: inline-block;
		width: 100%;
		@include rem(margin-bottom, 31px);
		@include rem(padding-bottom, 10px);

		input {
			border: 1px solid $color-white;
			border-bottom: 1px solid $color-light-gray;
		}

		//Текст ошибки для невалидного поля
		.parsley-errors-list {
			& > li {
				position: absolute;
				display: block;
				color: #CE1126;
				@include font-size(14px);
				@include rem(margin-left, 11px);
			}
		}
	}

	&__field-input input,
	&__field-input textarea {
		width: 100%;
		transform: translateZ(0);
		font-weight: 300;
		font-size: 16px;
		line-height: 26px;
		padding: 11px;

		&.is-error {
			border-bottom-color: #CE1126;
		}
	}

	&__field-input textarea {
		display: block;
		resize: none;
		border: 1px solid $color-light-gray;
		&.is-error {
			border-color: #CE1126;
		}
	}

	&__field-input--smart-placeholder {
		//Меняем положение span с текстовой меткой при состоянии focus или not-empty родительского label
		&.is-focused .b-form__field-placeholder--smart,
		&.is-focused .b-form__field-placeholder--smart-top {
			opacity: 1;
			top: 9px;
			transform: translateY(-150%);
			color: $color-black;
			background-color: $color-white;
			border: 1px solid #DADADA;
			cursor: default;
			@include rem(padding, 5px 7px);
			@include font-size(14px);

			&:after {
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				border-style: solid;
				border-width: 6px;
				border-color: #DADADA transparent transparent transparent;
				position: absolute;
				bottom: -11px;
				left: 10px;
				cursor: default;
			}

			&::before {
				content: '';
				display: block;
				width: 11px;
				height: 11px;
				border-style: solid;
				border-width: 6px;
				border-color: $color-white transparent transparent transparent;
				position: absolute;
				bottom: -10px;
				left: 10px;
				cursor: default;
				z-index: 2;
			}
		}

		&.is-fill .b-form__field-placeholder--smart,
		&.is-fill .b-form__field-placeholder--smart-top {
			opacity: 0;
		}
	}

	&__footnote {
		// span {
		// 	color: $color-error;
		// }
	}

	.b-captcha {
		@include rem(padding-bottom, 10px);
	}
}
