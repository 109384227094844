.product-acceleration {
	&__ore-mining,
	&__power,
	&__metal-prod {
		position: relative;
		width: 100%;

		@include breakpoint-width($breakpoint-mobile - 1px) {
			display: none;
		}
	}

	&__ore-mining {
		margin-top: 5% !important;
	}

	&__power {
		margin-top: -6% !important;
	}

	&__metal-prod {
		margin-top: -6% !important;
	}

	h1,
	.lead {
		max-width: 960px;

		@include breakpoint-width($breakpoint-mobile - 1px) {
			width: 100%;
		}
	}

	a {
		text-decoration: none;
	}

	.js-accel {
		opacity: 0;
	}
}

.accel-btn {
	cursor: help;
}
