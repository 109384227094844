.b-pagenav--buttons {
	display: flex;
	height: auto;
	// position: fixed;
	// top: 0;
	// right: 0;

	.b-pagenav {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			@include rem(width, $pagenav--link-width);
			@include rem(height, $pagenav--link-height);
			transition: background-color 0.2s ease;
			text-decoration: none;
			border-radius: 20px 0;
			color: $pagenav--icon-color;
			background-color: $color-white;

			&:hover,
			&:focus,
			&.is-touch {
				// background-color: $pagenav--btn-bg-hover;
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					right: 0;
					opacity: 1;

					&--scrollup {
						right: auto;
					}
				}
			}

			&:active {
				color: $pagenav--icon-color-active;
				// background-color: $pagenav--btn-bg-active;
			}
		}

		&__link--hide-fixed {
			opacity: 0;
		}

		&__link--hide {
			display: none;
		}

		&__link--disabled,
		&__scrollup--disabled {
			opacity: 0.3;
			pointer-events: none;
			// background-color: $pagenav--btn-bg;
			cursor: default;

			.b-pagenav__title {
				display: none;
			}

			&:hover,
			&:focus {
				// background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}
		}

		&__scrollup {
			@extend .b-pagenav__link;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 19px;
			color: $pagenav--icon-color;

			&:hover,
			&:focus,
			&.is-touch {
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					right: 50%;
					transform: translateX(50%);
					opacity: 1;
				}
			}

			&:active {
				color: $pagenav--icon-color-active;
			}
		}

		&__title {
			display: block;
			position: absolute;
			right: 9999px;
			@include rem(min-width, $pagenav--title-width);
			@include rem(top, 50px);
			@include rem(padding, $grid-gutter-width / 2 $grid-gutter-width);
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			opacity: 0;
			transition: opacity 0.2s ease;
			z-index: 10;
			border: 2px solid $color-regal-blue;
			border-radius: 20px 0px;
			font-size: 14px;
			line-height: 16px;
			@include font(400);
			text-align: center;

			.b-pagenav-help {
				display: block;
			}
		}
	}

	.b-icon {
		// position: relative;
		display: inline-block;
		// left: 50%;
		// top: 50%;
		// transform: translate(-50%, -50%);
		// font-size: 0;

		&:before {
			display: block;
		}
	}

	.b-icon--scrollup {
		display: block;
		text-align: center;
		@include webfont-icon($webfont-icon--i-arrow);
		transform: rotate(90deg);
		margin-left: -3px;
    	margin-top: 1px;

		&:before {
			font-size: 19px;
		}
	}

	.b-icon--prev {
		@include webfont-icon($webfont-icon--i-arrow);
		margin-top: 6px;

		&:before {
			@include font-size(19px);
		}
	}

	.b-icon--next {
		@include webfont-icon($webfont-icon--i-arrow);
		transform: rotate(180deg);
		margin-top: -2px;

		&:before {
			@include font-size(19px);
		}
	}
}

.fixed.b-pagenav--buttons {
	position: fixed;
	top: 10vh;
	right: 12vw;
	z-index: 5;
	.b-pagenav {
		&__link,
		&__scrollup {
			border: 2px solid $pagenav--btn-bg;

			&:hover,
			&:focus,
			&.is-touch {
				border-color: $pagenav--icon-color-hover;
			}

			&:active {
				border-color: $pagenav--icon-color-active;
			}
		}
	}

	@media (min-width: 1940px) {
		right: 20vw;
	}

	@include breakpoint(md) {
		right: 3vw;
	}
}

.body--business-model {
	.fixed.b-pagenav--buttons {
		visibility: hidden;
		z-index: -999;
	}
}
