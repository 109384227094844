$pagenav--link-width: 60px;
$pagenav--link-height: 40px;

$pagenav--icon-width: $pagenav--link-width / 1.5;
$pagenav--icon-height: $pagenav--link-width - 4;
$pagenav--icon-color: $color-pacific-blue;
$pagenav--icon-color-hover: $color-regal-blue;
$pagenav--icon-color-active: $color-supernova;

$pagenav--btn-bg: $color-pacific-blue;
$pagenav--btn-bg-hover: $color-regal-blue;
$pagenav--btn-bg-active: $color-supernova;

$pagenav--title-width: 250px;
$pagenav--title-color: rgba(19, 58, 96, .5);
$pagenav--title-bg: $color-white;
$pagenav--title-bg-active: $color-white;

@if ($contrast) {
	$pagenav--icon-color: $color-celeste;
	$pagenav--icon-color-hover: $color-black;
	$pagenav--icon-color-active: $color-carrot-orange;

	$pagenav--btn-bg: $color-eallery;
	$pagenav--btn-bg-hover: $color-celeste;
	$pagenav--btn-bg-active: $color-dark-gray;

	$pagenav--title-color: $color-white;
	$pagenav--title-bg: $color-dark-gray;
	$pagenav--title-bg-active: $color-dark-gray;
}
