.strategy-evolution {
	text-align: center;

	&__strategy {
		margin-top: -3% !important;

		#strat-right,
		#strat-left {
			&-bg {
				transition: all .5s;

				fill: #fff;
			}

			&-btn {
				cursor: pointer;

				.btn-border {
					transition: all .5s;

					stroke: #fff;
					fill: #EAF2FC;
				}
			}
		}

		#strat-right {
			&:hover {
				#strat-right-bg {
					fill: #EAF2FC;
				}

				.btn-border {
					stroke: $color-pacific-blue;
					fill: #fff;
				}
			}
		}

		#strat-left {
			&:hover {
				#strat-left-bg {
					fill: #EAF2FC;
				}

				.btn-border {
					stroke: $color-pacific-blue;
					fill: #fff;
				}
			}
		}
	}

	&__target {
	}

	&-btn {
		cursor: pointer;

		display: none;
		align-items: center;
		justify-content: space-around;

		width: 224px;
		height: 50px;

		background-color: $color-supernova;
		border-radius: 25px 0px;

		font-weight: bold;
		font-size: 16px;
		line-height: 18px;
		font-size: 1rem;
		line-height: 1.125rem;
		letter-spacing: 0.05em;

		text-transform: uppercase;

		color: #fff;

		.btn-ico {
			height: 16px;
			width: 22px;

			margin: 0;
		}

		@include breakpoint-width($breakpoint-mobile - 1px) {
			display: inline-flex;
		}
	}

	@include breakpoint-width($breakpoint-mobile - 1px) {
		display: none;
	}
}

.js-strat {
	// display: none;
	opacity: 0;
	// transform-origin: 50% 0;
	// transform: perspective(1000px) rotateX(-90deg);

	// transition: all .5s cubic-bezier(0.6, 0.04, 0.98, 0.335);
	// &.flip {
	//     transform: perspective(1000px) rotateX(0deg);
	//     transform-style: preserve-3d;
	// }
}

.body--s-e-base {
	// width: calc(100% - 218px);
	// max-width: 960px;

	// @include breakpoint-width($breakpoint-lg) {

	.quote {

		&--photo {
			display: block;
		}

		&__right {
			float: right;
			margin-bottom: 25px;
			width: 260px;
		}
	}

	@include breakpoint(lg) {
		.quote--photo {
			// display: block;
			padding: 24px 0 24px 0px;
			display: flex;
			flex-direction: column-reverse;

			.quote__icons {
				top: 24px;
			}

			.quote__content {
				position: relative;
			}

			.quote__photo {
				margin: 28px auto 25px;
			}

			.quote__right {
				margin-bottom: 0;
				width: auto;
			}
		}
	}

	h2 {
		@include font-size(28px);
		@include rem(line-height,32px);
		@include rem(margin-bottom,14px);
	}

	a {
		text-decoration: none;
	}

	.quote {

	}

	.inset {
		background-image: url(/images/content/inset-blue.png);
		padding: 39px 44px 47px 44px;
		margin-bottom: 55px;

		h2 {
			color: $color-white;
		}

		p {
			@include font-size(18px);
			color: $color-regal-blue;

			&:last-of-type() {
				margin-bottom: 0;
			}
		}
	}
}


.horizons-btn {
    cursor: help;
}