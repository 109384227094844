.note {
	display: block;
	@include font-size(13px);
	line-height: 1em;
	font-style: normal;
    text-align: right;

	div + div {
		@include rem(margin-top, 6px);
	}
}
