.b-download-center--page {
	
}

@include breakpoint(xs) {
	.b-download-center--page {
		.b-download-center__btn {
			display: flex;
			width: auto;
			min-width: 150px;
			margin-left: 0;
			margin-right: 0;
		}
		
		.b-download-center__btn + .b-download-center__btn {
			margin-top: 10px;
		}

		.b-download-center-line--external {
			padding: 15px 0;

			.b-download-center-line__content {
				padding: 0;
			}
		}
	}
}
