$button-size: 30px;
$analysis-bg-color: #cdcdcd;
$analysis-btn-bg-hover: #444444;
$analysis-icon-color: #33ffff;
$analysis-icon-color-hover: $color-white;


.analysis__preset {
	@include rem(padding, 5px 10px);
	@include rem(margin, 0px 1px 5px 1px);
	@include font-size(12px);
	@include rem(line-height, 22px);
	color: $analysis-font-color;
	@include font(700, "Lato");


	&.is-selected {
		background-color: $analysis-pink-flare;
		color: $color-white;
	}

	&:hover {
		background-color: $analysis-lemon;
		color: $color-white;
	}
}

.analysis__main {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.analysis__chart-section {
	display: table-cell;
	vertical-align: top;
	padding-left: 30px;
}

.analysis__chart-section,
.analysis__items-list {
	float: none !important;
}

.analysis__items-list {
	width: 340px;
	display: table-cell;
	vertical-align: top;

	.items-group__title {
		@include font-size(16px);

		.b-accordion {
			&__button {
				// @include rem(padding, 10px 45px 10px 10px);
				padding: 17px 49px 19px 10px;
				&::before {
					display: none;
				}

				&.is-expand {
					// @include font(700, "Lato");
					color: $analysis-font-color;
				}
			}
		}
	}

	.items-group__item {
		@include rem(padding, 5px 5px 5px 20px);
		@include font-size(14px);
		text-indent: 0;

		&.is-even:not(.is-selected):not(:hover) {
			background-color: $analysis-bg-smoke;
		}

		&:hover {
			background-color: $color-pacific-blue;
			color: $color-white
		}

		&.is-selected {
			background-color: $analysis-bg-active;
			color: $color-white;
			border-bottom: 1px solid $color-white;
		}

		&.subgroup {
			@include rem(padding-left, 30px);
		}
	}

	.b-accordion__article {
		// @include rem(margin, 11px 0 14px);
		background: transparent;
		overflow: hidden;

		&::after {
			display: none;
		}
	}
}

.analysis__table {
	@include font-size(11px);

	th {
		@include rem(padding, 5px);
		@include font(700, "Lato");
		border-bottom: 2px solid #0F69A4;
	}

	td {
		@include rem(padding, 13px 5px);
		// border-bottom: 1px dashed $analysis-dashed-border;
		@include font-size(11px);
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

		}
	}

	tr {

		&.is-hidden {
			opacity: 1 !important;

			td {
				color: #C9C9C9;
			}
		}
		td {
			position: relative;

			&:first-child {
				border-left: 1px solid transparent;
				padding-right: 5px;
			}

			&:last-child {
				border-right: 1px solid transparent
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-regal-blue;
				opacity: 0;
			}

			&::before {
				top: -1px;
			}

			&::after {
				bottom: 0;
			}
		}

		&:hover,
		&.is-hover {
			td {
				&::before,
				&::after {
					opacity: 1;
				}

				&:first-child {
					border-left: 1px solid $color-regal-blue;
				}

				&:last-child {
					border-right: 1px solid $color-regal-blue;
				}
			}

			.b-cell--strong {
				border: 1px solid $color-regal-blue;
			}
		}

		&:nth-child(2n) {
			background-color: #EAF2FC;
		}

		// &.is-selectable {
		// 	td {
		// 		&::before,
		// 		&::after {
		// 			background-color: $color-supernova !important;
		// 			opacity: 1;
		// 		}

		// 		&:first-child {
		// 			border-left-color: $color-supernova !important;
		// 		}

		// 		&:last-child {
		// 			border-right-color: $color-supernova !important;
		// 		}

		// 		.b-cell--strong {
		// 			border: 1px solid $color-supernova !important;
		// 		}
		// 	}
		// }
	}
}

.analysis__years-list {
	@include rem(margin-left, 29px);
	@include font-size(12px);
	@include rem(line-height, 22px);
	color: $analysis-font-color;
	@include font(700, "Lato");

	span {
		margin-right: 20px;
	}

	.years-list__year {
		@include rem(padding, 1px 7px);
		background-color: $analysis-pink-flare;
		margin: 0 6px 5px;
		color: $color-white;

		&:not(.is-selected) {
			background-color: transparent;
			color: $analysis-font-color;
		}

		&:hover {
			background-color: $analysis-lemon;
			color: $color-white;
		}
	}
}

.analysis__chart {
	@include rem(margin-bottom, 20px);
	overflow-x: auto;
    overflow-y: hidden;
}

.analysis__chart-area {
	@include breakpoint(xs) {
		// overflow-y: auto;
	}
}

.analysis__buttons {
	right: 41px !important;
	white-space: nowrap;
	display: inline-block;
	margin: 0;
	margin-top: -35px;
	$button-padding: 6px;
	$button-image-size: $button-size - $button-padding;

	.analysis__button {
		margin-left: 15px;
		@include rem(padding, 1px);
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		// color: $analysis-button;

		&::before {
			display: none;
		}

		&:hover {
			background-color: transparent;
			color: $color-pacific-blue;
		}

		&:active {
			color: $analysis-bg-active;
		}

		&:before {
			@include font-size(24px);
		}
	}

	.analysis__button--line {
		@include webfont-icon($webfont-icon--i-graf);
	}

	.analysis__button--histogram {
		
		@include webfont-icon($webfont-icon--i-gistogram);
		
	}

	.analysis__button--excel {
		@include webfont-icon($webfont-icon--i-xls-analysis);
		&:before {
			@include font-size(13px);
		}
		display: none;
	}
}

.analysis__items-list .b-accordion__article {
	padding: 0;
}

.c3-tooltip td.name {
	max-width: 700px;
}

.c3-tooltip-container {
	box-shadow: 0px 0px 7px rgba(35, 31, 32, 0.3);
	padding: 10px;
	background-color: $color-white;
}

.c3-tooltip {
	color: $color-eclipse !important;
	box-shadow: none !important;
	background-color: $color-white;

	tr {
		background-color: transparent !important;
		border: 0 !important;

		&:last-child {
			td {
				border-bottom: 0 !important;
			}
		}

		&:nth-child(2n) {
			background-color: #EAF2FC !important;
		}
	}

	th {
		font-size: 11px !important;
		color: $color-eclipse !important;
		background-color: transparent !important;
		border-bottom: 2px solid #0F69A4 !important;
		@include font(700, "Lato");
	}
	td {
		border-left: 0 !important;
		font-size: 11px !important;
		color: $color-eclipse !important;
		background-color:  transparent !important;
		// border-bottom: 1px solid $pink-flare !important;
		.c3-tooltip td>span {
			width: 11px !important;
			height: 11px !important;
		}
	}

	.c3-tooltip-name {
		font-size: 11px !important;
		color: $color-eclipse !important;
	}
}

.c3-axis-y-label {
	@include font-size(11px);
	color: $analysis-font-color;
}

.tick {
	@include font-size(12px);
	color: $analysis-font-color;
}

.table {
	margin-left: 23px;
	margin-bottom: 42px;

	@include breakpoint(xs) {
		overflow: auto;
	}
}

@include breakpoint(md) {
	.analysis__main {
		display: block;
	}

	.analysis__items-list {
		display: block;
		width: 100%;
	}

	.analysis__chart-section {
		display: block;
		width: 100%;
		padding: 0;
	}
}

@include breakpoint(xs) {
	.analysis__table {
		@include rem(font-size, 8px)
	}

	.analysis__years-list {
		@include rem(font-size, 12px);
	}

	.c3-tooltip-container {
		left: 50% !important;
		transform: translateX(-50%);
		width: 320px;
	}
}


.interactive-analysis.open-bp .os-padding,
.interactive-analysis.open-m .os-padding {
	z-index: 1000000;
}