$bc-link-color: rgba(19, 58, 96, .5);
$bc-link-color-hover: $color-regal-blue;
$bc-link-color-active: rgba(19, 58, 96, .5);
$bc-link-color-visited: rgba(19, 58, 96, .5);

$bc-home-icon-color: $color-pacific-blue;
$bc-home-icon-color-hover: $color-regal-blue;
$bc-home-icon-color-active: $color-supernova;

$bc-item-color: black;
$bc-dash-color: $color-pacific-blue;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: $link-default-color;
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;

	$bc-home-icon-color: #444444;
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;

	$bc-item-color: black;
	$bc-dash-color: $color-ironside-gray;

	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: $link-default-color;
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	@include font-size(14px);

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;
			font-size: 12px;
			line-height: 14px;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;
				text-decoration: none;
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;
		font-size: 12px;
		line-height: 14px;
		@include font(400);

		& + .breadcrumbs__item {
			@include rem(padding-left, 44px);

			&:after {
				content: '';
				position: absolute;
				top: 9px;
				width: 4px;
				height: 4px;
				background-color: $bc-dash-color;
				left: 21px;
				border-radius: 100%;
				box-shadow: 0px 4px 16px rgba(0, 159, 227, 0.2), 0px 6px 20px rgba(0, 159, 227, 0.14);
			}
		}

		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}

	&__link {
		color: $bc-link-color;
		font-size: 12px;
		line-height: 14px;
		@include font(400);

		&:visited {
			color: $bc-link-color-visited;
		}

		&:hover,
		&:focus {
			color: $bc-link-color-hover;
			text-decoration: none;
		}

		&:active {
			color: $bc-link-color-active;
		}
	}

	&__link--home {
		position: relative;
		display: flex;
		align-items: center;
		color: $bc-home-icon-color;
		font-size: 18px;
		@include webfont-icon($webfont-icon--i-breadcrumbs);
		text-decoration: none;

		&::before {
			color: $bc-home-icon-color;
		}

		&:hover {
			color: $bc-home-icon-color-hover;
			&::before {
				color: $bc-home-icon-color-hover;
			}
		}

		&:active {
			color: $bc-home-icon-color-active;
			&::before {
				color: $bc-home-icon-color-active;
			}
		}
	}
}
