// Цвет фона фиксированной шапки
$table--fixed-head-bg: $color-white;
// Шапка
$table--header-color: $color-pacific-blue;
$table--header-bg: $color-regal-blue;
// Подзаголовок
$table--subhead-color: $color-navy-blue;
// Выделенная ячейка
$table--strong-cell-color: $base-text-color;
$table--strong-cell-bg: $color-celeste;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-bg: $color-pacific-blue;
$table--summary-color: $color-pacific-blue;
$table--summary-hover: $color-mountain-mist;
$table--summary-active-js: $table--summary-hover;
// Фон строки по наведению
$table-row-bg-hover: $color-regal-blue;
$table-row-bg-strong-hover: $color-regal-blue;
// Выделенная строка
$table--strong-row-color: $table--strong-cell-color;
$table--strong-row-bg: $table--strong-cell-bg;
$table--strong-row-bg-hover: $color-mountain-mist;
$table--strong-row-bg-js: $table--strong-row-bg-hover;
// Выделенная строка (при клике)
$table--selected-row-bg: $color-supernova;
$table--selected-row-bg-strong: $color-supernova;

@if ($contrast) {
	$table--header-color: $color-white;
	$table--header-bg: $color-mine-shaft;
	$table--strong-cell-color: $color-taupe-gray;
	$table--strong-cell-bg: $color-eallery;
	$table--strong-cell-border: $color-davys-grey;
	$table--subhead-color: $color-error;
	$table--summary-bg: $color-dark-jungle;
	$table--summary-color: $color-white;
	$table--fixed-head-bg: $color-tapa-gray;
}

table {
	border-collapse: separate;
	border-spacing: 0;

	td,
	th {
		padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {
	position: relative;
	margin-bottom: 48px;
	// overflow-y: hidden;
	// overflow-x: auto;

	figcaption {
		font-size: 17px;
		line-height: 20px;
		font-weight: bold;
		color: $color-ship-grey;
		@include font(500, 'Ubuntu');
		margin-bottom: $grid-gutter-width;

		.caption__units,
		.b-caption__units {
			color: $color-pacific-blue;
		}
	}

	table {
		min-width: 100%;
		@include font(400, 'Ubuntu');
		color: #58595B;
		font-size: 17px;
		line-height: 22px;

		thead {
			//Ячейки в заголовках
			border-bottom: 3px solid $color-regal-blue;

			td,
			th {
				color: $table--header-color;
				border: 1px solid $table--header-bg;
				border-top: 0;
				text-align: right;
				border-left: 0;
				padding: 5px 12px;

				// &[colspan] {
				// 	text-align: center;
				// }
			}

			tr:first-child {
				th:first-child,
				td:first-child {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				td {
					position: relative;
					border: 0;
					border-top: 1px solid $table-row-bg-hover;

					&:first-child {
						border-left: 1px solid transparent;
						padding-right: 5px;
					}

					&:last-child {
						border-right: 1px solid transparent
					}

					&::before {
						content: '';
						position: absolute;
						left: -1px;
						width: calc(100% + 2px);
						height: 1px;
						background-color: $table-row-bg-hover;
						opacity: 0;
					}

					&::before {
						top: -1px;
					}

					&::after {
						bottom: 0;
						opacity: 1;
					}
				}
				td.b-cell {
					&--bt-orange::before {
						background-color: $color-supernova;
						opacity: 1;
					}
					&--bt-blue::before {
						background-color: #009FE3;
						opacity: 1;
					}
					&--bt-green::before {
						background-color: #7AC05E;
						opacity: 1;
					}
				}
				&:first-child {
					td.b-cell {
						&--bt-orange::before,
						&--bt-blue::before,
						&--bt-green::before {
							top: -3px;
							height: 3px;
						}
					}
				}

				&:last-child {

					td {
						border-bottom: 1px solid $table-row-bg-hover;
					}
				}
				
				&:hover,
				&.is-hover {
					td {

						&::before,
						&::after {
							opacity: 1;
						}

						&:first-child {
							border-left: 1px solid $table-row-bg-hover;
						}

						&:last-child {
							border-right: 1px solid $table-row-bg-hover;
						}
					}

					// .b-cell--strong {
					// 	border: 1px solid $table-row-bg-hover;
					// }
				}
				// &:nth-child(2n) {
				// 	background-color: #EAF2FC;
				// }
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
			padding: 9px 15px;
			transition: background-color 0.2s linear;
			background-clip: padding-box;

			&:first-child {
				text-align: left;
			}
		}
	}

	&--min {
		table {
			td {
				font-size: 16px;
				@include font(300, 'Ubuntu');
				line-height: 21px;
			}
		}
	}

	&__border {
		border-top: 4px solid #133A60 !important;
	}
	//Подзаголовок
	.b-subhead {
		font-weight: bold;
		text-transform: uppercase;
		color: $table--subhead-color;

		&--level-3 {
			text-transform: none;
		}
	}

	.b-subhead--level-1 td:first-child {
		padding-left: 0;
	}

	.b-subhead--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-subhead--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	//Выделеная ячейка
	.b-cell--strong {
		color: #009FE3;
		text-transform: none;
	}

	.b-cell--strong + .b-cell--strong {
		&:before {
			@include pseudo(1px, 100%, '');
			top: 0;
			left: 0;
		}
	}

	.b-cell--level-1 {
		padding-left: 0;
	}

	.b-cell--level-2 {
		@include rem(padding-left, 25px);
	}

	.b-cell--level-3 {
		@include rem(padding-left, 50px);
	}

	.b-cell--regular {
		font-weight: 400 !important;
	}

	.b-cell--bold {
		font-weight: 700 !important;
	}

	//Выделеная строка
	.b-row--strong {
		color: $table--strong-row-color;

		td {
			color: #009FE3;
		}

		.is-active,
		.is-hover {
			// background-color: $table--strong-row-bg-js;
		}

		&:hover {
			// background-color: $table--strong-row-bg-hover;
		}
	}

	//Выбранная строка(по клику)
	.b-selected-row {
		td {
		// border: 1px solid $table--selected-row-bg !important;
		border-top: 1px solid $table--selected-row-bg !important;
		border-bottom: 1px solid $table--selected-row-bg !important;
			&::before,
			&::after {
				background-color: $table--selected-row-bg !important;
				opacity: 1;
			}

			&:first-child {
				border-left-color: $table--selected-row-bg !important;
			}

			&:last-child {
				border-right-color: $table--selected-row-bg !important;
			}
		}

		.b-cell--strong {
			// border: 1px solid $table--selected-row-bg-strong !important;
		}
	}

	//Итоговая строка
	.b-summary {
		font-weight: bold;
		color: $table--summary-color;
		background-color: transparent !important;
		font-size: 20px;
		line-height: 29px;
		// border-bottom: 1px solid $table--summary-bg !important;
		text-transform: uppercase;
		td {
			&::before {
				display: none;
			}

			&:first-child {
				border-left-color: transparent !important;
			}

			&:last-child {
				border-right-color: transparent !important;
			}
		}

		&:hover {
			border: 0 !important;
			// border-bottom: 1px solid $table--summary-bg !important;

			td {
				&::before {
					display: none;
				}

				// &:first-child,
				// &:last-child {
				// 	border-color: transparent;
				// }
			}
		}

		.is-active,
		.is-hover {
			border: 0 !important;
			border-bottom: 1px solid $table--summary-bg !important;
		}
	}

	.b-summary--level-1 td:first-child {
		padding-left: 0;
	}

	.b-summary--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-summary--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 4;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 0;
		background-color: $table--fixed-head-bg;
	}

	// Цвет всего столбца, по наведению на ячейку
	.is-hover {
		background-color: $color-eallery;
	}

	// Цвет всего столбца, по клику на заголовок столбца
	.is-active {
		background-color: $color-eallery;
	}

	.figure-buttons {
		margin-top: 16px;
	}
}

.b-table--simple {
	table {
		thead {
			th,
			td {
				text-align: left;
			}
		}

		td,
		th {
			text-align: left;
		}
	}
}


.b-table--no-scroll {
	overflow: hidden;
}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	color: $color-pacific-blue;
	text-decoration: none;
	@include webfont-icon($webfont-icon--i-expand, after);
	@include font(500, 'Ubuntu');
	display: inline-flex;
	align-items: center;
	position: absolute;
    right: 7px;
	bottom: 0;
	padding: 0;
	font-size: 18px;
	background-color: transparent;
	border: 0;

	&::after {
		font-size: 15px;
		margin-left: 5px;
		display: inline-block;
	}

	&:hover {
		color: $color-regal-blue;
	}

	&:active {
		color: $color-supernova;
	}

	&.is-expand {
		&::after {
			transform:  rotate(180deg);
		}
	}
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
	}
}

.b-table__content,
.b-table {
	::-webkit-scrollbar {
		height: 15px;
		border-radius: 10px;
	}

	::-webkit-scrollbar-track {
		background: $color-white;
	}

	::-webkit-scrollbar-thumb {
		background: #33B2E9;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		@include bg-rgba(51, 178, 233, 1);
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: #33B2E9;
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	@extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		&:not(.disabled):hover {
			opacity: 0.7;
		}

		&.disabled {
			opacity: 0.4;
		}

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: $color-white;
	}

	.b-scroll-arrow--left {
		background: linear-gradient(90deg, #009FE3 0%, rgba(0, 159, 227, 0) 77%);
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, #009FE3 0%, rgba(0, 159, 227, 0) 77%);
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		background: linear-gradient(90deg, rgba(0, 159, 227, 0) 0%, #009FE3 77%);
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(0, 159, 227, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			table {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				@include font-size(20px);
			}
		}

		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}
