.inset {
	position: relative;
	color: $color-davys-grey;
	font-size: 14px;
	font-weight: 400;
	border-radius: 30px 0;
	// overflow: hidden;

	&--link {
		border: 2px solid $color-pacific-blue;
		padding: 22px 21px 0;
		color: $color-regal-blue;
		margin-bottom: 20px;

		p {
			font-size: 18px;
			line-height: 22px;

			@media (max-width: 1060px) {
				font-size: 14px;
			}

			@media (max-width: 812px) {
				font-size: 18px;
			}					
		}

		a {
			text-decoration: underline;

			&::before {
				display: none !important;
			}

			&:hover {
				text-decoration: none;
				color: $color-pacific-blue;
			}

			&:active {
				color: $color-supernova;
			}
		}
	}

	&--image {
		background-image: url(/images/content/inset-image.png);
		background-size: 100%;
		padding: 43px 34px 1px 19px;
		background-repeat: no-repeat;
		color: $color-white;
		@include font(500);
		width: calc(100% - 12px);
		line-height: 21px;
		margin-bottom: 20px;
		background-size: cover;
		position: relative;
		border-radius: 40px 0;
		font-weight: 700;

		&::before {
			content: '';
			position: absolute;
			width: 25px;
			height: 26px;
			@include svg(100%, 100%, "/images/icons/i-inset-white-icons.svg");
			top: -8px;
    		left: 14px;
		}

		&::after {
			content: '';
			position: absolute;
			right: -19px;
			top: 10px;
			@include svg(100%, 100%, "/images/icons/i-inset-icons.svg");
			width: 35px;
			height: 45px;
		}
	}

	&--image-second,
	&--image-blue {
		background-image: url(/images/content/inset-image.png);
		width: 100%;
		padding: 22px 34px 1px 19px;

		&::before,
		&::after {
			display: none;
		}
	}

	&--image-blue {
		border-color: transparent;
		background-image: url(/images/content/inset-blue.png);
		color: $color-regal-blue;

		.inset__icon {
			color: $color-regal-blue;

			&::before {
				color: inherit;
			}
		}

		a {
			color: $color-regal-blue;

			&:hover {
				color: #fff;
			}

            &:visited {
                color: #f7f7f7;
            }
		}
    }
	&--green {
		background-image: url(/images/content/pattern_green.png);
	}

	&--blue {
		background-image: url(/images/content/pattern_blue.png);
	}

	&--border {
		border: 8px solid #009FE3;
		border-radius: 60px 0;
		position: relative;
		padding: 40px 25px 17px;
		margin-top: 50px;
		font-weight: 300;
		ul {
			margin-bottom: 0 !important;
			font-weight: 300;

			li:last-child {
				margin-bottom: 0 !important;
			}
		}

		@media (max-width: 1280px) {
			padding: 40px 15px 17px;
		}
	}

	&--title {
		margin-bottom: 20px;

		ul {
			font-weight: 300;
			margin-bottom: 0 !important;

			li:last-child {
				margin-bottom: 0 !important;
			}
		}
	}

	&__title {
		font-weight: 500;
		font-size: 20px;
		line-height: 20px;
		color: $color-white;
		background-color: #009FE3;
		border-radius: 20px 0;
		padding: 10px 70px 10px 20px;
		display: inline-block;
	}

	&__wrapper-block {
		border: 4px solid #BDBDBD;
		border-radius: 0 40px;
		padding: 20px;
	}

	&__text {
		color: #3F3F3F;
	}

	&__year {
		position: absolute;
		top: -49px;
		right: 6px;
		font-size: 67px;
		line-height: 80px;
		text-transform: uppercase;
		color: $color-white;
		text-shadow: -1px 1px $color-dim-gray, 1px 1px $color-dim-gray, 1px -1px $color-dim-gray, -1px -1px $color-dim-gray;//
		font-weight: 700;
		background-color: $color-white;

		@media (max-width: 1770px) {
			font-size: 55px;
    		line-height: 70px;
		}

		@media (max-width: 1500px) {
			font-size: 40px;
    		line-height: 70px;
		}

		@media (max-width: 1260px) {
			font-size: 50px;
    		line-height: 60px;
		}

		@media (max-width: 850px) {
			font-size: 45px;
    		line-height: 60px;
		}
	}

	&__icon {
		@include webfont-icon($webfont-icon--i-external-link);
		display: block;
		margin-bottom: 14px;
		&:before {
			display: inline-block;
			@include font-size(17px);
			color: $color-pacific-blue;
		}
	}

	&--icons {
		margin-bottom: 30px;
	}

	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__icons {
		flex: 1 0 auto;
		width: 46px;
		height: 46px;
		margin-right: 15px!important;
	}

	&__column {
		display: flex;
		align-items: center;
		margin-right: 45px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__icon-text {
		margin-left: 15px;
		font-size: 16px;
		line-height: 19px;
		color: #3F3F3F;
		font-weight: 400;
	}

	@include breakpoint(sm) {
		&--image {
			padding: 76px 77px 1px 29px;
			width: 100%;
		}

		&--image-second,
		&--image-blue {
			padding: 22px 34px 1px 35px;
		}

		&--icons {
			margin-bottom: 0;
		}

		&__wrapper {
			align-items: baseline;
			flex-direction: column;
		}

		&__column {
			margin-bottom: 10px;
		}
	}

	@include breakpoint(xs) {
		&--image {
			padding: 46px 17px 1px 29px;

			&::after {
				right: -15px;
			}
		}

		&--image-second,
		&--image-blue {
			padding: 22px 34px 1px 35px;
		}
	}
}

.inset--with-title {
	@include rem(padding, 25px 25px 25px);
	border: 1px solid $color-davys-grey;
	color: $color-davys-grey;

	figcaption {
		font-size: 18px;
		font-weight: bold;
		color: $color-davys-grey;
		position: absolute;
		top: 0;
		@include rem(left, 15px);
		transform: translateY(-50%);
		background: #fff;
		@include rem(padding, 0 10px);
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.inset-image {
	position: relative;
	line-height: 0;
	margin-bottom: 30px;

	&--bottom & {
		&__text {
			top: auto;
			bottom: 0;
			font-size: 16px;
			line-height: 15px;
			background: #0F62A1;
			max-width: 559px;
			padding: 10px 42px;

			@media (max-width: 1280px) {
				position: relative;
				padding: 10px;
			}
		}
	}

	&__text {
		&--top {
			position: absolute;
			top: 0;
			bottom: auto;
			font-size: 16px;
			line-height: 18px;
			background: #F5F7F8;
			max-width: 589px;
			color: #5C5E62;
			padding: 10px 57px 10px 27px;
			font-weight: 300;

			@media (max-width: 1248px) {
				padding: 10px 80px 10px 15px;
				position: relative;
			}

			h2 {
				color: #5C5E62;
			}
		}
	}

	&__closed {
      	&--text {
		    position: absolute;
		    top: 60px;
		    right: 15px;
		    text-transform: uppercase;
		    color: #ED1C25;
		    max-width: 60px;
      	}
		&::after {
		    position: absolute;
		    top: 30px;
		    right: 20px;
		    content: "\274c";
		    font-size: 30px;
		    color: #ED1C25;
		    line-height: 0;
		    text-align: center;
		}
	}

	&__text {
		position: absolute;
		padding: 7px 7px;
		background-color: #009FE3;
		color: $color-black;
		top: 0;
		font-weight: 500;
		font-size: 20px;
		line-height: 20px;
		color: $color-white;
		padding: 4px 14px;
	}

	&.orange & {
		&__text {
			background-color: #F9B234;
		}
	}

	&.blue & {
		&__text {
			background-color: #0F62A1;
		}
	}

	&.dark-blue & {
		&__text {
			background-color: #0F69A4;
		}
	}

	&.green & {
		&__text {
			background-color: #71C063;
		}
	}
}
