.safety-ecology {
	&__nort-prog,
	&__dev-global {
		position: relative;
		width: 100%;

		@include breakpoint-width($breakpoint-mobile - 1px) {
			display: none;
		}
	}

	h1 {
		// width: calc(100% - 218px);
		max-width: 960px;

		color: lighten($color-green, $amount: 5%);

		@include breakpoint-width($breakpoint-mobile - 1px) {
			width: 100%;
		}
	}

	a {
		text-decoration: none;
	}

	.dev-global {
		&__border {
			position: relative;
			width: 100%;
		}

		&__content {
			position: absolute;
			width: 100%;

			top: 12%;

			z-index: 1;
		}

		&__block-2,
		&__graph {
			margin-top: 3% !important;
		}
	}

	&__nort-prog {
		margin-top: 4% !important;
	}

	.nort-prog {
		&__border {
			position: relative;
			width: 100%;
		}

		&__content {
			position: absolute;
			width: 100%;

			top: 18%;

			z-index: 1;
		}

		&__block-2 {
			margin-top: 4% !important;
		}
	}
}

#ecology-1,
#ecology-2 {
	&-border {
		display: none;
		// opacity: 0;
	}

	&-title {
		display: none;
		// opacity: 0;
	}

	&-head-title {
		display: none;
		// opacity: 0;
	}
}

.ecology-btn {
	cursor: help;
}

.js-ecology {
	// display: none;
	opacity: 0;
	// transform-origin: 50% 0;
	// transform: perspective(1000px) rotateX(-90deg);

	// transition: all .5s cubic-bezier(0.6, 0.04, 0.98, 0.335);

	// &.flip {
	//     transform: perspective(1000px) rotateX(0deg);
	//     transform-style: preserve-3d;
	// }
}
