.safety-ecology,
.product-acceleration {
	.tooltipster-content {
		p {
			margin-bottom: 0;
		}
	}
}

// .l-layout-wrapper--1 {
// 	.browser-ie & {
// 		height: max-content;
// 	}
// }
