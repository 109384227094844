/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
	max-width: none;
}

#colorbox,
#cboxWrapper,
#cboxContent {
	max-height: 100vh;
}

#cboxContent {
	overflow-x: hidden;
	overflow-y: auto;
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
	clear: left;
}

#cboxContent {
	position: relative;
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxTitle {
	margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
	cursor: pointer;
}

.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
	background: #000;
	opacity: 0.9;
	filter: alpha(opacity = 90);
}

#colorbox {
	outline: 0;
}

#cboxContent {
	margin-top: 20px;
	background: #000;
}

.cboxIframe {
	background: #fff;
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}

#cboxLoadedContent {
	border: 5px solid #000;
	background: #fff;
}

#cboxTitle {
	position: absolute;
	top: -20px;
	left: 0;
	color: #ccc;
}

#cboxCurrent {
	position: absolute;
	top: -20px;
	right: 0px;
	color: #ccc;
}

#cboxLoadingGraphic {
	// background: url("/images/vendor/colorbox/loading.gif") no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
	outline: 0;
}

#cboxSlideshow {
	position: absolute;
	top: -20px;
	right: 90px;
	color: #fff;
}

#cboxPrevious {
	position: absolute;
	top: 50%;
	left: 5px;
	margin-top: -32px;
	// background: url("/images/vendor/colorbox/controls.png") no-repeat top left;
	width: 28px;
	height: 65px;
	text-indent: -9999px;
}

#cboxPrevious:hover {
	background-position: bottom left;
}

#cboxNext {
	position: absolute;
	top: 50%;
	right: 5px;
	margin-top: -32px;
	// background: url("/images/vendor/colorbox/controls.png") no-repeat top right;
	width: 28px;
	height: 65px;
	text-indent: -9999px;
}

#cboxNext:hover {
	background-position: bottom right;
}

#cboxClose {
	position: absolute;
	top: 5px;
	right: 5px;
	display: block;
	// background: url("/images/vendor/colorbox/controls.png") no-repeat top center;
	width: 38px;
	height: 19px;
	text-indent: -9999px;
}

#cboxClose:hover {
	background-position: bottom center;
}


$zoom--overlay-bg-color: #212121;
$zoom--box-bg-color: $color-white;

$zoom--btn-color: $color-pacific-blue;
$zoom--btn-color-hover: $color-regal-blue;
$zoom--btn-color-active: $color-supernova;

$zoom--close-btn-color: $zoom--btn-color;
$zoom--close-btn-color-hover: $zoom--btn-color-hover;
$zoom--close-btn-color-active: $zoom--btn-color-active;

.button--zoom {
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--i-zoom);
	font-size: 0;
	color: $zoom--btn-color;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 25px;
	}

	&:hover,
	&:focus {
		color: $zoom--btn-color-hover;
	}

	&:active {
		color: $zoom--btn-color-active;
	}
}

#cboxClose {
	position: absolute;
	display: block;
	top: 25px;
	right: 30px;
	width: 15px;
	height: 15px;
	background: none;
	text-indent: 0;
	@include webfont-icon($webfont-icon--close_2);
	font-size: 0;
	transition: background-color 0.2s ease, transform 0.2s ease;
	color: $color-eclipse;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 15px;
	}

	&:hover,
	&:focus {
		color: $color-pacific-blue;
	}

	&:active {
		color: $color-supernova;
	}
}

#cboxOverlay {
	background: $zoom--overlay-bg-color;
	opacity: 0.6;
	filter: alpha(opacity = 60);
}

#cboxContent {
	margin-top: 0;
}

#cboxLoadedContent {
	border: 1px solid $color-pacific-blue;
	background-color: $zoom--box-bg-color;
	padding: 40px 30px 26px 20px;

	figcaption {
		text-align: left;
		font-size: 20px;
	}

	.caption__units,
	.b-caption__units {
		font-size: 20px;
	}

	.graphic {
		text-align: center;
	}

	.b-table {
		figcaption {
			padding-right: 70px;
		}

		.figure-buttons {
			// position: absolute;
			// right: 65px;
			// margin-top: 0;
			// top: 23px;
		}
	}
}

@include breakpoint(sm) {
	#cboxLoadedContent {
		.b-table .figure-buttons {
			display: none;
		}
	}
}
