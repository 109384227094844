.b-logo {
	display: block;
	height: 40px;
	width: 192px;

	&__image {
		width: 100%;
		height: 100%;

		&--mobile {
			display: none;
		}

		.body--main-page & {
			display: none;
		}

		&--main {
			display: none;

			.body--main-page & {
				display: block;
			}
		}
	}

	@include breakpoint(md) {
		height: 35px;
	}

	@include breakpoint(sm) {
		height: 28px;
		width: 50px;
		&__image {
			display: none;
			&--mobile {
				display: block;
				.body--main-page & {
					display: block;
				}
			}

			&--main {
				display: none;
	
				.body--main-page & {
					display: none;
				}
			}
		}
	}
}

.logo-svg {
	width: 100%;
	height: 100%;
}
