//text
$base-text-color: $color-eclipse;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

//links
$link-default-color: $color-pacific-blue;
$link-hover-color: $color-regal-blue;
$link-active-color: $color-supernova;
$link-visited-color: $color-jordy-blue;

//buttons
$btn--bg: $color-supernova;
$btn--bg-hover: $color-pacific-blue;
$btn--bg-active: $color-pacific-blue;
$btn--bg-visited: $color-supernova;
$btn--color: $color-white;
$btn--color-hover: $color-white;
$btn--color-active: $color-white;
$btn--color-visited: $color-supernova;

//checkbox
$checkbox--color: $color-pacific-blue;
$checkbox--color-hover: $color-regal-blue;
$checkbox--color-checked: $color-supernova;
$checkbox--color-partly: $color-gray;
$checkbox--bg-color: $color-white;

// xls, zoom, analysis buttons
$tools--color: $color-ironside-gray;
$tools--color-hover: $color-black;
$tools--color-active: $color-carrot-orange;

// tooltips
$t_tooltips--bg-color: $color-white;
$t_tooltips--color: $color-white;
$t_tooltips--border: $color-supernova;

// analysis
$analysis-font-color: $color-eclipse;
$analysis-lemon: $color-pacific-blue;
$analysis-pink-flare: $color-supernova;
$analysis-bg-active: $color-supernova;
$analysis-bg-smoke: #F8F8F8;
// $analysis-bg-hover: $cream;
// $analysis-button: $color-scarpa-flow;
// $analysis-dashed-border: $old-lavender;

@if ($contrast) {
	$base-text-color: $color-white;
	$error-text-color: #ff0000;
	$error-form-bg: #fff;
	$link-default-color: $base-text-color;
	$link-hover-color: $color-carrot-orange;
	$link-visited-color: #252525;
	$btn--bg: #ededed;
	$btn--bg-hover: #484848;
	$btn--bg-active: #252525;
	$btn--color: #656565;
	$btn--color-hover: $color-white;
	$btn--color-active: $color-white;
	$tools--color: $color-ironside-gray;
	$tools--color-hover: $color-black;
	$tools--color-active: $color-carrot-orange;
}
