$bod_bod-gallery--name-color: $color-navy-blue;
$bod_bod-gallery--bod-name-color-hover: $color-white;
$bod_bod-gallery--border-color: $color-prussian-blue;
$bod_bod-gallery--close-icon-color: $color-ironside-gray;
$bod_bod-gallery--close-icon-color-hover: $color-taupe-gray;
$bod_bod-gallery--pagination-button-color: $color-ironside-gray;
$bod_bod-gallery--pagination-button-color-hover: $color-taupe-gray;
$bod_bod-gallery--pagination-button-color-active: $color-gray;

@if ($contrast) {
	$bod_bod-gallery--name-color: $color-white;
	$bod_bod-gallery--bod-name-color-hover: $color-white;
	$bod_bod-gallery--border-color: $color-white;
	$bod_bod-gallery--close-icon-color: $color-white;
	$bod_bod-gallery--close-icon-color-hover: $color-dark-jungle;
	$bod_bod-gallery--pagination-button-color: $color-white;
	$bod_bod-gallery--pagination-button-color-hover: $color-mountain-mist;
	$bod_bod-gallery--pagination-button-color-active: $color-gray;
}

.b-bod--gallery {
	%bod__name {
		@include font-size(17px);
		color: $bod_bod-gallery--name-color;
	}

	%bod__post {
		@include font-size(14px);
		text-transform: none;
		margin: 0;
	}

	.b-bod-main {
		@include rem(padding-top, 20px);
		@include rem(min-width, 300px);

		&__inner {
			padding-top: $grid-gutter-width;
			padding-bottom: $grid-gutter-width;
		}

		&__item {
			width: 100%;
			overflow: hidden;
			position: relative;
			text-align: center;

			&:hover {
				cursor: pointer;

				.b-bod-main__sign {
					color: $bod_bod-gallery--bod-name-color-hover;
					background-color: #009FE3;
					bottom: 0;
					padding: 10px 20px;
				    border-radius: 23px 0;
    				min-height: 40px;
				}

				.b-bod-main__photo:before {
					background-color: #fff;
					height: 20px;
				}
			}
		}

		&__photo {
			width: 100%;
			@include rem(width, 215px);
			@include rem(height, 230px);
			max-height: 230px;
			max-width: 215px;
			margin: auto;
			position: relative;
			border-radius: 30px 0;

			img {
				width: 100%;
			}



			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				height: 10px;
				width: 100%;
				background-color: #009FE3;
			}
		}

		&__sign {
			width: 100%;
			bottom: -100%;
			transition: bottom 0s ease;
			position: absolute;

			.b-bod-main__name {
				color: $color-white;
			}
		}

		&__name {
			@extend %bod__name;
			@include font-size(18px);
			line-height: 1.2;
			font-weight: 500;
			margin: 0;
		}

		&__post {
			@extend %bod__post;
		}

		&__text {
			display: none;
		}
	}

	.b-bod-expandable-content {
		@include rem(min-width, 320px);

		&__photo {
			width: 100%;
		}

		&__inner {
			position: relative;
			display: none;
			@include rem(border-width, 8px);
			border-style: solid;
			border-color: #009FE3;

			border-radius: 60px 0;
			@include rem(padding, 40px);
		}

		&__text,
		&__biography {
			p.subtitle {
				font-weight: 600;
				color: #009FE3;

				span {
					font-weight: 300;
					color: #3F3F3F;
				}
			}
		}

		&__close-button {
			cursor: pointer;
			position: absolute;
			@include rem(right, 20px);
			@include rem(top, 20px);
			background-color: transparent;
			@include webfont-icon($webfont-icon--close_2);
			color: #133A60;
			@include font-size(30px);

			&:hover {
				color: #009FE3;
			}
		}

		&__head {
			margin-top: 0;
			margin-bottom: 20px;
			padding-bottom: 30px;
			border-bottom: 4px solid #133A60;
		}

		&__name {
			@extend %bod__name;
			@include font-size(28px);
			color: #009FE3;
		}

		&__subname {
			@include font-size(24px);
			font-weight: 600;
			margin-bottom: 0px;
			color: #3F3F3F;
		}

		&__post {
			@include font-size(16px);
			text-transform: none;
			margin-top: 0;
			color: #3F3F3F;
			line-height: 18px;
			font-weight: 500;
		}

		&__pagination-btn {
			position: absolute;
			right: 0;
			bottom: 0;
			cursor: pointer;
			background-color: transparent;

			&:hover {
				background-color: transparent;
				color: #009FE3;
			}

			&:active {
				background-color: transparent;
				color: #133A60;
			}

			&:before {
				@include font-size(18px);
			}
		}

		&__pagination-btn[disabled] {
			background-color: transparent;
			cursor: default;

			&:hover,
			&:active {
				background-color: transparent;
			}
		}

		&__pagination-btn--prev {
			@include rem(right, 60px);
			@include webfont-icon($webfont-icon--arrow-left);
			color: #133A60;
		}

		&__pagination-btn--next {
			@include rem(right, 25px);
			@include webfont-icon($webfont-icon--arrow-right);
			color: #133A60;
		}
	}

	@include breakpoint(md) {
		
		.b-bod-expandable-content {

			&__pagination-btn {
				display: none;
			}
		
			&__close-button {
				font-size: 20px;
				top: 10px;
				right: 5px;
			}
		}
	}
}
