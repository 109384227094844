.elementary-bod {
	position: relative;

	&__blue {
		@include webfont-icon($webfont-icon--icon-blue);

		&:before {
			color: #00AEEF;
			@include rem(font-size, 45px);
			z-index: 1;
			position: absolute;
			top: 20px;
			width: 40px;
			height: 40px;
		}
	}

	&__green {
		@include webfont-icon($webfont-icon--icon-green);

		&:before {
			color: #71C063;
			@include rem(font-size, 45px);
			z-index: 1;
			position: absolute;
			top: 20px;
			width: 40px;
			height: 40px;
		}
	}

	&__yellow {
		@include webfont-icon($webfont-icon--icon-yellow);

		&:before {
			color: #FBB040;
			@include rem(font-size, 45px);
			z-index: 1;
			position: absolute;
			top: 20px;
			width: 40px;
			height: 40px;
		}
	}
	div.photo {
		@include rem(padding, 20px);
		img {
			position: relative;
			border-radius: 0 45px;
			@include rem(margin, 20px);
			@include rem(margin-bottom, 30px);
		}
	}

	.person__name {
		position: relative;
		font-weight: 600;
		@include rem(font-size, 22px);
		@include rem(line-height, 28px);
		@include rem(margin-bottom, 15px);
		@include rem(padding-left, 20px);
		color: #133A60;
	}

	.person__post {
		position: relative;
		font-weight: 400;
		@include rem(font-size, 20px);
		@include rem(line-height, 24px);
		@include rem(padding-left, 20px);
		color: #00B9F1;
	}
}

