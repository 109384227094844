.b-nav--left.b-nav--vertical-expandable {
	.b-nav__list {
		@extend .clearfix;
		background-color: $menu--list-lvl1-bg;
	}

	.b-nav__list--lvl2 {
		display: none;
		position: absolute;
		top: 0;
		left: 100%;
		width: 100%;
		min-height: 100%;
		background-color: $menu--list-lvl2-bg;
		z-index: 5;
	}

	.b-nav__list--lvl3 {
		display: none;
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		min-height: 100%;
		background-color: $menu--list-lvl3-bg;
	}

	.b-nav__link {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		color: $menu_vertical--link-color;
		@include font-size($menu_vertical--font-size);
		@include rem(padding-left, 15px);
		@include rem(padding-top, 15px);
		@include rem(padding-bottom, 15px);
		text-decoration: none;
		line-height: normal;

		span {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			@include rem(line-height, 15px);
		}

		&.is-expand,
		&:hover {
			color: $menu_vertical--link-color-expand-hover;
			background-color: $menu_vertical--link-bg-expand-hover;
		}

		&:active {
			color: $menu_vertical--link-color-active;
			background-color: $menu_vertical--link-bg-active;
		}

		&.current {
			color: $menu_vertical--link-color-current;
			background-color: $menu_vertical--link-bg-current;
		}
	}

	.b-nav__link--lvl1 {
		@include rem(padding-right, 30px);
	}

	.b-nav__link--lvl2 {
		@include rem(padding-right, 30px);
	}

	.b-nav__link--lvl3 {
		@include rem(padding-right, 30px);
	}

	.has-submenu {
		> .b-nav__link {
			@include webfont-icon($webfont-icon--arrow-right, 'after');

			&:after {
				position: absolute;
				top: 0;
				@include rem(right, 15px);
				bottom: 0;
				margin: auto;
				@include font-size(10px);
				height: 10px;
			}
		}
	}
}
