.b-sitemap {
	.b-sitemap__item {
		&:before {
			display: none;
		}
	}

	.b-sitemap__item--lvl1 {
		padding-left: 0;

		.b-sitemap__item {
			padding-left: 1.2em;
		}
	}

	ul.b-sitemap__list--lvl2 {
		@include rem(margin, 10px 0);
	}

	ul.b-sitemap__list--lvl3 {
		@include rem(margin, 5px 0);
	}

	.b-sitemap__item--lvl3,
	.b-sitemap__item--lvl4 {
		&::before {
			display: block;
		}
	}

	&__link {
		display: inline;
		text-decoration: none;

		&:visited {
			color: $link-default-color;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&__link--lvl1 {
		font-weight: 500;
		
	}

	&__link--lvl2 {
		@include rem(margin-bottom, 10px);
		font-weight: 300;
	}

	&__link--lvl3 {
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
	}

	&__link--lvl4 {
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
	}

	&__list--lvl1 {
		& > .b-sitemap__item {
			margin-bottom: 1.55em;
		}
	}
}
