// tzar-core/src/gulp/utils/webfont-template.scss - файл-шаблон для генерации иконочного шрифта
// tzar-template/src/styles/webfont.scss - генерируемый файл шрифта, генерируется автоматически при сборке!

$icon_font_name:"webfont";

$webfont-icon--analyse: "\E001";
$webfont-icon--arrow-left: "\E002";
$webfont-icon--arrow-right: "\E003";
$webfont-icon--books: "\E004";
$webfont-icon--box-add: "\E005";
$webfont-icon--camera: "\E006";
$webfont-icon--chart-download: "\E007";
$webfont-icon--checkmark: "\E008";
$webfont-icon--clipboard: "\E009";
$webfont-icon--clock: "\E00A";
$webfont-icon--close: "\E00B";
$webfont-icon--close_2: "\E00C";
$webfont-icon--download: "\E00D";
$webfont-icon--external-link: "\E00E";
$webfont-icon--eye: "\E00F";
$webfont-icon--facebook: "\E010";
$webfont-icon--file-empty: "\E011";
$webfont-icon--file-excel: "\E012";
$webfont-icon--file-pdf: "\E013";
$webfont-icon--file-text: "\E014";
$webfont-icon--file-word: "\E015";
$webfont-icon--file-zip: "\E016";
$webfont-icon--foursquare: "\E017";
$webfont-icon--google-plus: "\E018";
$webfont-icon--google-plus_2: "\E019";
$webfont-icon--histogram: "\E01A";
$webfont-icon--home: "\E01B";
$webfont-icon--i-add-report: "\E01C";
$webfont-icon--i-arrow-bottom: "\E01D";
$webfont-icon--i-arrow: "\E01E";
$webfont-icon--i-breadcrumbs: "\E01F";
$webfont-icon--i-dc: "\E020";
$webfont-icon--i-del: "\E021";
$webfont-icon--i-download-pdf: "\E022";
$webfont-icon--i-expand: "\E023";
$webfont-icon--i-external-link: "\E024";
$webfont-icon--i-fb-tools: "\E025";
$webfont-icon--i-fb: "\E026";
$webfont-icon--i-feedback: "\E027";
$webfont-icon--i-gistogram: "\E028";
$webfont-icon--i-graf: "\E029";
$webfont-icon--i-hand: "\E02A";
$webfont-icon--i-history: "\E02B";
$webfont-icon--i-image: "\E02C";
$webfont-icon--i-inst: "\E02D";
$webfont-icon--i-int-analysis: "\E02E";
$webfont-icon--i-int-map: "\E02F";
$webfont-icon--i-mail: "\E030";
$webfont-icon--i-map-close: "\E031";
$webfont-icon--i-mouse: "\E032";
$webfont-icon--i-my-report: "\E033";
$webfont-icon--i-pdf-double-page: "\E034";
$webfont-icon--i-pdf-page: "\E035";
$webfont-icon--i-print: "\E036";
$webfont-icon--i-search: "\E037";
$webfont-icon--i-share: "\E038";
$webfont-icon--i-sitemap: "\E039";
$webfont-icon--i-table: "\E03A";
$webfont-icon--i-tools: "\E03B";
$webfont-icon--i-tooltip: "\E03C";
$webfont-icon--i-tw-tools: "\E03D";
$webfont-icon--i-tw: "\E03E";
$webfont-icon--i-vk-tools: "\E03F";
$webfont-icon--i-vk: "\E040";
$webfont-icon--i-xls-analysis: "\E041";
$webfont-icon--i-xls: "\E042";
$webfont-icon--i-yotube: "\E043";
$webfont-icon--i-zoom: "\E044";
$webfont-icon--icon-blue: "\E045";
$webfont-icon--icon-green: "\E046";
$webfont-icon--icon-yellow: "\E047";
$webfont-icon--instagram: "\E048";
$webfont-icon--lang-en: "\E049";
$webfont-icon--lang-ru: "\E04A";
$webfont-icon--library: "\E04B";
$webfont-icon--line: "\E04C";
$webfont-icon--linkedin: "\E04D";
$webfont-icon--location: "\E04E";
$webfont-icon--mail: "\E04F";
$webfont-icon--menu: "\E050";
$webfont-icon--microphone: "\E051";
$webfont-icon--next: "\E052";
$webfont-icon--pdf-double-page: "\E053";
$webfont-icon--pdf-page: "\E054";
$webfont-icon--pdf: "\E055";
$webfont-icon--phone: "\E056";
$webfont-icon--prev: "\E057";
$webfont-icon--printer: "\E058";
$webfont-icon--reload: "\E059";
$webfont-icon--reload_2: "\E05A";
$webfont-icon--report: "\E05B";
$webfont-icon--search: "\E05C";
$webfont-icon--share: "\E05D";
$webfont-icon--tap-hand: "\E05E";
$webfont-icon--tap: "\E05F";
$webfont-icon--tools: "\E060";
$webfont-icon--tooltip: "\E061";
$webfont-icon--tree: "\E062";
$webfont-icon--twitter: "\E063";
$webfont-icon--up: "\E064";
$webfont-icon--users: "\E065";
$webfont-icon--vk: "\E066";
$webfont-icon--vk_2: "\E067";
$webfont-icon--youtube: "\E068";
$webfont-icon--youtube_2: "\E069";
$webfont-icon--zoom-in: "\E06A";

@mixin webfont-icon($icon-code, $after-or-before: 'before') {
	&:#{$after-or-before} {
		content: #{'"' + $icon-code + '"'};
		display: inline-block;
		font-family: $icon_font_name;
		font-style: normal;
		line-height: 1;
		color: currentColor;
		-webkit-font-smoothing: antialiased;
		speak: none;
	}
}

@font-face {
	font-family: $icon_font_name;
	src: url('/fonts/webfont/webfont.woff') format('woff'),
	url('/fonts/webfont/webfont.woff2') format('woff2'),
	url('/fonts/webfont/webfont.svg#webfont') format('svg');
}

.webfont-icon {
	font-family: $icon_font_name;
	display: inline-block;
	vertical-align: middle;
	font-style: normal;
	speak: none;
	color: currentColor;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.webfont-icon--analyse:before {
	content: '\E001';
}

.webfont-icon--arrow-left:before {
	content: '\E002';
}

.webfont-icon--arrow-right:before {
	content: '\E003';
}

.webfont-icon--books:before {
	content: '\E004';
}

.webfont-icon--box-add:before {
	content: '\E005';
}

.webfont-icon--camera:before {
	content: '\E006';
}

.webfont-icon--chart-download:before {
	content: '\E007';
}

.webfont-icon--checkmark:before {
	content: '\E008';
}

.webfont-icon--clipboard:before {
	content: '\E009';
}

.webfont-icon--clock:before {
	content: '\E00A';
}

.webfont-icon--close:before {
	content: '\E00B';
}

.webfont-icon--close_2:before {
	content: '\E00C';
}

.webfont-icon--download:before {
	content: '\E00D';
}

.webfont-icon--external-link:before {
	content: '\E00E';
}

.webfont-icon--eye:before {
	content: '\E00F';
}

.webfont-icon--facebook:before {
	content: '\E010';
}

.webfont-icon--file-empty:before {
	content: '\E011';
}

.webfont-icon--file-excel:before {
	content: '\E012';
}

.webfont-icon--file-pdf:before {
	content: '\E013';
}

.webfont-icon--file-text:before {
	content: '\E014';
}

.webfont-icon--file-word:before {
	content: '\E015';
}

.webfont-icon--file-zip:before {
	content: '\E016';
}

.webfont-icon--foursquare:before {
	content: '\E017';
}

.webfont-icon--google-plus:before {
	content: '\E018';
}

.webfont-icon--google-plus_2:before {
	content: '\E019';
}

.webfont-icon--histogram:before {
	content: '\E01A';
}

.webfont-icon--home:before {
	content: '\E01B';
}

.webfont-icon--i-add-report:before {
	content: '\E01C';
}

.webfont-icon--i-arrow-bottom:before {
	content: '\E01D';
}

.webfont-icon--i-arrow:before {
	content: '\E01E';
}

.webfont-icon--i-breadcrumbs:before {
	content: '\E01F';
}

.webfont-icon--i-dc:before {
	content: '\E020';
}

.webfont-icon--i-del:before {
	content: '\E021';
}

.webfont-icon--i-download-pdf:before {
	content: '\E022';
}

.webfont-icon--i-expand:before {
	content: '\E023';
}

.webfont-icon--i-external-link:before {
	content: '\E024';
}

.webfont-icon--i-fb-tools:before {
	content: '\E025';
}

.webfont-icon--i-fb:before {
	content: '\E026';
}

.webfont-icon--i-feedback:before {
	content: '\E027';
}

.webfont-icon--i-gistogram:before {
	content: '\E028';
}

.webfont-icon--i-graf:before {
	content: '\E029';
}

.webfont-icon--i-hand:before {
	content: '\E02A';
}

.webfont-icon--i-history:before {
	content: '\E02B';
}

.webfont-icon--i-image:before {
	content: '\E02C';
}

.webfont-icon--i-inst:before {
	content: '\E02D';
}

.webfont-icon--i-int-analysis:before {
	content: '\E02E';
}

.webfont-icon--i-int-map:before {
	content: '\E02F';
}

.webfont-icon--i-mail:before {
	content: '\E030';
}

.webfont-icon--i-map-close:before {
	content: '\E031';
}

.webfont-icon--i-mouse:before {
	content: '\E032';
}

.webfont-icon--i-my-report:before {
	content: '\E033';
}

.webfont-icon--i-pdf-double-page:before {
	content: '\E034';
}

.webfont-icon--i-pdf-page:before {
	content: '\E035';
}

.webfont-icon--i-print:before {
	content: '\E036';
}

.webfont-icon--i-search:before {
	content: '\E037';
}

.webfont-icon--i-share:before {
	content: '\E038';
}

.webfont-icon--i-sitemap:before {
	content: '\E039';
}

.webfont-icon--i-table:before {
	content: '\E03A';
}

.webfont-icon--i-tools:before {
	content: '\E03B';
}

.webfont-icon--i-tooltip:before {
	content: '\E03C';
}

.webfont-icon--i-tw-tools:before {
	content: '\E03D';
}

.webfont-icon--i-tw:before {
	content: '\E03E';
}

.webfont-icon--i-vk-tools:before {
	content: '\E03F';
}

.webfont-icon--i-vk:before {
	content: '\E040';
}

.webfont-icon--i-xls-analysis:before {
	content: '\E041';
}

.webfont-icon--i-xls:before {
	content: '\E042';
}

.webfont-icon--i-yotube:before {
	content: '\E043';
}

.webfont-icon--i-zoom:before {
	content: '\E044';
}

.webfont-icon--icon-blue:before {
	content: '\E045';
}

.webfont-icon--icon-green:before {
	content: '\E046';
}

.webfont-icon--icon-yellow:before {
	content: '\E047';
}

.webfont-icon--instagram:before {
	content: '\E048';
}

.webfont-icon--lang-en:before {
	content: '\E049';
}

.webfont-icon--lang-ru:before {
	content: '\E04A';
}

.webfont-icon--library:before {
	content: '\E04B';
}

.webfont-icon--line:before {
	content: '\E04C';
}

.webfont-icon--linkedin:before {
	content: '\E04D';
}

.webfont-icon--location:before {
	content: '\E04E';
}

.webfont-icon--mail:before {
	content: '\E04F';
}

.webfont-icon--menu:before {
	content: '\E050';
}

.webfont-icon--microphone:before {
	content: '\E051';
}

.webfont-icon--next:before {
	content: '\E052';
}

.webfont-icon--pdf-double-page:before {
	content: '\E053';
}

.webfont-icon--pdf-page:before {
	content: '\E054';
}

.webfont-icon--pdf:before {
	content: '\E055';
}

.webfont-icon--phone:before {
	content: '\E056';
}

.webfont-icon--prev:before {
	content: '\E057';
}

.webfont-icon--printer:before {
	content: '\E058';
}

.webfont-icon--reload:before {
	content: '\E059';
}

.webfont-icon--reload_2:before {
	content: '\E05A';
}

.webfont-icon--report:before {
	content: '\E05B';
}

.webfont-icon--search:before {
	content: '\E05C';
}

.webfont-icon--share:before {
	content: '\E05D';
}

.webfont-icon--tap-hand:before {
	content: '\E05E';
}

.webfont-icon--tap:before {
	content: '\E05F';
}

.webfont-icon--tools:before {
	content: '\E060';
}

.webfont-icon--tooltip:before {
	content: '\E061';
}

.webfont-icon--tree:before {
	content: '\E062';
}

.webfont-icon--twitter:before {
	content: '\E063';
}

.webfont-icon--up:before {
	content: '\E064';
}

.webfont-icon--users:before {
	content: '\E065';
}

.webfont-icon--vk:before {
	content: '\E066';
}

.webfont-icon--vk_2:before {
	content: '\E067';
}

.webfont-icon--youtube:before {
	content: '\E068';
}

.webfont-icon--youtube_2:before {
	content: '\E069';
}

.webfont-icon--zoom-in:before {
	content: '\E06A';
}

