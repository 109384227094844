$preloader--size-width: 80px;
$preloader--size-height: 80px;
$preloader--bg-color: $color-white;
$preloader--bg-opacity: 0.9;
$preloader--color: $color-gray;

.preloader {
	min-height: $preloader--size-height * 2;
	min-width: $preloader--size-width * 2;
	z-index: 999999999;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .5);
		z-index: 1;
	}

	$bgcolor: #247fbc;
	$goocolor: $color-pacific-blue;
	$bigsize: 50px;
	$smallsize: 30px;
	$containerwidth: 500px;
	$animoffset: 150px;
	$animtime: 2.5s;
	$containerheight: $bigsize*2;

	.loader {
		filter:url('#goo');
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width:$containerwidth;
		height:$containerheight;
		z-index: 2;
		&::after { //big goo in middle
			content:'';
			display:block;
			width:$bigsize;
			height:$bigsize;
			border-radius:50%;
			background:$goocolor;
			margin:0 auto;
			position:absolute;
			top:$containerheight/2 - $bigsize/2;
			left:$containerwidth/2 - $bigsize/2;
			animation:scale $animtime ease-in-out infinite;
		}
		div {
			position:absolute;
			width:$smallsize;
			height:$smallsize;
			border-radius:50%;
			background:$goocolor;
			top:$containerheight/2 -$smallsize/2;
			left:$containerwidth/2 - $smallsize/2;
			animation:move $animtime ease-in-out infinite alternate;
			&::after, &::before {
				content:'';
				display:block;
				position:absolute;
				width:$smallsize;
				height:$smallsize;
				border-radius:50%;
				background:$goocolor;
			}
			&::before {
				left:$smallsize*-2.5;
			}
			&::after {
				left:$smallsize*2.5;
			}
		}
	}

	@keyframes move {
		0% {
			transform:translateX($animoffset*-1);
		}
		100% {
			transform:translateX($animoffset);
		}
	}

	@keyframes scale {
		10% {
			transform:scale(1);
		}
		50% {
			transform:scale(1.25);
		}
		90% {
			transform:scale(1);
		}
	}

	svg { //Firefox bug fix
		position:absolute;
		z-index:-100;
		pointer-events:none;
	}
}

.preloader--is-button {
	min-height: inherit;
	min-width: inherit;

	&:after {
		@include svg(auto, 80%, '/images/components/preloader/.variant/base/preloader.svg', null, $preloader--color);
	}
}

.preloader:not(.preloader--is-flow-item) {
	position: relative;
}

.feature-no-svg .preloader:after {
	background-image: url('/images/components/preloader/.variant/base/preloader.gif') !important;
}
