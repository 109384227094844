$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок

button {
	border: 0;

	&:focus {
		outline: 0;
	}
}

%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: $btn--color;
	border: 0;
	background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease;
	box-shadow: 0px 4px 16px rgba(249, 178, 52, 0.2), 0px 6px 20px rgba(249, 178, 52, 0.14);
	border-radius: 20px 0px;
	font-size: 14px;
	line-height: 16px;
	@include font(700, 'Ubuntu');
	padding: 12px 30px;

	&:hover {
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
		box-shadow: none;
		text-decoration: none;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		background-color: $btn--bg-active;
		color: $btn--color-active;
	}

	&:visited {
		box-shadow: none;
		background-color: transparent;
		border: 2px solid $btn--color-visited;
		color: $btn--color-visited;
		opacity: .9;
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	// padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	color: $btn--color;
	background-color: $btn--bg;
	opacity: 0.7;
	cursor: default;

	&:hover {
		color: $btn--color;
		background-color: $btn--bg;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
