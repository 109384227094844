.b-search-result {
	margin-top: 1.6em;

	&__item {
		padding-top: $grid-gutter-width;
		@include rem(border-top-width, 1px);
		border-top-style: solid;

		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
	}

	&__title {
		@include font-size(18px);
	}

	&__link {
		font-weight: 700;
		color: inherit;
		text-decoration: none;
		color: $color-pacific-blue;

		&:hover {
			color: $color-regal-blue;
			background-color: transparent;
		}

		&:active {
			color: $color-supernova;
		}
	}

	&__loading-trigger {
		width: 100%;

		&.is-finidhed {
			display: none;
		}

		&.is-loading {
			height: 40px;
		}
	}
}
