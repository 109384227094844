@charset "utf-8";
// Переменные
//
// Styleguide 2

// Путь к папке
$imagesFolder: 'static';

//Параметры сайта
$debug: false;
$responsive: true;       // адаптивность да/нет
$site-width: 1580px;       // средняя ширина
$site-min-width: 320px;     // минимальная ширина страницы
// $site-max-width: $site-width;     // максимальная ширина
$site-max-width: 1920px;
$breakpoint-xs: 510px;
$breakpoint-sm: 812px;
$breakpoint-md: 1054px;
$breakpoint-lg: 1270px;
$breakpoint-xl: $breakpoint-lg + 1px;

// Параметры брекпоинтов сетки
$breakpoint-tiny: 320px !default;
$breakpoint-small: $breakpoint-xs;
$breakpoint-mobile: $breakpoint-sm;
$breakpoint-tablet: $breakpoint-md;
$breakpoint-desktop: $breakpoint-lg;
$breakpoint-extra-desktop: $breakpoint-xl;

//параметры сетки
$grid-columns: 12;
$grid-gutter-width: 16px;
$grid-legacy-support: true;
$input-height: 40px;
$input-padding: 0 0.3em;
$textarea-padding: 0 0.3em;
$default-font-size: 16;      // number of pixels, without units - default browser font size (16)
$base-font-size: $default-font-size * 1px;      // размер текста по умолчанию для текста
$base-line-height: (21 / $default-font-size);   // межстрочное расстояние

//Размеры областей страницы
$left-col-width: 320px;
$right-col-width: 320px;
$center-col-width: $site-width - $left-col-width - $right-col-width;
$cursorProperty: default, crosshair, help, move, pointer, progress, text, wait, n-resize, ne-resize, e-resize, se-resize, s-resize, sw-resize, w-resize, nw-resize;

// Шрифт по умолчанию для прогрессивной загрузки
$default-font-family: sans-serif;
// Кастомный шрифт, используемый на проекте
$custom-font-family: Ubuntu;
