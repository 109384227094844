.js-customScroll {
	max-height: 300px; // частный случай
	overflow: hidden;

	.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
		// os-scrollbar
		// os-scrollbar-vertical
		width: 8px;
		height: 100%;
	}

	.os-scrollbar-track {
		// подложка
		border: 1px solid rgba(0, 159, 227, 0.8) !important;
		// +вариант без бордера с фоном
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: #33B2E9;
		width: 5px;

		cursor: grab;
	}
}


.js-buttons-panel-list,
.js-menu-scroll,
.js-mobile-menu-panel {
	overflow: hidden;

	.os-scrollbar-vertical {
		position: absolute;
		top: 50% !important;
		transform: translateY(-50%);
		right: 41px;
		height: calc(100% - 10%);
	}

	.os-scrollbar-track {
		// подложка
		border: 1px solid rgba(0, 159, 227, 0.8) !important;
		// +вариант без бордера с фоном
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: #33B2E9;
		left: -2px;
		width: 5px;
		border-radius: 10px;

		cursor: grab;
	}
}

.js-mobile-menu-panel {
	.os-scrollbar-vertical {
		right: 5px
	}
}

@include breakpoint(md) {
	.js-menu-scroll {
		.os-scrollbar-vertical {
			right: 22px;
			height: calc(100% - 10px);
		}
	}
}