$useful-links--btn-bg: $color-ironside-gray;
$useful-links--btn-color: $color-white;
$useful-links--bg: $color-eallery;
$useful-links--link-color: $color-black;
$useful-links--link-color-hover: $color-tapa-gray;

@if ($contrast) {
	$useful-links--btn-bg: $color-light-gray;
	$useful-links--btn-color: $base-text-color;
	$useful-links--bg: $color-light-gray;
	$useful-links--link-color: $color-ironside-gray;
	$useful-links--link-color-hover: $color-mine-shaft;
}

.b-useful-links {
	@include rem(margin-top, $grid-gutter-width);
	@include rem(padding, 0 $grid-gutter-width 0);

	&__link {
		@extend %disable-visited;
		display: block;
		position: relative;
		@include font-size(18px);
		@include rem(padding-top, 0);
		@include rem(padding-bottom, 0);
		text-decoration: none;
		@include font(400);
		color: $color-regal-blue;

		&:hover {
			color: $color-pacific-blue;
			text-decoration: underline;
		}
	}
}
