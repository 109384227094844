.graphic {
	margin-bottom: 48px;

	&__img {
		@extend %collapse--top;

		#colorbox & {
			width: 100%;
		}
	}

	figcaption {
		font-size: 16px;
		line-height: 19px;
		@include font(500, 'Ubuntu');
		color: $color-ship-grey;
		margin-bottom: 32px;
		max-width: 380px;
		width: 100%;

		.caption__units {
			font-weight: normal;
			color: $color-regal-blue;
			display: block;
			margin-top: 10px;
		}
	}

	&--scheme {
		figcaption {
			font-size: 20px;
			@include font(700, 'Ubuntu');
			text-transform: uppercase;
			line-height: 26px;
			color: #009FE3;

			.caption__units {
				text-transform: none;
			}
		}
	}

	.graphic__tools {
		@extend %clearfix;
		@extend %collapse--top;
		position: relative;
		border-top: 1px solid;

		.button + .button {
			margin-right: 1px;
		}
		
	}
	
	.year,
	.value,
	.circ,
	.horizontalAxis,
	.verticalAxis,
	.legend,
	.grid,
	.axis-content,
	.rect,
	.rect-down,
	.rect-left,
	.rect-right {
		opacity: 0;
	}
	.rect {
		transition: all 0.7s;
		transform: translateX(-50px);
	}

	.circ {
		transition: all 0.7s;
		transform: rotate(180deg);
	}
}
