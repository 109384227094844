blockquote {
	position: relative;
	padding: 40px 16px 30px 29px;
	display: flex;
	margin-bottom: 47px;

	.text {
		position: relative;
		padding-right: 10px;

		&:before {
			// content: "\"";
			position: absolute;
			font-size: 4em;
			top: -0.3em;
			left: -50px;
			color: $color-navy-blue;
		}
	}

	.person {
		margin-top: 30px;

		&__name {
			display: block;
			font-size: 16px;
			color: $color-regal-blue;
			@include font(700, 'Ubuntu');
			text-transform: uppercase;
			margin-bottom: 2px;
		}

		&__position {
			display: block;
			@include font(400, 'Ubuntu');
			color: $color-pacific-blue;
		}

		&__company {
			@include font(400, 'Ubuntu');
			display: block;
			color: $color-pacific-blue;
		}
	}
}

.quote--photo {
	display: flex;
	padding: 0;
	align-items: flex-start;

	.quote__content {
		border-top: 0;
		border-left: 0;
		// border: 3px solid $color-pacific-blue;
		border-radius: 53px 0;
		position: relative;

		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	right: -53px;
		// 	top: 30px;
		// 	width: 43.37px;
		// 	height: 21.19px;
		// 	border-radius: 50% 50% 0 0 / 100% 100% 0 0;  /* угол состоит из половины ширины (50% от 10em = 10em : 2 = 5em) и всей высоты (100% от 5em = 5em) */
		// 	background: $color-pacific-blue;
		// 	transform: rotate(-90deg);
		// }

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	right: -45px;
		// 	top: 74px;
		// 	width: 43.37px;
		// 	height: 21.19px;
		// 	border-radius: 50% 50% 0 0 / 100% 100% 0 0;  /* угол состоит из половины ширины (50% от 10em = 10em : 2 = 5em) и всей высоты (100% от 5em = 5em) */
		// 	background: #83D0F5;
		// 	transform: rotate(90deg);
		// }
	}

	.quote__icons {
		position: absolute;
		top: 0px;
		left: 19px;
		width: 50px;
		height: 33px;
		@include svg(100%, 100%, "/images/icons/i-quote.svg");


		&::before {
			content: '';
			position: absolute;
			top: -2px;
			left: 1px;
			width: 17px;
			height: 15px;
			background-color: $color-white;
			z-index: 1;
		}

		&::after {
			content: '';
			position: absolute;
			top: -2px;
			left: 29px;
			width: 18px;
			height: 6px;
			background-color: $color-white;
			z-index: 1;
		}
	}

	.quote__right {
		margin-left: 25px;
		// margin-top: 11%;
	}

	.quote__photo {
		flex-shrink: 0;
		width: 208px;
		position: relative;
		border-radius: 0 0 46px 0;
		line-height: 0;
		padding-left: 10px;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			border: 3px solid #009FE3;
			border-top: 0;
			border-left: 0;
			width: 100%;
			max-height: 130px;
			height: 100%;
			border-radius: 0 0 43px 0;
		}

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	top: -10px;
		// 	left: -10px;
		// 	border-radius: 100%;
		// 	border: 1px solid #009FE3;
		// 	width: calc(100% + 20px);
		// 	height: calc(100% + 20px);
		// }


		img {
			display: block;
			border-radius: 43px 0 43px 0;
			-webkit-border-radius: 43px 0 43px 0;
			-moz-border-radius: 43px 0 43px 0;
			-ms-border-radius: 43px 0 43px 0;
			-o-border-radius: 43px 0 43px 0;
}
	}

	.quote__content {
		position: relative;
		padding: 40px 16px 30px 29px;
	}

	@include breakpoint(sm) {
		
	}
}

@include breakpoint(sm) {
	// blockquote {
	// 	padding: 24px 16px 24px 40px;

	// 	.text {
	// 		&:before {
	// 			left: -28px;
	// 		}
	// 	}
	// }

	.quote--photo {
		display: block;
		padding: 24px 0 24px 0px;

		.quote__photo {
			margin: 28px auto 25px;
		}

		.quote__icons {
			top: 24px;
		}

		.quote__content {
			position: relative;

			&::before,
			&::after {
				display: none;
			}
		}
	}
}
