.l-layout-wrapper--1 {
	.browser-ie & {
		// height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		@include rem(padding, 41px 0);

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__title {
			margin: 0 auto;
			flex: 1 0 auto;
			text-align: center;
			margin-left: 20px;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
			// flex-grow: 1;
		}
	}

	.l-header-top-nav {
		display: flex;
		flex-direction: column;
		position: fixed;
		left: 10px;
		top: 10px;
		width: 78px;
		height: calc(100% - 20px);
		// display: none;
		// background: $color-pacific-blue;
		// box-shadow: 0px 2px 2px rgba(0, 159, 227, 0.24), 0px 0px 2px rgba(0, 159, 227, 0.12);
		// border-radius: 0 40px 40px 0;
		z-index: 10;

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
			height: 50%;
		}

		&__buttons-panel {
			height: 50%;
		}

		// @media (min-width: 1940px) {
		// 	left: 50vw;
		// 	transform: translateX(-1080%);
		// }
	}

	.l-page {
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 218px); // минус ширина page-nav'a
		}
	}

	.l-page-nav {
		display: none;
	}

	.l-header-top-nav {
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%);
			margin-top: -1px;
			height: 2px;
			background-color: rgba(255, 255, 255, 0.2);
			width: calc(100% - 28px);
			z-index: 11;
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			@include rem(margin-left, 16px);
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__buttons-panel {
			display: none;
		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		display: flex;
		@include rem(padding-top, 21px);
		@include rem(padding-bottom, 19px);
		padding-left: 85px;
		padding-right: 62px;

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}

		// &__nav {
			// text-align: right;
		// }
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-nav {
				width: 49px;
			}

			.l-header-top-line {
				&__logo {
					display: block;
				}

				&__title {
					margin-left: 0;
				}
			}

			.l-page {
				.browser-ie & {
					display: block;
				}
			}


			.l-header-top-nav__logo {
				display: none;
			}

			.l-footer {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-header {
				background-color: $color-pacific-blue;
			}

			.l-page {
				flex-direction: column;
				&__nav-controls {
					display: none;
				}
			}

			.l-header-top-line {
				padding: 18px 0;
				&__title {
					margin: 0;
					text-align: left;
				}
			}

			.l-page-nav {
				display: block;
				height: auto !important;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-nav {
				display: none;
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
			}

			.l-buttons-area {
				&__search,
				&__lang,
				&__pdf {
					display: none;
				}

				&__buttons-panel {
					display: block;
					margin-right: 12px;
				}

				&__item + .l-buttons-area__item {
					@include rem(margin-left, 7px);
				}
			}

			.l-footer {
				&__nav {
					display: none;
				}
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				display: none;
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-footer {
				padding-top: 16px;
				padding-bottom: 9px;
				&__copyright {
					@include rem(margin-bottom, 5px);
				}

				&__inner {
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}
.video-wrapper {
	&--aside {
	  margin-top: 101%;
	  margin-left: 4px;
	}
	&--mtop {
	  margin-top: 120%;
	  margin-left: 4px;
	}
	&--nomtop {
	  margin-top: 80%;
	}
  
	@include breakpoint(sm) {
	  .frame-adaptive {
		height: 200px;
	  }
  
	  &--aside {
		margin-top: 0;
		margin-left: 0;
  
		.frame-adaptive {
		  height: 200px;
		}
	  }
  
	  &--mtop {
		margin-top: 0;
		margin-left: 0;
	  }
  
	  &--nomtop {
		margin-top: 0;
		margin-left: 0;
	  }
	}
	@include breakpoint(md) {
	  .frame-adaptive {
		height: 300px;
	  }
  
	  &--aside {
		.frame-adaptive {
		  height: 250px;
		}
	  }
	}
  }