$buttons-panel--trigger-icon-size: 25px;
$buttons-panel--trigger-text-font-size: 15px;
$buttons-panel--trigger-color: $color-ironside-gray;
$buttons-panel--trigger-color-hover: $color-black;
$buttons-panel--trigger-color-active: orange;

$buttons-panel--list-bg-color: $color-ironside-gray;

$buttons-panel--link-icon-font-size: 25px;
$buttons-panel--link-font-size: 13px;
$buttons-panel--link-color: $color-pacific-blue;
$buttons-panel--link-color-hover: $color-pacific-blue;
$buttons-panel--link-color-active: $color-supernova;
$buttons-panel--link-bg-color: $color-eallery;
$buttons-panel--link-bg-color-hover: #444444;
$buttons-panel--link-bg-color-active: #444444;

$buttons-panel--counter-width: 12px;
$buttons-panel--counter-height: 12px;
$buttons-panel--counter-color: $color-pacific-blue;
$buttons-panel--counter-color-hover: $color-pacific-blue;
$buttons-panel--counter-color-active: $color-supernova;
$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
$buttons-panel--counter-border-color: $buttons-panel--counter-color;
$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;

$buttons-panel--sublist-bg-color: $color-ironside-gray;
$buttons-panel--sublist-icon-font-size: 13px;
$buttons-panel--sublist-link-width: 30px;
$buttons-panel--sublist-link-height: 30px;
$buttons-panel--sublist-link-icon-color: $color-pacific-blue;
$buttons-panel--sublist-link-icon-color-hover: $color-supernova;
$buttons-panel--sublist-link-icon-color-active: $color-supernova;
$buttons-panel--sublist-icon-bg-color: transparent;
$buttons-panel--sublist-icon-bg-color-hover: transparent;
$buttons-panel--sublist-icon-bg-color-active: transparent;

$buttons-panel--compare-link-font-size: 14px;

$buttons-panel--tooltips-color: $color-white;
$buttons-panel--tooltips-bg-color: $color-ironside-gray;

$buttons-panel--title-font-size: 17px;
$buttons-panel--title-color: $base-text-color;

@if ($contrast) {
	$buttons-panel--trigger-color: $color-ironside-gray;
	$buttons-panel--trigger-color-hover: $color-black;
	$buttons-panel--trigger-color-active: orange;

	$buttons-panel--list-bg-color: $color-eallery;

	$buttons-panel--link-color: $base-text-color;
	$buttons-panel--link-color-hover: $color-white;
	$buttons-panel--link-color-active: orange;
	$buttons-panel--link-bg-color: $buttons-panel--list-bg-color;
	$buttons-panel--link-bg-color-hover: #444444;
	$buttons-panel--link-bg-color-active: #444444;

	$buttons-panel--counter-color: $buttons-panel--link-color;
	$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
	$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
	$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
	$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
	$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
	$buttons-panel--counter-border-color: $buttons-panel--counter-color;
	$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
	$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;

	$buttons-panel--sublist-bg: $color-ironside-gray;

	$buttons-panel--sublist-bg-color: $color-ironside-gray;
	$buttons-panel--sublist-link-icon-color: $color-white;
	$buttons-panel--sublist-link-icon-color-hover: $color-gray;
	$buttons-panel--sublist-link-icon-color-active: orange;
	$buttons-panel--sublist-icon-bg-color: transparent;
	$buttons-panel--sublist-icon-bg-color-hover: transparent;
	$buttons-panel--sublist-icon-bg-color-active: transparent;

	$buttons-panel--tooltips-bg-color: $color-ironside-gray;
	$buttons-panel--tooltips-color: $color-white;

	$buttons-panel--title-color: $base-text-color;
}

%counter {
	display: block;
	@include font-size(14px);
	@include font(400);
	text-align: center;
	@include rem(width, $buttons-panel--counter-width);
	@include rem(height, $buttons-panel--counter-height);
	color: $buttons-panel--counter-color;
	@extend .user-select--no;
}

.b-buttons-panel__link {
	position: relative;
	display: block;
	text-decoration: none;
	line-height: 1.2;
	background-color: $buttons-panel--link-bg-color;
	@include font-size($buttons-panel--link-font-size);
	color: $buttons-panel--link-color;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--link-icon-font-size);
	}

	&:visited {
		color: $buttons-panel--link-color;
	}

	&:hover,
	&:focus,
	&:visited:hover,
	&:visited:focus {
		background-color: $buttons-panel--link-bg-color-hover;
		color: $buttons-panel--link-color-hover;
	}

	&:active,
	&.is-expand,
	&:visited:active {
		background-color: $buttons-panel--link-bg-color-active;
		color: $buttons-panel--link-color-active;
	}

	&.is-visible {
		display: block;
	}

	//Мой отчет
	&--myreport {
		@include webfont-icon($webfont-icon--i-my-report);

		.b-buttons-panel__counter {
			@extend %counter;
		}

		&:hover,
		&:focus {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	&--add,
	&--del {
		display: none;

		&:hover,
		&:focus {
			&:after {
				color: $buttons-panel--counter-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			&:after {
				color: $buttons-panel--counter-color-active;
				background-color: $buttons-panel--counter-bg-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	//Добавить в мой отчет
	&--add {
		@include webfont-icon($webfont-icon--i-add-report);
	}

	//Удалить из моего отчета
	&--del {
		@include webfont-icon($webfont-icon--i-del);

		&::before {
			font-size: 33px;
		}
	}

	//Печать страницы
	&--print {
		@include webfont-icon($webfont-icon--i-print);
	}

	//Скачать PDF
	&--pdf {
		@include webfont-icon($webfont-icon--i-download-pdf);

		&::before {
			font-size: 25px;
		}
	}

	//Поделится в соц. сетях или по e-mail
	&--share {
		@include webfont-icon($webfont-icon--i-share);
	}

	//История просмотренных страниц
	&--history {
		@include webfont-icon($webfont-icon--i-history);
	}

	//Обратня связь
	&--feedback {
		@include webfont-icon($webfont-icon--i-feedback);
	}

	//Центр загрузки
	&--download {
		@include webfont-icon($webfont-icon--i-dc);
	}

	//Интерактивный анализ
	&--analysis {
		@include webfont-icon($webfont-icon--i-int-analysis);
		&::before {
			font-size: 22px;
		}
	}

	//Сравнить с прошлым отчетом
	&--compare {
		@include webfont-icon($webfont-icon--books);
	}

	//Смотреть предыдущие отчеты
	&--see {
		@include webfont-icon($webfont-icon--eye);
	}

	//предыдущие отчеты
	&--previous-reports {
		@include webfont-icon($webfont-icon--books);
	}

	//Карта сайта
	&--site-map {
		@include webfont-icon($webfont-icon--i-sitemap);
	}

	//глосарий
	&--glossary {
		@include webfont-icon($webfont-icon--library);
	}

	//архив отчетов
	&--archive-reports {
		@include webfont-icon($webfont-icon--box-add);
	}

	//disclaimer
	&--disclaimer {
		@include webfont-icon($webfont-icon--file-text);
	}

	&--int-map {
		@include webfont-icon($webfont-icon--i-int-map);
	}
}

//Стили для sublist (share, social, compare, etc.)
.b-buttons-panel__sublist {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 43px;
	height: 37px;
	margin: -38px 0 0 -18px;
    padding: 0 !important;
	background-color: $color-white;

	@include breakpoint(sm) {
		margin-left: -27px;
		width: 50px;
	}

	&--compare {
		top: 35%;
		height: 70px;

		@include breakpoint(sm) {
			margin-left: -22px;
		}
	}
}

.b-buttons-panel__sublist-inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
}

.b-buttons-panel__sublist-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $buttons-panel--sublist-link-icon-color;
	width: 50%;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--sublist-icon-font-size);
	}

	&:hover,
	&:visited:hover {
		color: $buttons-panel--sublist-link-icon-color-hover;
	}

	&:active,
	&:visited:active {
		color: $buttons-panel--sublist-link-icon-color-active;
	}

	&--vkontakte {
		@include webfont-icon($webfont-icon--i-vk-tools);

		&:before {
			@include font-size(10px);
		}
	}

	&--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	&--twitter {
		@include webfont-icon($webfont-icon--i-tw-tools);
	}

	&--facebook {
		@include webfont-icon($webfont-icon--i-fb-tools);

		&:before {
			@include font-size(15px);
		}
	}

	&--youtube {
		@include webfont-icon($webfont-icon--youtube);
	}

	&--googleplus {
		@include webfont-icon($webfont-icon--google-plus);

		&:before {
			@include font-size(13px);
		}
	}

	&--email {
		@include webfont-icon($webfont-icon--i-mail);

		&:before {
			@include font-size(11px);
		}
	}
}

.b-buttons-panel__sublist-link--compare {
	white-space: nowrap;
	float: none;
	width: auto;
	height: auto;
	@include rem(padding, 2px 0px);
	@include font-size($buttons-panel--compare-link-font-size);
}

.tooltipster-sidetip.tooltipster-buttons-panel {
	.tooltipster-box {
		border: none;
		background: $buttons-panel--tooltips-bg-color;
		color: $buttons-panel--tooltips-color;
		@include font-size(15px);
		@include font(400);
		@include rem(border-radius, 5px);
	}

	.tooltipster-content {
		color: $buttons-panel--tooltips-color;
	}


	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $buttons-panel--tooltips-bg-color;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
