$accordion--controls-height: 40px;
$accordion--button-bg: $color-celeste;
$accordion--button-bg-hover: $color-ironside-gray;
$accordion--button-bg-active: $color-mine-shaft;
$accordion--button-text-color: $base-text-color;
$accordion--button-text-color-hover: $color-pacific-blue;
$accordion--button-text-color-active: $base-text-color;
$accordion--article-bg: transparent;
$accordion--article-close-hover: orange;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 18px;

@if ($contrast) {
	$accordion--button-bg: $color-celeste;
	$accordion--button-bg-hover: $color-ironside-gray;
	$accordion--button-bg-active: $color-mine-shaft;
	$accordion--button-text-color: $base-text-color;
	$accordion--button-text-color-hover: $color-white;
	$accordion--button-text-color-active: $color-white;
	$accordion--article-bg: $color-eallery;
	$accordion--article-close-hover: orange;
	$accordion--control-icon-bg: $color-navy-blue;
	$accordion--control-icon-color: $color-white;
	$accordion--control-item-bg: $color-celeste;
	$accordion--control-item-bg-hover: $color-ironside-gray;
	$accordion--control-item-bg-active: $color-mine-shaft;
	$accordion--control-text-color: $base-text-color;
	$accordion--control-text-color-hover: $color-white;
	$accordion--control-text-color-active: $color-white;
	$accordion--article-close-bg: $base-text-color;
	$accordion--article-close-bg-hover: $color-navy-blue;
	$accordion--article-close-bg-active: $color-prussian-blue;
}

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	@include rem(width, 20px);
	@include rem(height, 2px);
}

.b-accordion {
	margin-bottom: 52px;
	&__section {
		@include rem(margin-bottom, 3px);
		border-radius: 20px 0;
		// overflow: hidden;
	}

	&__button {
		display: block;
		position: relative;
		padding: 17px 49px 19px 73px;
		text-decoration: none;
		color: $accordion--button-text-color;
		@include font-size($accordion--button-font-size);
		cursor: pointer;
		transition: all .2s ease-in-out;
		text-transform: uppercase;
		@include font(400);
		@include webfont-icon($webfont-icon--i-arrow, after);
		background-color: #EAF2FC;
		border: 2px solid transparent;
		border-bottom: 0;
		border-radius: 30px 0;
		@include breakpoint(xs) {
			@include font-size(14px);
		}
		&:visited {
			color: $accordion--button-text-color;
		}

		&:hover {
			color: $accordion--button-text-color-hover;
			text-decoration: none;

			&:visited {
				color: $accordion--button-text-color-hover;
			}
		}

		&.is-expand,
		&:active {
			color: $accordion--button-text-color-active;

			&:visited {
				color: $accordion--button-text-color-active;
			}
		}

		&.is-expand {
			background-color: transparent;
			border-color: $color-pacific-blue;
			border-radius: 30px 0 0;
			&:after {
				transform: rotate(90deg);
			}
		}

		&:after {
			font-size: 20px;
			color: $color-pacific-blue;
			position: absolute;
			top: 17px;
			right: 27px;
			transition: transform .3s ease;
		}

		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 100%;
			background-color: $color-pacific-blue;
			top: 23px;
			left: 30px;
			margin: auto;
			transition: transform 0.2s ease;
		}
	}

	&__article {
		position: relative;
		margin-top: 0;
		padding: 4px 38px 7px 31px;
		background-color: $accordion--article-bg;
		display: none;
		border-radius: 0 0 20px;
		border: 2px solid $color-pacific-blue;
		border-top: 0;

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__article-close {
		position: absolute;
		display: block;
		@include rem(width, 32px);
		@include rem(height, 32px);
		@include rem(right, 10px);
		background: transparent;
		bottom: 0px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 3px);
			@include rem(height, 15px);
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			@include rem(right, 19px);
			transform: rotate(-45deg);

		}

		&:after {
			@include rem(right, 10px);
			transform: rotate(45deg);
		}

		&:hover,
		&:focus {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-hover;
			}

			&:after {
				background-color: $accordion--article-close-bg-hover;
			}
		}

		&:active {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-active;
			}

			&:after {
				background-color: $accordion--article-close-bg-active;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__control {
		@include rem(padding-top, 10px);
	}

	&__control-item {
		display: inline-block;
		@include rem(height, $accordion--controls-height);
		@include rem(line-height, $accordion--controls-height);
		@include rem(margin-bottom, 10px);
		background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;

		&:first-child {
			@include rem(margin-right, 10px);
		}

		&:hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		@include rem(width, $accordion--controls-height);
		float: left;
		background-color: $accordion--control-icon-bg;
	}

	&__control-text {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}

	.aside--right {
		padding-left: 20px;

		@media (max-width: 768px) {
			padding-left: 0;
		}
	}

	@include breakpoint(xs) {
		&__button {
			padding: 17px 29px 19px 24px;

			&::before {
				left: 8px;
			}

			&::after {
				right: 10px;
			}
		}
	}
}
