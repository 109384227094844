$slider-nav--color: $color-white;
$slider-nav--color-hover: $color-celeste;
$slider-nav--color-active: orange;
$slider-nav--bg: transparent;
$slider-nav--bg-hover: transparent;
$slider-nav--bg-active: transparent;
$slider-pagination--color: $color-white;
$slider-pagination--color-hover: $color-white;
$slider-pagination--color-active: $color-white;
$slider-pagination--bg: $color-ironside-gray;
$slider-pagination--bg-hover: $color-black;
$slider-pagination--bg-active: orange;

.browser-ie {
	.body--main-page .b-slider-wrapper__image-wrapper img {
		transform: none;
		top: 0;
	}

	.body--main-page .b-slider-item:not(.b-slider-item--5) {
		overflow: visible;

		&:before {
			height: calc(100% + 1px)!important;
		}
	}

	.body--main-page .l-slider-item__container,
	.body--main-page .l-slider-item__inner,
	.body--main-page .b-slider-item,
	.body--main-page .l-page__content,
	.body--main-page .l-page__wrapper,
	.body--main-page .b-slider-wrapper  {
		height: 100vh!important;
		min-height: 100vh!important;
	}
}

.body--main-page {
	overflow: hidden;
	.l-page__content {
		width: 100%;	
	}

	.l-layout-wrapper--1 {
		padding: 0;
		.content-area {
			padding: 0;
		}

		.l-header {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 3;
		}

		.l-header-top-line {
			transition: transform .3s ease, opacity .3s ease;
			transform: translateY(-100%);
			opacity: 0;
			position: relative;
			&.show{
				transform: translateY(0%);
				opacity: 1;
			}
		}

		.l-header-top-nav {
			background-color: transparent;
			transition: transform .3s ease, opacity .3s ease;
			transform: translateX(-100%);
			opacity: 0;

			&.show{
				transform: translateX(0%);
				opacity: 1;
			}
		}

		.l-page {
			padding: 0;

			&__wrapper {
				width: 100%;
			}
		}

		.l-footer {
			display: none;
			transition: transform .3s ease, opacity .3s ease;
			transform: translateY(100%);
			opacity: 0;
			width: 100%;
			position: absolute;
			left: 50%;
			bottom: 0;

			&.show{
				transition:none;
				display: block;
				transform: translate(-50%, 0%);
				z-index: 1;
			}
		}

		.b-nav--top.b-nav--horizontal .b-nav__burger,
		.b-buttons-panel--in-column .b-buttons-panel__trigger--tools {
			background: rgba(0, 159, 227, .6);

			&.is-expand {
				background: rgba(0, 159, 227, .6) !important;
			}
		}
	}

	.b-nav--top.b-nav--horizontal .b-nav__wrapper {
		top: 0;
		left: 0;
		width: 100vw;
	}

	.b-buttons-panel--in-column.b-buttons-panel--tools .b-buttons-panel__list {
		top: -7px;
		left: 0;
	}

	.l-layout-wrapper__page {
		max-width: 100%;
		width: 100%;
		flex-basis: auto;
	}

	.b-slider-wrapper--multi {
		position: relative;
	}

	.js-object-fit {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.b-slider-item {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		// TODO можно удалить если надо
		opacity: 1 !important;

		.browser-ie & {
			overflow: visible !important;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: .8;
		}

		&--0 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			// TODO надо скрыть когда ушли с первого слайда
			overflow: visible;
			background-color: #133A60;

			&::before {
				background: linear-gradient(0deg, #133A60 70%, rgba(19, 58, 96, 0) 100%);
				bottom: calc(-100% + 64vh);
				z-index: 2;
				top: auto;
				max-height: 50vh;//509px;
				opacity: 1;
			}
		}

		&--1 {
			&::before {
				background: linear-gradient(0deg, #464172 3.48%, #133A60 50%, #133A60 70%);
			}
		}

		&--2 {
			&::before {
				background: linear-gradient(0deg, #8B5C97 3.82%, #464172 66.21%);
			}
		}

		&--3 {
			&::before {
				background: linear-gradient(0deg, #EEA562 1.48%, #8B5C97 69.16%);
			}
		}

		&--4 {
			&::before {
				background: linear-gradient(0deg, #48B983 30.11%, #EEA562 97.8%);
			}
		}

		&--5 {
			overflow: visible;
			&::before {
				background: linear-gradient(0deg, #62D0FF 61.68%, #62D0FF -0.7%, #48B983 88.02%);
				height: calc(100% + 60vh);//382px);
			}

			.js-fake-image {
				width: 100%;
			}
		}

		&--6 {
			// TODO можно удалить если надо
			top: 0 !important;
			// background-color: $color-white;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 18%, rgba(255,255,255,0.51) 21%, rgba(255,255,255,1) 54%);

			// &::before {
				// background:  linear-gradient(0deg, rgba(255, 255, 255, 0) 0.44%, #62D0FF 61.68%);//linear-gradient(0deg, rgba(255, 255, 255, 0) 0.44%, rgba(98, 208, 255, .7) 61.68%);//linear-gradient(0deg, rgba(255, 255, 255, 0) 0.44%, #62D0FF 61.68%);
				// height: 382px;
				// top: -100px;
			// }

			.b-slider-item__up {
				&::before {
					color: #009FE3;
				}
			}
		}


		&__logo {
			width: 246px;
			margin: 0 auto 10vh;
		}

		&__title {
			font-size: 82px;
			line-height: 86px;
			color: $color-white;
			@include font(700, 'Ubuntu');
			width: 100%;
			max-width: 1178px;
			margin: 0 auto 10vh;
			text-align: center;
			position: relative;
			opacity:0;
		}

		&__link {
			position: relative;
			text-decoration: underline;
			font-size: 24px;
			line-height: 27px;
			color: $color-white;
			@include font(700, 'Ubuntu');
			@include webfont-icon($webfont-icon--i-arrow, 'after');
			margin: 0 auto;
			text-align: center;
			opacity:0;
			text-decoration: none;
			&:hover,
			&:active {
					color: #133A60;
				}
			span {
				text-decoration: underline;
			}

			&::after {
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				right: -38px;
				top: 50%;
				transform: translateY(-50%) rotate(180deg);
				font-size: 18px;
				text-decoration: none;
			}
		}

		&__image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				// height: 100%;
				transform: translateY(-50%);

				.browser-ie & {
					transform: none;
					top: 0;
				}
			}

			.browser-ie & {
				img {
					transform: none;
					top: 0;
				}
			}
		}

		&__info {
			position: relative;
			z-index: 2;
			text-align: center;
		}

		&__next {
			display: inline-block;
			margin: 0 auto;
			width: 40px;
			height: 82px;
			// @include svg(auto, 100%, '/images/main/i-mouse.svg', null, $color-white);
			// background-image: url('/images/main/i-mouse.svg');
			background-size: contain;
			position: relative;
			opacity:0;
			text-decoration: none;

			.help-mouse-down {
				border: 2px solid #fff;
				border-radius: 17px;
				height: 50px;
				margin: 15px auto 10px;
				position: relative;
				width: 30px;
				display: flex;
				justify-content: center;
			}

			//Mouse Scroll
			.help-mouse-wheel {
				position: relative;
				// left: 50%;
				transition: opacity 0.5s;
				&:before,
				&:after {
					content: '';
					background: #fff;
					border-radius: 50%;
					margin-left: -2px;
					height: 4px;
					position: absolute;
					top: 5px;
					transition: top 0.5s, height 0.5s, opacity 0.5s;
					width: 4px;
				}
				&:before {
					animation: scroll 1.5s 0.75s infinite ease-in-out;
				}
				&:after {
					animation: scroll 1.5s infinite ease-in-out;
				}
			}
				
			.help-down {
				animation: arrow-down 0.6s infinite alternate ease-in-out;
				border-bottom: 2px solid #cdcdcd;
				border-right: 2px solid #cdcdcd;
				display: block;
				height: 5px;
				margin: 0 auto;
				transform: rotate(45deg);
				transition: opacity 0.5s;
				width: 5px;
			}
		}

		&__next-wrapper {
			margin-top: 11vh;
		}

		&__info-content {
			position: relative;
			z-index: 2;
			display: flex;
			justify-content: space-between;
			padding-top: 19vh;
			&--2 {
				padding-top: 15vh;
			}

			&--4 {
				padding-top: 0;
				align-items: center;
			}

			&--5 {
				padding-top: 0;
				align-items: center;
				justify-content: flex-start;
			}
		}

		&__left {
			max-width: 439px;
			position: relative;
			padding-right: 42px;
			flex: 1 0 auto;
			padding-top: 10vh;

			&--2 {
				max-width: 498px;
				padding-top: 9vh;
			}

			&--2-en {
				padding-top: 5vh;
			}



			&--3-en {
				padding-top: 12vh;
			}

			&--4 {
				max-width: 544px;
				padding-top: 5vh;
			}

			&--5 {
				max-width: 702px;
				padding-top: 3vh;
			}
		}

		&__right {
			padding-left: 67px;
			position: relative;
			width: 100%;

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				&--5 {
					padding-left: 0;
				}
			}
		}

		&__title-slide {
			font-size: 55px;
			line-height: 57px;
			@include font(500, 'Ubuntu');
			color: $color-white;
			margin-bottom: 15px;

			&--right {
				@include font(700, 'Ubuntu');
				margin-bottom: 25px;
			}

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				font-size: 45px;
				line-height: 47px;
			}
		}

		&__text {
			width: 100%;
			max-width: 296px;
			font-size: 18px;
			line-height: 26px;

			&--full {
				max-width: none;
			}

			a {
				color: $color-white;
				@include font(700, 'Ubuntu');
				text-decoration: underline;
				&:hover,
				&:visited:hover {
					color: $link-hover-color;
					// text-decoration: underline;
				}

				&:active,
				&:visited:active {
					color: $link-active-color;
				}

				&:active,
				&:hover {
					outline: 0;
				}
			}

			.link--external {
				text-decoration: none;

				// &:hover {
				// 	&::before {
				// 		color: $link-hover-color;
				// 	}
				// }
			}
		}

		&__arrow {
			position: absolute;
			right: 0;
			top: 0;//-50%;
			width: 174px;
			height: 379px;

			&--4 {
				top: -23px;
			}
		}

		&__button {
			text-decoration: none !important;
			color: $color-white;
			font-size: 14px;
			line-height: 16px;
			@include font(700, 'Ubuntu');
			border-radius: 20px 0;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			background-color: #009FE3;
			display: inline-block;
			padding: 11px 50px;
			transition: all;

			&:hover {
				background-color: $color-supernova;
			}

			&:active {
				background-color: $color-supernova;
			}
		}

		&__button-wrapper {
			text-align: right;
			padding-right: 36px;
		}

		&__inset {
			background-color: rgba(15, 98, 161, .3);
			border-radius: 20px 0;
			padding: 31px 41px;
			// width: 100%;
			// max-width: 737px;
			margin-top: 34px;

			a {
				color: $color-white;
				@include font(700, 'Ubuntu');
				text-decoration: underline;
				&:hover,
				&:visited:hover {
					color: $link-hover-color;
					text-decoration: underline;
				}

				&:active,
				&:visited:active {
					color: $link-active-color;
				}

				&:active,
				&:hover {
					outline: 0;
				}
			}

			p {
				font-size: 20px;
				line-height: 28px;
				&:last-child {
					margin-bottom: 0;
				}


				@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
				only screen and(      -o-min-device-pixel-ratio: 5/4 ),
				only screen and( min-resolution: 120dpi ),
				only screen and( min-resolution: 1.25dppx ) {
					font-size: 18px;
					line-height: 26px;
				}
			}

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				margin-top: 14px;
			}
		}

		&__title-inset {
			font-size: 30px;
			line-height: 39px;
			@include font(700, 'Ubuntu');

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				font-size: 27px;
				line-height: 36px;
			}
		}

		&__inset-border {
			border: 2px solid $color-white;
			border-radius: 40px 0;
			display: flex;
			align-items: center;
			max-width: 599px;
			width: 100%;
			padding: 22px 34px;
			justify-content: space-between;
			margin-bottom: 34px;
			
			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				padding: 10px 10px;
				margin-bottom: 10px;
			}
		}

		&__indicator-title {
			font-size: 20px;
			line-height: 23px;
			margin-bottom: 25px;

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				font-size: 19px;
				line-height: 22px;
				margin-bottom: 10px;
			}
		}

		&__value {
			// font-size: 77px;
			// line-height: 77px;
			// color: transparent;
			// text-shadow: -1px 1px $color-white, 1px 1px $color-white, 1px -1px $color-white, -1px -1px $color-white;//

			img {
				width: 100%;
				max-width: 228px;
			}
		}

		&__indicator-text {
			font-size: 25px;
			line-height: 29px;
			@include font(700, 'Ubuntu');

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				font-size: 24px;
				line-height: 28px;
			}
		}

		&__left-inset {
			max-width: 165px;
			width: 100%;
			flex: 1 0 auto;

			img {
				width: 100%;
			}
		}

		&__right-inset {
			padding-left: 49px;
		}

		&__wrapper-last {
			display: flex;
			justify-content: space-between;
			margin: 0 3vw;
		}

		&__text-last {
			font-size: 24px;
			line-height: 34px;
			color: $color-denim;
			position: relative;
			width: 100%;
			max-width: 1003px;
			padding: 35px 0 0 48px;
			margin-bottom: 40px;
			margin-left: 3vw;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 200px;
				height: 144px;
				border-radius: 40px 0;
				border-top: 3px solid $color-pacific-blue;
				border-left: 3px solid $color-pacific-blue;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 200px;
				width: 100px;
				height: 3px;
				background: linear-gradient(90deg, #009FE3 0.11%, rgba(255, 255, 255, 0) 100%);
			}

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				margin-bottom: 10px;
				font-size: 22px;
				line-height: 32px;
			}
		}

		&__treatment-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-right: 3px solid #009FE3;
			border-bottom: 3px solid #009FE3;
			border-radius: 0 0 40px 0;
			overflow: hidden;
			margin-bottom: 55px;
		}

		&__qote-photo {
			line-height: 0;
			margin-right: -1px;
		}

		&__qote-text {
			font-size: 21px;
			line-height: 22px;
			@include font(500, 'Ubuntu');
			color: $color-pacific-blue;
			width: 100%;
			max-width: 170px;
		}

		&__indicator-last {
			background-image: url('/images/main/indicator-bg.png');
			border-radius: 20px 0;
			overflow: hidden;
			padding: 36px 36px 0;
		}

		&__indicator-item {
			margin-bottom: 20px;
		}

		&__icon-wrapper {
			width: 53px;
			height: 45px;
			margin-bottom: 10px;
		}

		&__indicator-text-last {
			display: block;
			position: relative;
			font-size: 21px;
			line-height: 25px;
			color: $color-denim;
			@include font(500, 'Ubuntu');
			@include webfont-icon($webfont-icon--i-arrow, 'after');

			&::after {
				position: absolute;
				top: 5px;
    			right: -5px;
				font-size: 11px;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}

			&:hover {
				text-decoration: none;
				span {
					text-decoration: underline;
				}
			}

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				font-size: 19px;
				line-height: 23px;
			}
		}

		&__content-sub {
			position: relative;
			line-height: 0;
			width: 100%;
		}

		&__text-sub {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			color: $color-white;
			font-size: 18px;
			line-height: 26px;
			text-align: center;
			padding: 9px 30px;

			a {
				color: $color-white;
				text-decoration: underline;
				@include font(700, 'Ubuntu');
			}
		}

		&__treatment,
		&__indicator-last {
			width: calc(100%/3);
		}

		&__treatment {
			max-width: 363px;
			flex: 1 0 auto;

			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				max-width: 335px;
			}
		}

		&__indicator-last {
			max-width: 310px;
			flex: 1 0 auto;
			@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
			only screen and(      -o-min-device-pixel-ratio: 5/4 ),
			only screen and( min-resolution: 120dpi ),
			only screen and( min-resolution: 1.25dppx ) {
				max-width: 290px;
			}
		}

		&__up-wrapper {
			text-align: center;
			display: none;
		}

		&__up {
			display: inline-block;
			@include webfont-icon($webfont-icon--i-arrow);
			transform: rotate(90deg);
			&::before {
				color: $color-white;
				font-size: 20px;
			}

			.b-slider-item--6 & {
				&::before {
					color: #009FE3;
				}
			}
		}

		.link--external {
			color: $color-white;
		}

		.quote--photo {
			.quote__photo {
				width: 208px;
				padding: 0;

				&::before {
					display: none;
				}
			}

			.quote__content {
				padding: 55px 16px 30px 33px;
			}

			.text {
				font-size: 20px;
				line-height: 28px;
			}
		}

		.person {
			&__name {
				font-size: 18px;
				line-height: 25px;
				color:$color-white;
				@include font(700, 'Ubuntu');
				padding-left: 0;
			}

			&__position {
				font-size: 18px;
				line-height: 25px;
				color:$color-white;
				@include font(400, 'Ubuntu');
			}
		}
	}

	.b-sub-slider-item {
		min-height: 100%;
	}

	.b-sub-slider-wrapper {
		width: calc(100%/3);
		border-radius: 50px 0 !important;
		overflow: hidden;
		position: relative;
		flex: 1 0 auto;
		max-width: 540px;

		&__text {
			background-image: url('/images/main/sub-slider-bg.png');
			font-size: 21px;
			line-height: 22px;
			color:$color-white;
			@include font(700, 'Ubuntu');
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 147px;
			height: 147px;
			display: flex;
			align-items: center;
			padding: 10px 25px 10px 25px;
		}

		@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
		only screen and(      -o-min-device-pixel-ratio: 5/4 ),
		only screen and( min-resolution: 120dpi ),
		only screen and( min-resolution: 1.25dppx ) {
			max-width: 500px;
		}
	}

	.b-slider-item__content {
		width: calc(100% - 90px);
		max-width: #{$site-width};
		margin: auto;
		color: $color-white;
		padding: 20px;

		*:last-child {
			margin-bottom: 0;
		}

		@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
			max-width: 1350px;
		}
		// @media (max-width: $site-width) {
		// 	width: calc(100% - 90px);
		// }
	}

	.b-sub-slider__block {
		// position: absolute;
		bottom: 0;
		left: 0;
		// width: 100%;
		color: $color-white;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		padding: 9px 0;

		a {
			color: $color-white;
			text-decoration: underline;
			@include font(700, 'Ubuntu');

			&:hover {
				color: #133A60;
			}
		}
	}

	.b-sub-slider__block-show{

	}
	.b-sub-slider__block-hide {
		position: absolute;
		opacity: 0;
		display: none;
		.techrush {
			color: red;
		}

	}

	.l-slider-button-wrapper {
		width: 100%;
		height: 0;
		margin: auto;

		&--main {
			display: none;
		}
	}

	.l-sub-slider-button-wrapper {
		width: 0;
		height: 100%;
		margin: 0 auto;
		background-color: $color-pacific-blue;
		border-radius: 50px 0 0 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		padding: 0 10px;
	}

	.l-slider-button-wrapper,
	.l-sub-slider-button-wrapper {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		height: auto;
	}
	// 	// position: absolute;
	// 	// left: 0;
	// 	// right: 0;
	// 	// bottom: 0;
	// 	// z-index: 1;
	// }

	.b-slider-button {
		top: 0;
		transform: translateY(-50%);
	}

	.b-slider-button,
	.b-sub-slider-button {
		position: absolute;
		color: $color-white;
		transition: color 0.2s ease, opacity 0.2s ease;
		background-color: transparent;
		bottom: 10px;
		z-index: 2;

		&:before {
			font-size: 7px;
		}

		&.swiper-button-disabled {
			opacity: 0.3;
			cursor: default;
		}

		&:not(.swiper-button-disabled) {
			cursor: pointer;

			@media (min-width: 1024px) {
				&:hover {
					background-color: $slider-nav--bg-hover;
					color: $slider-nav--color-hover;
				}
			}

			&:active {
				background-color: $slider-nav--bg-active;
				color: $slider-nav--color-active;
			}
		}
	}

	.b-sub-slider-button {
		position: static;
	}

	.b-slider-button--prev,
	.b-sub-slider-button--prev {
		@include webfont-icon($webfont-icon--i-arrow-bottom);
		transform: rotate(90deg);
		left: 25px;
	}

	.b-slider-button--next,
	.b-sub-slider-button--next {
		right: 25px;
		@include webfont-icon($webfont-icon--i-arrow-bottom);
		transform: rotate(-90deg);
		left: auto;
	}

	.b-slider-button--prev {
		left: 25px;
	}

	.l-slider-pagination-wrapper {
		position: absolute;
		right: -3vw;
		z-index: 1;
		width: 30px;
		top: 50%;
		transform: translateY(-50%);
		transition: right .3s ease;
		&.show{
			right: 3vw;
		}
	}

	.b-slider-pagination {

		&__item {
			display: block;
			@include rem(width, 20px);
			@include rem(height, 20px);
			font-size: 0;
			border: 1px solid #FFFFFF;
			border-radius: 6px 0;
			text-align: center;
			opacity: 1;
			transition: color 0.2s ease, background-color 0.2s ease;
			outline: 0;
			background-color: transparent;

			&.color-blue {
				border: 1px solid $color-pacific-blue;
			}

			&:active,
			&.swiper-pagination-bullet-active {
				background-color: $color-white;
				&.color-blue {
					background-color: $color-pacific-blue;
				}
			}

			& + .b-slider-pagination__item {
				margin-top: 26px;
			}
		}
	}

	@media (max-width: $site-width + $grid-gutter-width * 2) {
		.b-slider-pagination {
			padding-right: $grid-gutter-width;
		}

		.b-sub-slider-pagination {
			padding-left: $grid-gutter-width;
		}
	}

	.b-sub-slider-wrapper,
	.b-sub-slider,
	.swiper-wrapper,
	.l-sub-slider-item__inner {
		height: 100%;
	}
	.l-slider-item__inner{
		position: relative;
	}
	.b-sub-slider-wrapper,
	.b-sub-slider {
		height: auto;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		.swiper-wrapper {
			flex: 1 0 100%;
			min-height: 100%;
		}
	}

	.l-sub-slider-item__container {
		display: flex;
		align-content: center;
		height: 100%;
	}

	.l-sub-slider-pagination-wrapper {
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		z-index: 1;
		width: 100%;
		max-width: $site-width;
		margin: auto;
	}

	@media (max-width: 1680px) {
		.b-slider-item__content,
		.l-header__inner,
		.l-footer__inner {
			max-width: 1300px;
		}
	}

	@media (max-width: 1440px) {
		.l-layout-wrapper--1 {
			.l-header-top-line,
			.l-footer {
				max-width: 1180px;
				margin: 0 auto;
				padding-left: 4vw;
			}

			// .l-footer {
			// 	left: 50%;
			// 	transform: translate(100%, -50%);
			// }
		}

		.b-slider-item {
			// &--6 {
			// 	&::before {
			// 		// height: 205px;
			// 		background: linear-gradient(180deg, #62D0FF 9.52%, #FFFFFF 52.38%);
			// 	}
			// }

			&__content {
				max-width: 1180px;
				padding-left: 4vw;

				&--6 {
					padding-top: 10vh;
				}
			}

			&__info-content {
				&--2 {
					padding-top: 13vh;
				}

				&--5 {
					padding-top: 7vh;
				}
			}

			&__logo {
				width: 162px;
			}

			&__title {
				font-size: 68px;
				line-height: 71px;
				max-width: 1105px;
			}

			&__link {
				font-size: 21px;
				line-height: 23px;
				
			}

			&__next {
				// width: 34px;
				// height: 70px;
			}

			&__title-slide {
				font-size: 42px;
				line-height: 42px;

				&--right {
					margin-bottom: 9px;
				}
				// 	max-width: 454px;
				// 	width: 100%;
				// }
			}

			&__text {
				font-size: 16px;
				line-height: 23px;
			}

			&__arrow {
				width: 113px;
				height: 246px;
			}

			&__left {
				max-width: 367px;
				&--2 {
					max-width: 412px;
				}
				&--4 {
					max-width: 432px;
				}

				&--5 {
					max-width: 540px;
					padding-right: 30px;
				}
			}

			&__right {
				padding-left: 40px;

				&--5 {
					padding-left: 0;
				}
			}

			&__inset {
				// padding: 23px 41px;
				// margin-top: 25px;
				padding: 10px 24px;
    			margin-top: 15px;
				
				p {
					font-size: 18px;
					line-height: 25px;
					margin-bottom: 14px;
				}
			}

			&__inset-border {
				margin-bottom: 21px;
				max-width: 516px;
				padding: 11px 30px;
			}

			&__title-inset {
				font-size: 26px;
				line-height: 34px;
			}

			&__left-inset {
				max-width: 148px;
				flex: 1 0 auto;
			}

			&__indicator-title {
				font-size: 18px;
				line-height: 21px;
				margin-bottom: 18px;
			}

			&__value {
				img {
					max-width: 169px;
				}
			}

			&__indicator-text {
				font-size: 20px;
				line-height: 23px;
			}

			&__wrapper-last {
				margin: 0;
				margin-right: 4vw;
			}

			&__indicator-item {
				margin-bottom: 13px;
			}

			&__text-last {
				font-size: 20px;
				line-height: 26px;
				padding: 21px 0 0 35px;
				margin-left: 0;
				margin-bottom: 10px;

				&::before {
					width: 51px;
					height: 93px;
				}

				&::after {
					left: 50px;
				}
			}

			&__qote-text {
				font-size: 19px;
				line-height: 23px;
			}

			&__qote-photo {
				max-width: 125px;
			}

			&__treatment {
				max-width: 296px;
			}

			&__icon-wrapper {
				width: 47px;
				height: 40px;
			}

			&__indicator-text-last {
				font-size: 19px;
				line-height: 23px;
			}

			&__indicator-last {
				max-width: 265px;
				padding: 21px 30px;
			}

			&__text-sub {
				font-size: 17px;
				line-height: 25px;
				padding: 5px 30px;
			}

			.quote--photo {
				margin-bottom: 0;
				.quote__photo {
					width: 172px;
				}
	
				.quote__content {
					padding: 15px 16px 30px 33px;
				}
	
				.text {
					font-size: 18px;
					line-height: 25px;
				}
			}
	
			.person {
				margin-top: 15px;
				&__name {
					font-size: 14px;
					line-height: 20px;
					padding-left: 0;
				}
	
				&__position {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}

		.b-sub-slider-wrapper {
			max-width: 415px;

			&__text {
				font-size: 19px;
				line-height: 21px;
				width: 136px;
				height: 136px;
			}
		}

		.l-slider-pagination-wrapper {
			right: 2vw;
		}

		.b-slider-pagination {
			&__item {
				display: block;
				@include rem(width, 16px);
				@include rem(height, 16px);

				& + .b-slider-pagination__item {
					margin-bottom: 24px;
				}
			}
		}

		.b-slider-button,
		.b-sub-slider-button {
			bottom: 4px;
		}

		.b-sub-slider-button--next {
			right: 15px;
		}
		.b-sub-slider-button--prev {
			left: 10px;
		}
	}

	@media (max-width: 1366px) {
		.b-slider-item__content,
		.l-header__inner,
		.l-footer__inner {
			max-width: 1200px;
		}
	}

	@media (max-width: 1280px) {
		.l-layout-wrapper--1 {
			.l-footer {
				padding-top: 0;
				padding-bottom: 5px;
			}
		}
		.b-slider-item {
			&__content {
				max-width: 1100px;
			}

			&__title-slide {
				font-size: 36px;
				line-height: 38px;
			}

			&__inset-border {
				margin-bottom: 5px;
				padding: 6px 31px;
			}

			&__value {
				img {
					width: 149px;
				}
			}

			&__indicator-title {
				font-size: 16px;
				line-height: 19px;
				margin-bottom: 9px;
			}

			&__indicator-text {
				font-size: 18px;
				line-height: 21px;
			}
		}

		.b-sub-slider-wrapper {
			max-width: 395px;
		}
	}

	@media (max-width: 1054px) {
		.l-layout-wrapper--1 {
			.l-header-top-line,
			.l-footer {
				// padding-left: 8vw;
				max-width: 920px;
			}

			.b-search.b-search--extendable .b-search__button,
			.b-lang--single .b-lang__item,
			.b-buttons-panel--in-column.b-buttons-panel--download-pdf .b-buttons-panel__trigger {
				padding: 7px 15px;
			}
		}

		.b-slider-item {
			&__content {
				padding-left: 5vw;
				max-width: 920px;
			}

			&__logo {
				width: 137px;
			}

			&__title {
				font-size: 58px;
				line-height: 61px;

				.browser-ie & {

				}
			}

			&__link {
				font-size: 19px;
				line-height: 21px;
			}

			&__next {
				// width: 26px;
				// height: 55px;
			}

			&__title-slide {
				font-size: 35px;
				line-height: 35px;
			}

			&__text {
				font-size: 15px;
				line-height: 19px;
				max-width: 206px;

				&--4 {
					max-width: none;
				}
			}

			&__left {
				max-width: 329px;
				&--2 {
					max-width: 347px;
				}

				&--3 {
					max-width: 299px;
					padding-right: 35px;
				}
				&--4 {
					max-width: 324px;
					padding-right: 17px;
				}
				&--5 {
					max-width: 452px;
				}
			}

			&__right {
				padding-left: 17px;

				&--2 {
					padding-left: 44px;
				}

				&--3 {
					padding-left: 26px;
				}
			}

			&__button {
				font-size: 13px;
				line-height: 15px;
				padding: 8px 22px;
			}

			&__inset {
				padding: 17px 24px;
				p {
					font-size: 15px;
					line-height: 21px;
				}
			}

			&__title-inset {
				font-size: 20px;
				line-height: 26px;
			}

			&__arrow {
				width: 94px;
				height: 205px;
			}

			&__left-inset {
				max-width: 104px;
			}

			&__inset-border {
				margin-bottom: 10px;
				max-width: 378px;
				padding: 7px 17px;
			}

			&__indicator-title {
				font-size: 15px;
				line-height: 21px;
			}

			&__value {
				img {
					max-width: 141px;
				}
			}

			&__indicator-text {
				font-size: 17px;
				line-height: 20px;
			}

			&__text-last {
				font-size: 16px;
				line-height: 21px;
			}

			&__treatment {
				max-width: 142px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			&__treatment-item {
				height: 50%;
			}

			&__qote-photo {
				display: none;
			}

			&__qote-text {
				font-size: 16px;
				line-height: 19px;
			}

			&__indicator-last {
				max-width: 261px;
			}

			&__indicator-text-last {
				font-size: 16px;
				line-height: 19px;
			}

			&__text-sub {
				font-size: 16px;
    			line-height: 23px;
			}

			.quote--photo {
				.quote__photo {
					width: 135px;
				}
				.quote__content {
					padding: 0 16px 30px 33px;
				}
	
				.text {
					font-size: 15px;
					line-height: 21px;
				}
			}
	
			.person {
				margin-top: 0;
				&__name {
					font-size: 12px;
					line-height: 18px;
				}
	
				&__position {
					font-size: 12px;
					line-height: 18px;
				}
			}
		}

		.b-sub-slider-wrapper {
			max-width: 390px;

			.browser-ie & {
				flex: 1 0 390px;
			}
		}

		.l-slider-pagination-wrapper {
			right: 1vw;
		}

		.b-slider-pagination {
			&__item {
				width: 14px;
				height: 14px;
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
	
			.l-header-top-line {
				padding-left: 0;
				opacity: 1 !important;
				transform: none !important;
			}

			.l-header {
				opacity: 0;
				transform: translateY(-100%);
			}

			.l-footer {
				padding-left: 15px;
				// left: 0;

				&.show {
					transform: translateY(0);
				}
			}

			.content-area {
				height: 100vh;
			}
		}

		.b-buttons-panel--in-column.b-buttons-panel--tools .b-buttons-panel__list,
		.b-mobile-menu.b-mobile-menu--vertical-accordion .b-mobile-menu__panel {
			height: calc(100vh - 69px);
		}

		.b-buttons-panel--in-column.b-buttons-panel--tools .b-buttons-panel__list {
			top: 69px;
		}

		.b-slider-item {
			&__content {
				padding: 70px 14px 0;

				&--6 {
					padding-bottom: 80px;
				}
			}

			&__next {
				width: 65px;
				height: 65px;
				background: rgba($color-white, .3);
				border-radius: 50%;
				overflow: hidden;
				@include webfont-icon($webfont-icon--tap);
				@include webfont-icon($webfont-icon--tap-hand, 'after');

				.help-mouse-down,
				.help-down {
					display: none;
				}

				&:before,
				&:after {
					position: absolute;
					color: $color-white;
					font-size: 65px;
				}

				&:before {
					top: -2px;
					left: -10px;
					animation: tap 2s ease 1s infinite;
				}

				&:after {
					top: -1px;
					left: 2px;
					transform: scale(1.1);
					animation: tap-tap 2s ease infinite;
				}
			}

			&__info-content {
				padding-top: 0;
				flex-direction: column;
				justify-content: flex-start;
			}

			&__left {
				max-width: none;
				display: flex;
				flex-wrap: wrap;
				padding: 0;
				align-items: flex-end;
				margin-bottom: 3vh;

				&--3 {
					.b-slider-item__title-slide {
						width: 100%;
					}
				}
			}

			&__right {
				padding: 0;
			}

			&__arrow {
				height: 94px;
				width: 205px;
				flex: 1 0 100%;
				position: static;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 4vh;

				img {
					display: block;
					transform: rotate(90deg);
					width: 94px;
					height: 205px;
					margin: 0;
				}
			}

			&__title-slide {
				font-size: 42px;
				line-height: 42px;
				margin-bottom: 15px;
				flex: 1 0 100%;

				&--right {
					padding-right: 25px;
				}
			}

			&__text {
				max-width: none;
				font-size: 16px;
				line-height: 21px;
				flex: 1 0 100%;

				.b-slider-item__left--2 & {
					padding-left: 20px;
				}
			}

			&__title-slide,
			&__text {
				// width: 50%;

				&--right {
					width: 100%;
					margin-bottom: 25px;
				}
			}

			&__button-wrapper {
				padding-right: 47px;
			}

			&__inset {
				p {
					font-size: 16px;
					line-height: 22px;
				}
			}

			&__title-inset {
				margin-bottom: 6px;
			}

			&__inset-border {
				max-width: 478px;
			}

			&__indicator-title {
				font-size: 16px;
				line-height: 22px;
			}

			&__text-last {
				max-width: 586px;
				font-size: 17px;
				line-height: 23px;
				padding: 20px 0 0 22px;
				margin-bottom: 10px;
			}

			&__wrapper-last {
				flex-direction: column;
				max-width: 589px;
			}

			&__treatment {
				flex-direction: row;
				width: 100%;
				max-width: none;
				margin-bottom: 10px;
			}

			&__treatment-item {
				height: auto;
				margin-bottom: 0;
				max-width: 270px;
			}

			&__qote-photo {
				display: block;
				width: 100%;
			}

			&__indicator-last {
				max-width: none;
				width: 100%;
				display: flex;
				justify-content: space-between;
				background-size: cover;
				margin-bottom: 10px;
				padding: 12px 38px;
			}

			&__indicator-item {
				margin-bottom: 0;
				width: calc(100%/3);

				&:first-child {
					max-width: 176px;
				}

				&:nth-child(2) {
					max-width: 123px;
				}

				&:nth-child(3) {
					max-width: 156px;
				}
			}

			&__indicator-text-last {
				display: flex;
				align-items: center;
				
				&::after {
					right: 0px;
				}
			}

			&__text-sub {
				font-size: 16px;
    			line-height: 23px;
			}

			.quote--photo {
				display: flex;
				padding: 0;

				.quote__left {
					width: 200px;
					flex: 1 0 auto;
				}
	
				.quote__photo {
					width: 173px;
					margin: 0;
				}

				.quote__content {
					padding: 17px 28px 30px 0px;
				 }
	
				.text {
					font-size: 16px;
					line-height: 21px;
				}
			}
	
			.person {
				margin-top: 10px;
				&__name {
					font-size: 16px;
					line-height: 21px;
				}
	
				&__position {
					font-size: 16px;
					line-height: 21px;
				}
			}
		}

		.b-sub-slider-wrapper {
			max-width: none;
			width: 100%;
			max-height: 399px;

			&__text {
				width: 112px;
				height: 112px;
				font-size: 16px;
				line-height: 17px;
				background-size: contain;
			}
		}

		.l-slider-pagination-wrapper {
			right: 11px;
		}

		.b-slider-pagination {
			padding-right: 0;
			&__item {
				width: 20px;
				height: 20px;
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-header-top-line {
				padding-left: 0;
			}

			.l-page,
			.content-area {
				height: 100% !important;
			}

			.l-footer {
				display: none !important;
			}
		}

		.l-slider-item__inner {
			
		}

		.b-slider-item {
			padding: 110px 0 0;

			&--0 {
				padding: 0;

				&::before {
					bottom: calc(-100% + 36vh);
				}
			}

			&__content {
				padding: 0 14px 20px;
				width: 100%;
			}

			&__logo {
				width: 96px;
			}

			&__title {
				font-size: 27px;
				line-height: 28px;
			}

			&__link {
				font-size: 15px;
				line-height: 17px;

				&::after {
					font-size: 8px;
					right: -15px;
				}
			}

			&__title-slide {
				font-size: 24px;
				line-height: 25px;
				max-width: 286px;
				padding-right: 0;
			}

			&__text {
				font-size: 15px;
				line-height: 19px;
			}

			&__arrow {
				height: 44px;
				width: 95px;

				img {
					width: 44px;
					height: 95px;
				}
			}

			&__up-wrapper {
				display: block;
				margin: 30px 0;
			}

			&__inset {
				p {
					font-size: 15px;
					line-height: 21px;
				}
			}

			&__title-inset {
				font-size: 18px;
				line-height: 23px;
			}

			&__inset-border {
				display: block;
				margin-bottom: 19px;
			}

			&__right-inset {
				padding-left: 0;
			}

			&__text-last {
				font-size: 15px;
				line-height: 20px;
			}

			&__treatment {
				display: block;
				margin-bottom: 28px;
			}

			&__treatment-item {
				max-width: none;
				margin-bottom: 29px;
			}

			&__indicator-last {
				display: block;
			}

			&__indicator-item {
				width: 100%;
				margin-bottom: 15px;
				&:first-child,
				&:nth-child(2),
				&:nth-child(3) {
					max-width: 307px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			&__indicator-text-last {
				font-size: 19px;
				line-height: 23px;
			}

			&__text-sub {
				font-size: 14px;
				line-height: 18px;
			}

			.os-scrollbar-track {
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 3px;
					width: 1px;
					height: 100%;
					background-color: $color-white;
				}
			}

			.os-scrollbar-handle {
				background-color: $color-white !important;
			}

			.quote--photo {
				display: block;
				.quote__left {
					width: 300px;
				}

				.quote__photo {
					width: 80px;
				}

				.text {
					font-size: 15px;
					line-height: 21px;
				}
			}

			.person {
				&__name,
				&__position {
					font-size: 15px;
					line-height: 21px;
				}
			}

			&--6 {
				.os-scrollbar-vertical {
					height: calc(100vh - 150px);
				}
				.os-scrollbar-track {
					&::before {
						background-color: #009FE3;
					}
				}
	
				.os-scrollbar-handle {
					background-color: #009FE3 !important;
				}
			}
		}

		.b-sub-slider-wrapper {
			.browser-safari & {
				overflow: visible;
			}

			&__text {
				font-size: 12px;
				line-height: 12px;
				width: 81px;
				height: 81px;
				padding: 16px 25px 10px 16px;
			}
		}

		.b-sub-slider-item {
			overflow: hidden;
			border-radius: 50px 0;

		}

		.l-slider-pagination-wrapper {
			right: auto !important;
			left: 0;
			top: 83px;
			width: 100%;
		}

		.b-slider-pagination {
			display: flex;
			max-width: 169px;
			margin: 0 auto;

			&__item {
				width: 15px;
				height: 15px;

				& + .b-slider-pagination__item {
					margin-bottom: 0;
					margin: 0 0 0 16px;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.l-slider-button-wrapper--main {
			display: block;
			top: 83px;
			bottom: auto;

			.b-slider-button {
				opacity: 0;
				&::before {
					font-size: 20px;
					color: $color-white;
				}

				&.color-blue {
					&::before {
						color: #009FE3;
					}
				}
			}

			.b-slider-button--prev {
				@include webfont-icon($webfont-icon--i-arrow);
				&::before {
					display: inline-block;
					transform: rotate(-90deg);
				}

			}

			.b-slider-button--next {
				@include webfont-icon($webfont-icon--i-arrow);
				&::before {
					display: inline-block;
					transform: rotate(-90deg);
				}
			}
		}
	}

	// @media only screen 
	// and (min-device-width: 320px) 
	// and (max-device-width: 480px)
	// and (-webkit-min-device-pixel-ratio: 2) {
	// 	.l-layout-wrapper--1 {
	// 		.content-area {
	// 			height: 100vh !important;

	// 			.browser-chrome & {
	// 				height: calc(100vh - 72px) !important;
	// 			}
	// 		}
	// 	}
	// }

	// @media only screen 
	// and (min-device-width: 320px) 
	// and (max-device-width: 568px)
	// and (-webkit-min-device-pixel-ratio: 2) {
	// 	.l-layout-wrapper--1 {
	// 		.content-area {
	// 			height: 100vh !important;

	// 			.browser-chrome & {
	// 				height: calc(100vh - 72px) !important;
	// 			}
	// 		}
	// 	}
	// }

	// @media only screen 
	// and (min-device-width: 375px) 
	// and (max-device-width: 667px) 
	// and (-webkit-min-device-pixel-ratio: 2) { 
	// 	.l-layout-wrapper--1 {
	// 		.content-area {
	// 			height: 100vh !important;

	// 			.browser-chrome & {
	// 				height: calc(100vh - 72px) !important;
	// 			}
	// 		}
	// 	}
	// }

	// @media only screen 
	// and (min-device-width: 414px) 
	// and (max-device-width: 736px) 
	// and (-webkit-min-device-pixel-ratio: 3) { 
	// 	.l-layout-wrapper--1 {
	// 		.content-area {
	// 			height: 100vh !important;

	// 			.browser-chrome & {
	// 				height: calc(100vh - 72px) !important;
	// 			}
	// 		}
	// 	}
	// }

	// @media only screen 
	// and (min-device-width: 375px) 
	// and (max-device-width: 812px) 
	// and (-webkit-min-device-pixel-ratio: 3) { 
	// 	.l-layout-wrapper--1 {
	// 		.content-area {
	// 			height: 100vh !important;

	// 			.browser-chrome & {
	// 				height: calc(100vh - 72px) !important;
	// 			}
	// 		}
	// 	}
	// }
}

.js-opacity1{
	opacity: 1 !important;
}

.quote__left {
	z-index: 1;
}

.js-fake-image{
	position: absolute;
	z-index: -1;
	top: -25px;
}

@-moz-keyframes scroll {
	0% {
		top: 5px;
		opacity: 0.3;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 15px;
		opacity: 0;
	}
}

@-webkit-keyframes scroll {
	0% {
		top: 5px;
		opacity: 0.3;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 15px;
		opacity: 0;
	}
}

@-o-keyframes scroll {
	0% {
		top: 5px;
		opacity: 0.3;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 15px;
		opacity: 0;
	}
}

@keyframes scroll {
	0% {
		top: 5px;
		opacity: 0.3;
	}

	50% {
		opacity: 1;
	}

	100% {
		top: 15px;
		opacity: 0;
	}
}

@-moz-keyframes indicator-up-down {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		top: 20px;
	}
}

@-webkit-keyframes indicator-up-down {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		top: 20px;
	}
}

@-o-keyframes indicator-up-down {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		top: 20px;
	}
}

@keyframes indicator-up-down {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		top: 20px;
	}
}

@-moz-keyframes arrow-down {
	0% {
		margin-top: 5px;
	}

	100% {
		margin-top: 15px;
	}
}

@-webkit-keyframes arrow-down {
	0% {
		margin-top: 5px;
	}

	100% {
		margin-top: 15px;
	}
}

@-o-keyframes arrow-down {
	0% {
		margin-top: 5px;
	}

	100% {
		margin-top: 15px;
	}
}

@keyframes arrow-down {
	0% {
		margin-top: 5px;
	}

	100% {
		margin-top: 15px;
	}
}

@keyframes tap-tap {
	0%, 100% {
		transform: scale(1.1);
	}

	50% {
		transform: scale(.85);
	}
}

@keyframes tap {
	0%, 100% {
		opacity: 1;
	}

	50% {
		opacity: .3;
	}
}