.business-model {
	@keyframes blinker {
		0% { opacity: 1.0; }

		50% { opacity: 0.0; }
	}

	.b-tabs {
		&__nav {
			padding: 0 15px;

			@include breakpoint(xs) {
				padding: 0;
			}
		}

		&__nav-item {
			@media (min-width: 501px) {
				max-width: 33% !important;
			}
			@include breakpoint(md) {
				max-width: unset;
			}

			@include breakpoint(sm) {
				margin-bottom: 20px;
			}

			@include breakpoint(xs) {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&__button {
			padding: 14px 13px;
			margin: 0 8px 0 8px;
			font-size: 20px;
			line-height: 28px;

			@include breakpoint(xs) {
				padding: 9px 13px;
			}

			.b-tabs__nav-text {
				@media (max-width: 1250px) {
					@include font-size(16px);
				}
			}
		}

		&__article {
			padding-top: 30px;

			@include breakpoint(xs) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.b-title-icon {
		// display: none;

		max-width: 148px;
		border-bottom: 1px solid $color-cobalt;
		margin-bottom: 9px;

		@media(max-width: 747px) {
			display: flex;
			align-items: flex-end;
		}

		&__icon {
			width: 25px;
			height: 25px;
			margin-right: 10px;
			margin-bottom: 2px;
		}

		&__text {
			max-width: 123px;
			margin-bottom: 2px;
			font-weight: normal;
			@include font-size(14px);
			line-height: 16px;

			color: $color-cobalt;
		}
	}

	h2 {
		@include font-size(40px);
		line-height: 44px;
		color: $color-pacific-blue;
		opacity: 0.8;
		margin: 0 0 30px;
		@include font(400, 'Ubuntu');
		text-transform: uppercase;
		border-bottom: 1px solid $color-grey;
		padding-bottom: 12px;

		@include breakpoint(xs) {
			@include font-size(30px);
			line-height: 33px;
			font-weight: 800;
		}
	}

	.btn {
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
	}

	.b-map {
		.btn {
			// width: 288px;
			font-size: 16px;
			line-height: 18px;
			display: flex;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			justify-content: space-between;
			@include rem(padding, 17px 20px);
			@include webfont-icon($webfont-icon--chart-download, after);
			display: none;

			@media (max-width: 747px) {
				display: flex;
			}

			&:after {
				color: $color-white;
			}

		}
	}

	.b-chart-title {
		font-style: normal;
		font-weight: normal;
		@include font-size(14px);
		line-height: 16px;
		color: $color-regal-blue;
		position: relative;
		@include rem(padding, 17px 20px 20px);
		margin: 0;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 126px;
			height: 92px;
			@include svg(100%, 100%, "/images/components/business-model/images/title.svg", transparent);
		}
	}

	.b-lead {
		position: relative;
		@include rem(padding, 13px 20px);

		h3 {
			@include font-size(18px);
			line-height: 21px;
			@include font(400, 'Ubuntu');
			margin-bottom: 6px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 126px;
			height: 92px;
			@include svg(100%, 100%, "/images/components/business-model/images/lead.svg", transparent);
		}
	}

	p {
		font-style: normal;
		font-weight: normal;
		@include font-size(14px);
		line-height: 16px;
		color: $color-star-dust;
	}

	.b-sales {
		padding-top: 40px;

		@include breakpoint (md) {
			margin-bottom: 17px;
			padding-top: 0;
		}

		p {
			@include font-size(16px);
			line-height: 18px;
			margin-bottom: 0;

			&.b-sales__en {
				color: $color-ship-grey;
			}
		}

		&__title {
			@include breakpoint(sm) {
				@include font-size(18px);
				line-height: 21px;
				margin-bottom: 7px;
			}
		}

		&__list {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			margin: 0;

			@media (max-width: $site-width) {
				flex-wrap: wrap;
			}

			@include breakpoint(xs) {
				display: block;
			}
		}

		&__item {
			flex-basis: 25%;
			@include rem(padding-left, 12px);

			&:before {
				width: 6px;
				height: 6px;
				top: 6px;
				background-color: $color-supernova;
			}

			@media (max-width: $site-width) {
				flex-basis: 47%;
			}

			@include breakpoint(md) {
				flex-basis: 25%;
			}
		}

		@include breakpoint (xs) {
			p, .b-sales__en {
				display: inline;
			}
		}
	}

	.b-see-map {

		display: flex;
		flex-direction: column;
		margin-bottom: 25px;
		padding-top: 20px;

		@include breakpoint(md) {
			padding-top: 0;
		}

		// @include breakpoint(xs) {
		//     flex-direction: column-reverse;
		// }

		.btn {
			max-width: 179px;
			margin: 0 auto 15px;
			padding: 12px 10px;
			// white-space: nowrap;

			@include breakpoint(sm) {
				margin: 0;
				margin-bottom: 15px;
				max-width: 288px;
			}

			@include breakpoint(xs) {
				display: none;
			}
		}

		.btn--mobile {
			display: none;

			@include breakpoint(xs) {
				display: block;
				font-size: 16px;
				line-height: 18px;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				width: 100%;
				max-width: unset;
				@include rem(padding, 17px 20px);
			}
		}

		&__icon {
			position: relative;
			width: 108px;
			height: 61px;
			margin: 0 auto;

			svg {
				width: 100%;
				height: 100%;
			}

			@include breakpoint (xs) {
				width: 259px;
				height: 147px;
				margin-bottom: 25px;
			}
		}

	}

	.b-chart {
		max-width: calc(75% - 12px);
		margin-left: 10px;

		.graphic {
			display: flex;
			align-items: flex-start;
			flex-wrap: nowrap;

			.figure-buttons {
				display: none;
			}

			&__img--legend {
				align-self: center;
			}

			&__img--europe {
				width: 20%;
			}

			&__img--asia,
			&__img--america,
			&__img--legend {
				width: 15%;
			}

			&__img--russia {
				width: 12%;
			}

			&__img--legend-mobile {
				display: none;
			}
		}
	}

	.b-inset {
		border: 1px solid $color-solitude;
		border-radius: 0 20px;
		padding: 5px 15px;
		position: relative;
		max-width: 236px;

		margin-left: 10px;
		margin-bottom: 40px;

		@include breakpoint(xs) {
			margin-left: 0;
			max-width: unset;
		}

		&:before {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			@include svg(100%, 100%, "/images/components/business-model/images/inset-icon.svg", transparent);
			position: absolute;
			left: 0;
			top: 0;
			transform: translate(-50%, -50%);
		}

		&__title {
			font-style: normal;
			font-weight: 300;
			@include font-size(26px);
			line-height: 30px;
			color: $color-grey;
			margin-bottom: 11px;

			&:before {
				display: none;
			}
		}
	}

	.inset {
		background-image: url(/images/content/inset-blue.png);
		display: flex;
		align-items: baseline;
		@include rem(padding, 40px 0px);
		margin-bottom: 0;

		&__block {

			@include rem(padding, 0px 40px 0px 52px);

			@include breakpoint(md) {
				min-width: unset;
			}

			&:last-child {
				border: none;
			}

			@media (max-width: 1366px) {
				.browser-ie & {
					flex: 1 0;
				}
			}

			&--shareholders {
				max-width: 312px;

				img {
					padding-bottom: 10px;
				}

				.b-indicator-block .b-indicator {
					max-width: unset;
				}

				@media (max-width: 1366px) {
					.browser-ie & {
						max-width: 260px;
					}
				}
			}

			&--social {
				max-width: 483px;
				border-right: 2px solid $color-white;
				border-left: 2px solid $color-white;

				img {
					padding-bottom: 9px;
				}

				@media (max-width: 1366px) {
					.browser-ie & {
						max-width: 314px;
					}
				}
			}

			&--ecological {
				img {
					padding-bottom: 11px;
				}
			}

		}

		h4 {
			@include font(400, 'Ubuntu');
			font-style: normal;
			font-weight: 500;
			@include font-size(16px);
			color: $color-denim;
			// line-height: 18px;
			line-height: 22px;
			max-width: 180px;
			margin-bottom: 19px;
		}

		.b-indicator {

			max-width: 45%;
			margin-bottom: 16px;
			@include breakpoint(lg) {
				max-width: unset;
			}

			&-block {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				max-width: 547px;
			}

			&__text {
				@include font(400, 'Ubuntu');
				font-style: normal;
				font-weight: normal;
				@include font-size(14px);
				line-height: 16px;
				margin-bottom: 0px;
				color: $color-regal-blue;
			}

			&__value {
				@include font(700, 'Ubuntu');
				font-style: normal;
				font-size: 30px;
				line-height: 34px;
				text-shadow: 1px 1px $color-white,
							-1px 1px $color-white,
							-1px -1px $color-white,
							1px -1px $color-white;
				color: $color-summer-sky;
				margin-bottom: 0;
			}

			&__unit {
				@include font(500, 'Ubuntu');
				font-size: 14px;
				line-height: 16px;
				color: $color-white;
			}
		}
	}

	@include breakpoint(xs) {
		.row--mobile-reverse {
			display: flex;
			flex-direction: column-reverse;
		}

		.inset {
			flex-direction: column;
			padding: 10px 20px;
			border-radius: 25px 0;
			box-sizing: border-box;

			&__block {

				padding: 25px 0;
				min-width: unset;
				max-width: unset;
				width: 100%;

				&--social {
					border-top: 2px solid $color-white;
					border-bottom: 2px solid $color-white;
					border-left: none;
					border-right: none;
				}
			}

			.b-indicator {
				width: 210px;
			}

		}

		.b-chart {
			max-width: 100%;
			margin-left: 0px;

			.graphic {
				flex-direction: column;
				align-items: center;
				margin-bottom: 0;

				&__img--legend {
					display: none;
				}

				&__img--legend-mobile {
					display: inline-block;
				}

				&__img--europe,
				&__img--asia,
				&__img--america,
				&__img--legend,
				&__img--russia {
					width: unset;
				}

				img {
					margin-left: unset;
					margin-right: unset;
					margin-bottom: 40px;

					&:first-child,
					&.graphic__img--russia {
						margin-bottom: 25px;
					}
				}
			}
		}
	}

	// .b-map-wrapper {
	// 	border-radius: 20px;
	// 	background-image: url('/images/components/business-model/images/map-bg.png');
	// 	background-size: 100%;

	// 	@media(max-width: 747px) {
	// 		border-radius: unset;
	// 		background-image: none;
	// 	}
	// }

	.b-map-mobile {
		display: none;
		margin-bottom: 20px;

		@media(max-width: 747px) {
			display: block;
		}
	}

	.b-map-picture {
		@media(max-width: 747px) {
			display: none;
		}

		svg {
			border-radius: 20px;
		}

		.flag {
			opacity: 0;
			transition: opacity .5s ease;
		}

		.point {
			animation-name: blinker;
			animation-iteration-count: 5;
			animation-timing-function: linear;
			animation-duration: 2s;
		}

		.point:hover {
			cursor: pointer;
			// animation: shower;
		}

		.point--honeymoon:hover ~ .flag--honeymoon,
		.point--harjavalta:hover ~ .flag--harjavalta,
		.point--kolska:hover ~ .flag--kolska,
		.point--zapolyarny:hover ~ .flag--zapolyarny,
		.point--nkomati:hover ~ .flag--nkomati,
		.point--bystrinskoe:hover ~ .flag--bystrinskoe {
			opacity: 1;
		}
	}

	.b-scheme {
		position: relative;
		margin-bottom: 40px;

		img {
			margin-left: 0;
			width: 100%;
		}

		.btn {
			margin-bottom: 40px;
			@include webfont-icon($webfont-icon--chart-download, after);
			justify-content: space-between;
			// width: 288px;
			display: none;
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			@include rem(padding, 17px 20px);

			@media (max-width: 747px) {
				display: flex;
			}

			&:after {
				color: $color-white;
			}
		}

		&__picture {
			@media(max-width:747px) {
				display: none;
			}
		}

		&__mobile {
			display: none;

			@media(max-width:747px) {
				display: block;
				margin-bottom: 25px;
			}
		}

		&__icon-box {
			position: absolute;
			width: 80%;
			top: 12.5vw;
			pointer-events: none;

			display: flex;
			justify-content: space-between;

			@media(min-width: 2400px) {
				top: 10vw;
			}

			@media(max-width: 1920px) and (min-width: 1601px) {
				top: 12.5vw;
			}

			@media(max-width: 1600px) and (min-width: 1240px) {
				top: 11.3vw;
			}

			@media(max-width: 1240) and (min-width: 801px) {
				top: 12.5vw;
			}

			@media(max-width: 800px) and (min-width: 769px) {
				top: 11vw;
			}

			@media(max-width: 768px) {
				top: 13vw;
			}

			@media(max-width:747px) {
				display: none;
			}
		}

		&__icon-block {
			width: 22.5%;
			height: 100%;

			text-align: right;
		}

		&__icon {
			width: 45px !important;
			height: 45px;

			cursor: pointer;
			animation-name: blinker;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-duration: 2s;
		}

		.b-tooltip .b-tooltip__svg-icon {
			font-size: 18px;
			fill: $color-supernova;
		}

		.picture:hover {
			cursor: pointer;
		}

		.is-hidden {
			opacity: .3;
			transition: opacity .5s ease;
		}

	}

	html:not(.browser-ie) & {
		.js-strat-bm,
		.js-strat-map,
		.js-strat-wrapper {
			opacity: 0;
		}
	}
}
