$mobile-menu_vertical-accordeon--font-size: 15px;
$mobile-menu_vertical-accordeon--button-width: 30px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 22px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 2px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 10px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 28px;

$mobile-menu_vertical-accordeon--panel-bg: $color-eallery;
$mobile-menu_vertical-accordeon--link-color: $color-pacific-blue;
$mobile-menu_vertical-accordeon--link-color-hover: $color-pacific-blue;
$mobile-menu_vertical-accordeon--link-color-active: $color-supernova;
$mobile-menu_vertical-accordeon--controller-color: $color-dim-gray;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-supernova;
$mobile-menu_vertical-accordeon--controller-color-active: $color-supernova;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: $color-white;
$mobile-menu_vertical-accordeon--button-color-hover: $color-white;
$mobile-menu_vertical-accordeon--button-color-active: $color-white;
$mobile-menu_vertical-accordeon--link-lvl1-bg: $color-light-gray;
$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;

@if ($contrast) {
	$mobile-menu_vertical-accordeon--panel-bg: $color-eallery;
	$mobile-menu_vertical-accordeon--link-color: $base-text-color;
	$mobile-menu_vertical-accordeon--link-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--link-color-active: orange;
	$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
	$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--controller-color-active: orange;
	$mobile-menu_vertical-accordeon--button-bg: transparent;
	$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
	$mobile-menu_vertical-accordeon--button-bg-active: transparent;
	$mobile-menu_vertical-accordeon--button-color: $color-ironside-gray;
	$mobile-menu_vertical-accordeon--button-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--button-color-active: orange;
	$mobile-menu_vertical-accordeon--link-lvl1-bg: $color-light-gray;
	$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
	$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;
}

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	@include rem(width, $mobile-menu_vertical-accordeon--button-width);
	@include rem(height, $mobile-menu_vertical-accordeon--button-height);

	.b-mobile-menu__burger {
		display: block;
		z-index: 10;
		position: relative;
		background-color: $mobile-menu_vertical-accordeon--button-bg;
		@include rem(width, $mobile-menu_vertical-accordeon--button-width);
		@include rem(height, $mobile-menu_vertical-accordeon--button-height);

		&:hover,
		&:focus {
			background-color: $mobile-menu_vertical-accordeon--button-bg-hover;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-hover;
			}
		}

		&:active {
			background-color: $mobile-menu_vertical-accordeon--button-bg-active;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-active;
			}
		}

		.b-mobile-menu__burger-ingredients {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: $mobile-menu_vertical-accordeon--bar-thickness;
			width: $mobile-menu_vertical-accordeon--bar-width;
			margin: auto;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: $mobile-menu_vertical-accordeon--bar-thickness;
				background-color: $mobile-menu_vertical-accordeon--button-color;
				transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
				transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
				will-change: transform;
			}

			&:before {
				top: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: top, transform;
			}

			&:after {
				bottom: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: bottom, transform;
			}
		}

		.b-mobile-menu__burger-ingredients-middle {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $mobile-menu_vertical-accordeon--button-color;
			transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
			will-change: transform;
		}

		&.is-expand {
			z-index: 500;
			background: none;
			top: auto !important;
			right: auto !important;

			.b-mobile-menu__burger-ingredients-middle {
				opacity: 0;
			}

			.b-mobile-menu__burger-ingredients {
				&:before,
				&:after {
					transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
				}

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.b-mobile-menu__panel {
		display: none;
		position: fixed;
		height: calc(100% - 68px);
		width: 100%;
		left: 0;
		top: 68px;
		z-index: 100;
		background-color: #F3F7FD;
		overflow: hidden;

		&::-webkit-scrollbar {
			background: transparent;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #555;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.l-buttons-area {
		&__lang,
		&__search,
		&__pdf {
			display: block;
		}
	}

	.b-mobile-menu__item {
		display: block;
		vertical-align: top;
		margin-bottom: 15px;
	}

	.b-mobile-menu__panel-item {
		&--title {
			font-size: 14px;
			line-height: 16px;
			@include font(700);
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: $color-supernova;
			padding: 0 16px;
			margin-bottom: 13px;
		}

		&--search {
			padding: 16px 16px;
		}
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}

		&--menu {
			width: 100%;
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}

		&--lvl2 {
			padding: 3px 0 0 17px;
		}

		&--lvl3 {
			padding: 5px 0 0 21px;
		}
	}

	.b-nav__item {
		&--lvl1 {
			margin-bottom: 8px;
		}

		&--lvl2 {
			margin: 0 0 3px;
		}
	}

	.b-nav__link {
		display: block;
		@include rem(padding-right, 15px);
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
		color: $mobile-menu_vertical-accordeon--link-color;
		@include font-size($mobile-menu_vertical-accordeon--font-size);
		text-decoration: none;
		@include font(400);
		line-height: 1;

		&:hover {
			// color: $mobile-menu_vertical-accordeon--link-color-hover;
			text-decoration: underline;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}

		&.current,
		&.is-expand {
			color: $color-supernova;
		}
	}

	.b-nav__controller {
		position: absolute;
		@include rem(width, 24px);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		top: 0;
		right: 31px;
		margin: auto 0;
		bottom: 0;
		cursor: pointer;
		@include webfont-icon($webfont-icon--i-arrow-bottom, 'after');
		font-size: 9px;

		&:after {
			display: block;
			transition: transform 0.2s ease;
			color: $mobile-menu_vertical-accordeon--controller-color;
		}



		// &:hover {
		// 	&:before,
		// 	&:after {
		// 		color: $mobile-menu_vertical-accordeon--controller-color-hover;
		// 	}
		// }

		&:active {
			&:before,
			&:after {
				color: $mobile-menu_vertical-accordeon--controller-color-active;
			}
		}

		&.is-expand:after {
			transform: rotate(180deg);
		}
	}

	.b-nav__link--lvl1 {
		color: $mobile-menu_vertical-accordeon--link-color;
		padding-left: 15px;
		font-size: 19px;
		line-height: 23px;
		text-transform: uppercase;
		@include font(700);
		padding-right: 69px;
		margin-bottom: 9px;

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}

		&.current,
		&.is-expand {
			color: $mobile-menu_vertical-accordeon--link-color;
		}
	}

	.b-nav__link--lvl1 + .b-nav__controller {
		&::after {
			color: $mobile-menu_vertical-accordeon--link-color;
		}
	}

	.b-nav__link-wrapper {
		position: relative;
	}

	.b-nav__link--lvl2 {
		font-size: 15px;
		line-height: 18px;
		@include font(500);
		color: $color-dim-gray;
		padding-right: 56px;
	}

	.b-nav__link--lvl3 {
		color: $color-eclipse;
		font-size: 15px;
		line-height: 18px;
		@include font(300);
		padding-right: 58px;
	}

	.b-nav__link--lvl4 {
		@include rem(padding-left, 60px);
	}
}
