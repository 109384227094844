.figure-buttons {
	position: relative;
	// text-align: right;
	margin-top: 3px;
	display: inline-flex;
	flex-direction: row-reverse;

	.button + .button {
		margin-right: 20px;
	}

	.button {
		padding: 0;
		text-decoration: none;
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(sm) {
		.figure-buttons {
			margin-top: 10px;
			width: 100%;
		}

		.figure-buttons .button {
			@include font-size(16px);
			text-align: left;
			padding: 12px 12px 12px;
			background: #F9B234;
			position: relative;
			width: 100%;
			text-decoration: none;
			@include font(700);
			border-radius: 20px 0;
			color: $color-white;

			&:before {
				@include font-size(22px);
				position: absolute;
				font-size: 22px;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}

			&:hover,
			&:active {
				background: $color-pacific-blue;
			}

			&--excel {
				@include webfont-icon($webfont-icon--i-table);
			}

			&--image {
				@include webfont-icon($webfont-icon--i-image);
			}
		}
	}
}
