$copyright_base--color: $color-pacific-blue;
$copyright_base--link-color: $link-default-color;
$copyright_base--link-color-hover: $link-hover-color;
$copyright_base--link-color-active: $link-active-color;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;
	@include font-size(16px);
	@include font(500);

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: underline;
		

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $copyright_base--link-color-hover;
			text-decoration: none;
		}

		&:active {
			color: $copyright_base--link-color-active;
			text-decoration: underline;
		}
	}

	@include breakpoint(xs) {
		font-size: 14px;
	}
}
