.header-1 {
	font-size: 40px;
	line-height: 44px;
	color: $color-pacific-blue;
	opacity: 0.8;
	margin: 0 0 42px;
	@include font(700, 'Ubuntu');
	text-transform: uppercase;

	@include breakpoint(sm) {
		font-size: 29px;
		line-height: 33px;
	}

	@include breakpoint(xs) {
		font-size: 21px;
		line-height: 25px;
	}
}

.more-h1 {
	font-size: 16px;
	line-height: 18px;
	color: $color-grey;
	opacity: 0.8;
	border-top: 1px solid $color-grey;
	text-transform: uppercase;
	@include font(700, 'Ubuntu');
	padding-top: 7px;
	margin-top: 12px;
}

h1 {
	@extend .header-1;
}

.header-2 {
	font-size: 22px;
	line-height: 29px;
	margin-bottom: 28px;
	text-transform: uppercase;
	color: $color-regal-blue;
	@include font(700, 'Ubuntu');

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	font-size: 20px;
	line-height: 26px;
	color: $color-supernova;
	margin-bottom: 24px;
	@include font(700, 'Ubuntu');
	text-transform: uppercase;
}

h3 {
	@extend .header-3;
}

.header-4 {
	// font-size: 20px;
	// line-height: 26px;
	// color: $color-supernova;
	// margin-bottom: 24px;
	// @include font(700, 'Ubuntu');
	font-size: 20px;
	font-weight: 500;
	line-height: 26px;
	color: $color-black;
	margin-bottom: 1em;
}

h4 {
	@extend .header-4;
}

.header-5 {
	font-size: 20px;
	line-height: 26px;
	color: $color-black;
	margin-bottom: 1em;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}

.h_special {
	font-weight: bold;
}

.title-custom {
	font-size: 22px;
	line-height: 29px;
	color: $color-regal-blue;
	@include font(700, 'Ubuntu');
	text-transform: uppercase;
	border-left: 1px solid $color-regal-blue;
	padding-left: 18px;

	span {
		display: block;
		text-transform: lowercase;
		color: $color-supernova;
		font-size: 18px;
		font-weight: 400;
	}

	@include breakpoint(xs) {
		font-size: 19px;
		line-height: 23px;
	}
}

.hc--blue {
	color: $color-pacific-blue;
}

.hc--yellow {
	color: $color-supernova;
}


.custom_header {
	margin-bottom: 1em;

	.header {
		@include font-size(18px);
		font-weight: bold;
		color: $color-regal-blue;
		border-bottom: 3px solid $color-regal-blue;
		display: inline-block;
		margin-right: 20px;
		width: 50%;
	}

	.logo_header {
		display: inline-block;
		vertical-align: middle;
		align-items: center;
	}
}
