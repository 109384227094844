$header-bg: $color-white;
$body-bg: #F3F7FD;
$footer-bg: $color-white;

@if ($contrast) {
	$header-bg: #656565;
	$body-bg: #656565;
	$footer-bg: #656565;
}

body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;

	&__header {
		flex: none;
	}

	&__footer {
 
		flex: none;
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;
	background-color: $color-white;
	box-shadow: 2px 2px 2px #eaf2fc61, 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 20px;
	padding: 49px 60px 0 calc(3vw + 4px);
	// flex-wrap: wrap;
	flex-direction: column;
	position: relative;

	.browser-ie & {
		min-height: calc(100vh - 121px - 64px);
		display: block;
	}

	&__wrapper {
		display: flex;
	}

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: 288px;//$right-col-width;
		width: 288px;//$right-col-width;
		min-width: 288px;//$right-col-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	&__content--with-left {
		// padding-left: 5vw;
		padding-right: calc(3vw + 6px);
		// max-width: 54vw;
		// width: 100%;
		width: calc(100% - 288px);
		// width: $left-col-width + $center-col-width;

		// @media (min-width: 1940px) {
		// 	max-width: 943px;
		// }
	}

	&__content--with-right {
		padding-right: 0;
		// width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 49px);
		// flex: 1 0 100%;
		padding: 0 0 0 16px;
		height: 40px;
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
		// position: absolute;
		// right: 69px;
		// top: 37px;
	}
}

// .l-layout-wrapper__page,
// .l-layout-wrapper__footer {
// 	padding-left: $grid-gutter-width;
// 	padding-right: $grid-gutter-width;
// }

.content-area {
	min-height: 1px;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	// margin-right: -($right-col-width + 15px);
	margin-right: -21vw;
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
	width: 288px;//$right-col-width;
	right: -288px;//-$right-col-width;
	// padding-left: $grid-gutter-width;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		max-width: 82vw;//$site-width;
		min-width: $site-min-width;
		padding-left: 4vw;
		// transform: translateX(calc(2vw - 1px));
		@media (min-width: 1940px) {
			max-width: 1680px;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper {
			max-width: 95vw;
			padding-left: 7vw;
		}

		// .l-page__content--with-left {
		// 	max-width: 65vw;
		// }

		.l-layout-wrapper__header {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__footer {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-page__content {
			// width: 100%;
			//overflow: hidden;
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper {
			max-width: 100%;
			padding-left: 0;
		}

		.l-page {
			box-shadow: none;
			border: 0;
		}

		.l-page__wrapper {
			flex-direction: column;
		}

		.l-page__content--with-left {
			max-width: 100%;
			width: 100%;
		}

		.l-content--with-right {
			margin-right: 0;
		}

		.l-page__content {
			padding-left: 0;
			padding-right: 0;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 20px;
		}
	}
}
