.b-appeal--variant-base {
	display: flex;

	.b-appeal {
		&__heading {
			display: block;
		}

		&__photo {
			@include rem(margin-bottom, 20px);
			text-align: center;

			img {
				max-width: 100%;
			}
		}

		&__signature {
			min-height: 0;
		}

		&__info {
			width: 30%;
		}

		&__content {
			width: 70%;
			// default left
			order: 0;
			padding-left: 15px;
		}

		&__post {
			@include rem(padding-bottom, 20px);
			display: block;
		}

		&__name {
			display: inline-block;
			width: 60%;
			@include rem(margin-bottom, 7px);
		}
	}

	&[data-side="left"] {
		.b-appeal__content {
			order: 0;
			padding-left: 15px;
		}
	}

	&[data-side="right"] {
		.b-appeal__content {
			order: -1;
			padding-right: 15px;
		}
	}
}

.b-appeal--variant-base--float {
	display: block;

	&:after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		clear: both;
	}

	&[data-side="left"] {
		.b-appeal__info {
			float: left;
			margin-right: 15px;
		}

		.b-appeal__content {
			padding-left: 0;
		}
	}

	&[data-side="right"] {
		.b-appeal__info {
			float: right;
			margin-left: 15px;
		}

		.b-appeal__content {
			padding-right: 0;
		}
	}

	.b-appeal__content {
		width: 100%;
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-appeal--variant-base {
			flex-direction: column;
			display: flex;

			.b-appeal__info {
				width: 100%;
			}

			.b-appeal__content {
				width: 100%;
				@include rem(padding-top, 15px);
				// default left
				padding-left: 0;
			}

			&[data-side] {
				.b-appeal__content {
					order: 0;
					padding-left: 0;
					padding-right: 0;
				}

				.b-appeal__info {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
}
