.body--overview {
	$orange: #F8B333;
	$indicator-orange: #F9B234;
	$blue: #009FE3;
	$gray: #626262;
	$light-gray: #3F3F3F;
	$dark-blue: #003663;
	$block-header-gray: #B2B2B2;
	$block-gray: #EDEDED;
	$color-white: white;

	.color-blue {
		color: $blue !important;
	}

	.color-white {
		color: $color-white !important;
	}

	.color-dark-blue {
		color: $dark-blue !important;
	}

	.b-tooltip {
		&__svg-icon {
			font-size: 1.2em;
			stroke: $orange;
			fill: $orange;
		}

		&--white {
			.b-tooltip__svg-icon {
				stroke: white;
				fill: white;
			}
		}
	}

	.b-indicator {
		margin-bottom: 24px;

		&__value {
			@include font-size(40px);
			text-shadow: none;
			text-shadow: $light-gray 1.3px 1.3px 0, $light-gray -1.3px -1.3px 0, $light-gray -1.3px 1.3px 0, $light-gray 1.3px -1.3px 0;
			opacity: 0.4;
		}
	}

	.lined {
		border-top: 1px solid $gray;
		position: relative;
		padding-top: 13px;

		&:before {
			content: "";
			position: absolute;
			background-color: $blue;
			width: 40px;
			height: 5px;
			top: -2.5px;
		}
	}

	.padding-md-right {
		padding-right: 3.5%;

		// &--none {
		// 	padding-right: 0;
		// }

		@include breakpoint(md) {
			padding-right: 15px;
		}
	}

	.padding-md-left {
		padding-left: 3.5%;

		// &--none {
		// 	padding-left: 0;
		// }

		@include breakpoint(md) {
			padding-left: 15px;
		}
	}

	.graphic {
		figcaprion {
			@include font-size(20px);
			max-width: 420px;
		}
	}

	.b-link-icon {
		display: block;
		line-height: 1.2em;
		font-weight: normal;

		&__icon {
			max-width: 39px;
			width: 39px;
			height: 39px;
			margin-bottom: 10px;
		}
	}

	figure {
		figcaption {
			max-width: none;
		}
	}

	.content-block {
		&[id="1"] {
			margin-top: 60px;
		}

		&[id="2"] {
			margin-top: 70px;
		}

		&[id="3"] {
			margin-top: 60px;
		}
	}
	
	a {
		text-decoration: none;
	}

	.tooltip-icon {
		cursor: help;
	}

	.js-accel--overview {
		opacity: 0;

		@include breakpoint(sm) {
			opacity: 1;
		}
	}

	.content-non-block {
		margin-right: 270px;

		@include breakpoint(md) {
			margin-right: 90px;
		}

		@include breakpoint(sm) {
			margin-right: 0px;
		}
	}

	.svg-decor {
		transition: opacity .3s ease;
	}
	
	.svg-link:hover {
		.svg-decor {
			opacity: 0;
		}
	}
}
